const userListConfiguration = [
    {
        title: 'My Profile',
        path: 'GO_TO_PROFILE',
        leftIcon: {
            type: 'solid',
            title: 'user-md'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'MY_PROFILE',
        pathType: 'relative'
    },
    {
        title: 'My Orders',
        path: 'GO_TO_ORDERS_LIST',
        leftIcon: {
            type: 'material',
            title: 'OrderIcon'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event: 'MY_ORDERS',
        pathType: 'relative'
    },
    {
        title: 'Buy Again',
        path: 'GO_TO_BUY_FROM_PAST_ORDERS',
        leftIcon: {
            type: 'material',
            title: 'OrderIcon'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event: 'Buy Again',
        pathType: 'relative'
    },
    {
        title: 'My Wishlist',
        path: 'GO_TO_WISHLIST',
        leftIcon: {
            type: 'material',
            title: 'WishlistIcon'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'MY_WISHLIST',
        pathType: 'relative'
    },
    {
        title: 'My Rewards',
        path: 'GO_TO_REWARDS',
        leftIcon: {
            type: 'solid',
            title: 'hand-holding-usd'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'MY_REWARDS',
        pathType: 'relative'
    },
    {
        title: "My Membership",
        path: "GO_TO_MY_MEMBERSHIP",
        leftIcon: {
          type: "material",
          title: "CardMembershipIcon",
        },
        rightIcon: {
          type: "",
          title: "",
        },
        event: "MY_MEMBERSHIP",
        pathType: "relative",
    },


];

export default userListConfiguration;
