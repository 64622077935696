import React from "react";
import { RenderDesktopProductDetails, RenderMobileProductDetails } from "./getProductView";
import { Desktop, Mobile } from "@config/dentalkart.environment";

const ProductGallery = ({ product }) => {

    return (
        <>
            <Desktop>
                <RenderDesktopProductDetails product={product} />
            </Desktop>
            <Mobile>
                <RenderMobileProductDetails product={product} />
            </Mobile>
        </>
    );
};

export default ProductGallery;