import TagManager, {Events as GTM_EVENTS} from '../gtm';

const fireGTM = (eventName, eventData) => {
    const dataLayer = GTM_EVENTS[eventName](eventData);
    const {ga3, ga4} = dataLayer;
    if(ga3 && ga4){
        TagManager.dataLayer({dataLayer:ga3});
        TagManager.dataLayer({dataLayer:ga4});
    }else{
        TagManager.dataLayer({dataLayer})
    }
    console.log(dataLayer);

    // TagManager.dataLayer({dataLayer})
    // console.log(dataLayer);
};
const fireFBP = () => {};

const fireEvents = (eventType = "DEFAULT", eventName, eventData) => {
    console.log(eventName);
    const EventTypes = {
        GTM: fireGTM,
        FBPIXEL: fireFBP,
        DEFAULT: () => console.error('Wrong event type provided.')
    }
    try {EventTypes[eventType](eventName, eventData)}
    catch (err) {console.warn(err + eventName)}
};

export default fireEvents;
