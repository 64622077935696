import { gql } from '@apollo/client';

const FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS = gql`
    query frequentlyBoughtTogether($id : Int!){
        frequentlyBoughtTogether(id: $id){
            id
            related {
                id
                score
            }
        }
        
    }
`;

export default FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS;
