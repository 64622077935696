import { gql } from '@apollo/client';
import {ProductListingFragment} from '../fragments';

const APPLY_REWARD_POINTS = gql`
	mutation dkApplyRewardPoints($rewardpoints: Int!){
    	dkApplyRewardPoints(rewardpoints: $rewardpoints)
    	{
            cart{
				total_quantity
				...ProductListingFragment
				applied_coupons {
					code
				}
				prices {
					grand_total{
						value
						currency
						currency_symbol
					}
					subtotal_including_tax{
						value
						currency
						currency_symbol
					}
					subtotal_excluding_tax{
						value
						currency
						currency_symbol
					}
					overweight_delivery_charges{
						currency
						currency_symbol
						value
					}
					subtotal_with_discount_excluding_tax{
						value
						currency
						currency_symbol
					}
					applied_taxes{
							amount{
								value
								currency
								currency_symbol
							}
							label
						}
						discount{
						amount{
							value
							currency
							currency_symbol
						}
							label
						}
						rewardsdiscount{
							amount{
								value
								currency
								currency_symbol
							}
							label
						}
						total_savings{
							value
							currency
							currency_symbol
						}
					}
				global_errors
				shipping_addresses{
					available_shipping_methods{
					carrier_code
					carrier_title
					method_code
					method_title
					error_message
					amount{
						value
						currency
						currency_symbol
					}
					base_amount{
						value
						currency
						currency_symbol
					}
					price_excl_tax{
						value
						currency
						currency_symbol
					}
					price_incl_tax{
						value
						currency
						currency_symbol
					}
					available
					}
				}
            }
		    rewardsCartInfo{
				earn_points
				earn_points_value
				balance
				reward_term
				reward_icon_url
				exchange_rate_info
				exchange_rate
				exchange_rate_currency
				max_point_to_checkout
				max_point_message
				applied_points
				applied_points_value
				reward_gain_info
				currency
            }
	    }
	}
	${ProductListingFragment}
`;

export default APPLY_REWARD_POINTS;

