import {gql} from '@apollo/client';
import {ProductListingFragment, cartPriceFragment, cartShippingAddressFragment} from '../fragments';

const REMOVE_CART_ITEM = gql`
	mutation removeItem($cart_id: String!, $cart_item_id: Int!){
        removeItemFromCart(
            input: {
                cart_id: $cart_id,
                cart_item_id: $cart_item_id
            }
        )
        {
            cart{
				global_errors
				applied_coupons {
					code
				}
                total_quantity
				...ProductListingFragment
				prices {
					...cartPriceFragment
				}
				shipping_addresses{
					...cartShippingAddressFragment
				}
            }
        }
    }
    ${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
`;

export default REMOVE_CART_ITEM;
