import { gql } from '@apollo/client';

const GET_WHATSAPP_API = gql`
    query{
        getWhatsappApi{
            _id
            web_link
            icon
            app_link
            enable
        }
    }
`;
export default GET_WHATSAPP_API;
