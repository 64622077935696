import {gql} from '@apollo/client';

const GET_FEATURED_MAGAZINE = gql`
query{
    getFeaturedMagazine {
      _id
      description
      featuredMagazine_coverpage
      featuredMagazine_url
    }
  }
`;

export default GET_FEATURED_MAGAZINE;