import {gql} from '@apollo/client';

const GET_PRODUCT_TAGS = gql`
    query productTags($id : Int){
        GetAttributesBySku(id: $id){
            tags{
                image
                position
                tag_description
                tag_title
                tag_id
                status
            }

        }
    }
`;

export default GET_PRODUCT_TAGS;
