import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Style from './productGalleryStyle.module.scss';
import { BigEntries, SmallEntries } from './galleryElement';

const RenderGalleryData = ({parentDataAndFunctions}) => {
    return(
        <>
            <div className={Style.gallery_wrapper}>
                        <div className={Style.gallery_images_loader_wrapper}>
                            <div className={Style.gallery_loader_wrapper}>
                                <CircularProgress className={Style.loader} size={35} />
                            </div>
                            <div className={Style.gallery_images_wrapper}>
                                <BigEntries parentDataAndFunctions={parentDataAndFunctions}/>
                            </div>
                            <SmallEntries parentDataAndFunctions={parentDataAndFunctions}  />
                        </div>
            </div>
        </>
    )
}

export default RenderGalleryData;
