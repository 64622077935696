import { gql } from '@apollo/client';

const GET_CANCELLABLE_ORDER = gql`
    query($orderId:String!){
        getCancelableOrder(orderId: $orderId){
            order{
                isFullCancel
            }
        }
    }
`

export default GET_CANCELLABLE_ORDER;