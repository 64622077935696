import { Fragment } from 'react';
import {Desktop, Mobile, isDesktop} from '@config/dentalkart.environment';
import Skeleton from '@components/skeleton';
import Style from './cartSkeleton.module.scss';

export const TotalSecitonSkeletol = () => {
    return(
          <div className={Style.skeleton_totals_wrapper}>
            {[1, 2, 3, 4, 5,6, 7].map(key =>
                <div className={Style.skeleton_totals_row_wrapper} key={`${key}-Index`}>
                    <Skeleton width="130px" height="10px" borderRadius="5px" />
                    <Skeleton width="130px" height="10px" borderRadius="5px"/>
                </div>
            )} 
            <p style={{marginTop:20}}>
                <Skeleton width="100%" height="45px" />
            </p>
        </div>
    )
}


const CartSkeleton = _ => {
    return (
        <>
            <div className={Style.cart_skeleton_wrapper}>
                <div className={Style.skeleton_left_section}>
                    <Skeleton width="200px" height="25px" />
                    {
                        [1, 2, 3].map(key =>
                            <Fragment key={key} >
                                <div className={Style.row_skeleton_wrapper}>
                                    <div className={Style.image_skeleton_wrapper}>
                                        <Skeleton width={isDesktop ? "200px" : "50px"} height={isDesktop ? "200px" : "50px"} />
                                    </div>
                                    <div className={Style.content_quantity_wrapper}>
                                        <div className={Style.row_content_wrapper}>
                                            <Skeleton width={isDesktop ? "300px" : "150px"} height={isDesktop ? "20px" : "10px"} />
                                            <Skeleton width={isDesktop ? "150px" : "70px"} height={isDesktop ? "20px" : "10px"} />
                                            <Skeleton width={isDesktop ? "200px" : "100px"} height={isDesktop ? "20px" : "10px"} />
                                            <Skeleton width={isDesktop ? "120px" : "120px"} height={isDesktop ? "20px" : "10px"} />
                                        </div>
                                        <Desktop>
                                            <div className={Style.quantity_skeleton_wrapper}>
                                                <Skeleton width="90px" height="15px" />
                                                <Skeleton width="100px" height="25px" />
                                            </div>
                                        </Desktop>
                                    </div>
                                    <Desktop>
                                        <div className={Style.row_skeleton_action_wrapper}>
                                            <Skeleton width="90px" height="35px" />
                                            <Skeleton width="90px" height="35px" />
                                        </div>
                                    </Desktop>
                                </div>
                                <Mobile>
                                    <div className={Style.row_skeleton_action_wrapper}>
                                        <Skeleton width="90px" height="35px" />
                                        <Skeleton width="90px" height="35px" />
                                    </div>
                                </Mobile>
                            </Fragment>
                        )
                    }
                </div>
                <Desktop>
                    <div style={{ width: "30%", padding: "10px" }}>
                        <Skeleton width="100%" height="40px" />
                        <Skeleton width="100%" height="50px" />
                        <Skeleton width="100%" height="100px" />
                        <TotalSecitonSkeletol/>
                    </div>
                </Desktop>
            </div>
        </>
    )
}

export default CartSkeleton;