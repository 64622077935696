import {gql} from '@apollo/client';

const GET_NOTICES = gql`
    query{
        notices(sections: []) {
            colour
            content
            content_type
            notification_type
            section
            sort_order
            source
        }
    }
`;

export default GET_NOTICES;