import {Desktop, isDesktop} from '@config/dentalkart.environment';
import Skeleton from '@components/skeleton';
import Style from './categorySkeleton.module.scss';

const CategoryScreenSkeleton = () => {
    return(
        <div className={Style.category_wrapper}>
            <div className={Style.skeleton_loader}>
                <div className={Style.filter_product_placeholder_wrapper}>
                    <div className={Style.product_placeholder_wrapper}>
                        <Desktop>
                            <div className={Style.heading_placeholder_wrapper}>
                                <Skeleton width="100%" height="45px" />
                            </div>
                        </Desktop>
                        <div className={Style.products_placeholder_wrapper}>
                            {[1,2,3,4,5,6,7,8].map((item, index) =>
                                <div key={index} className={Style.product_placeholder}>
                                    <div className={Style.image_placeholder_wrapper}>
                                        <Skeleton width="100%" height={isDesktop ? "200px" : "100px"} />
                                    </div>
                                    <div className={Style.details_placeholder_wrapper}>
                                        <Skeleton width="90%" height={isDesktop ? "20px" : "10px"} />
                                        <Skeleton width="35%" height={isDesktop ? "20px" : "10px"} />
                                        <Skeleton width="80%" height={isDesktop ? "20px" : "10px"} />
                                        <Skeleton width="50%" height={isDesktop ? "20px" : "10px"} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryScreenSkeleton