import { gql } from '@apollo/client';

const GET_FIRST_DESKTOP = gql`
    query{
        gethomepagesliders{
	        mobile_image
	        web_image
	        link
	        small_image
	        title
	        id
			relative
    	}
        gethomepagebrands{
		    brand_name
	        brand_image
	        link
	        web_image
	        small_image
	        mobile_image
	        title
	        id
  		}
  		gethomepagesalesbanner{
        	section_enabled
            mobile_image
            fallback_web_image
            fallback_mobile_image
            web_image
            page_url
            before_sale_page_url
            start_time
            end_time
            timer_color
            desktop_timer_position
            mobile_timer_position
        }
		gethomepagecarousel{
		    heading
		    heading_url
		    view
		    sku
		}
  		gethomepagebannersv2{
	        web_image
	        mobile_image
	        link
	        alt
	        small_image
	        title
			relative
	        id
    	}
    }
`;
export default GET_FIRST_DESKTOP;


