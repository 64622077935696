import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import isDeepEqual from "fast-deep-equal/react";
import { magentoLiveClient } from "@apolloClient";
import PropTypes from "prop-types";
import AppContext from "@helpers/appContext";
import isInvalidCart from "@helpers/inActiveCartError";
import BrowserPersistence from "@helpers/browser_persistence";
import FetchSkeleton from "@components/fetchSkeleton";
import { ComponentLoader } from "@components/loader";
import { FullScreenLoader } from "@components/loader";

const storage = new BrowserPersistence();

const FetchLoader = ({ loaderType }) => {
    const [state, setState] = useState(loaderType);

    useEffect(() => {
        setState(loaderType);
    }, [loaderType]);

    const getComponent = {
        'fullscreen': <FullScreenLoader />,
        'default': <ComponentLoader />,
    };

    return getComponent[state] || getComponent["default"];
};

const GetQueryData = ({ children, queryConfiguration, page, loaderType, displayError }) => {
    const { globalObject } = useContext(AppContext);
    const [state, setstate] = useState({
        queryConfigurationData: queryConfiguration,
    });
    const { gql, queryClient, queryFetchPolicy, variables, sendLoadingState } = state.queryConfigurationData;

    if (!isDeepEqual(queryConfiguration, state.queryConfigurationData)) {
        setstate({ queryConfigurationData: queryConfiguration });
    }

    const { data, loading, error, fetchMore, refetch } = useQuery(gql, {
        client: queryClient || magentoLiveClient,
        fetchPolicy: queryFetchPolicy || "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { ...variables },
    });

    if (error) {
        if (page === "cartPage") {
            const shouldRetry = !error?.networkError && isInvalidCart(error);
            if (shouldRetry) {
                storage.removeItem("customer_cart_id");
                storage.removeItem("guest_cart_id");
                globalObject.getGuestAndCustomerCartId();
            }
            console.log("cartError", error);
        } else if (displayError) {
            globalObject.handleError(error);
        } else return null;
    }

    if (data || loading) {
        if (sendLoadingState) {
            return children(data, loading);
        } else {
            if (loading) return page ? <FetchSkeleton page={page} /> : <FetchLoader loaderType={loaderType} />;
            if (data) return children(data, fetchMore, refetch);
        }
    }

    return null;
};

GetQueryData.propTypes = {
    children: PropTypes.func.isRequired,
    queryConfiguration: PropTypes.object.isRequired,
};

GetQueryData.defaultProps = {
    sendLoadingState: false,
    displayError: true,
};

export default GetQueryData;
