import { gql } from '@apollo/client';

const REORDER_ITEMS_MUTATION = gql`
    mutation dkReorderItems(
        $order_number: String!
    ){
        dkReorderItems(orderNumber:$order_number){
            cart{
                id
                email
                items {
                    id
                    product {
                      sku
                    }
                    quantity
                    prices {
                      price {
                        value
                      }
                    }
                }
            }
            userInputErrors{
                message
                path
                code
            }
        }
    }
`;

export default REORDER_ITEMS_MUTATION;
