import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";

import {productData, ga4Items} from './helper.js';
const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const PaymentPageEvents = {
    PLACE_ORDER: () => {
        const PLACE_ORDER_DATA = {
            event: "checkout",
            pageName: "detnalkart|Checkout Step2|Payment",
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 2,
                        option: "Place Order",
                        dimension11: "Razorpay",
                    },
                    products: storage.getItem("placeOrderProduct"),
                    adsProduct:storage.getItem("placeOrderAdsProduct")
                },
            },
        };
        storage.removeItem("placeOrderProduct");
        storage.removeItem("placeOrderAdsProduct");
        storage.removeItem("parentData");
        return PLACE_ORDER_DATA;
    },
    SELECT_PAYMENT_OPTION: (data) => {
        const SELECT_PAYMENT_OPTION_DATA = {
            event: "checkoutOption",
            shippingMethod: data.code,
            ecommerce: {
                checkout_option: {
                    actionField: { step: 3, option: data.code },
                    dimension7: getEncryptedEmail()
                },
            },
        };
        return SELECT_PAYMENT_OPTION_DATA;
    },

    PAYMENT_FAILED: ({ data, prod }) => {
        const PAYMENT_FAILED = {
            event: "Click",
            eventCategory: "Payment Failed",
            eventAction: `orderid: ${data.order_id}`,
            eventLabel: data.amount,
            amount: data.amount,
            totalAmount: data.totalAmount,
            products: [...prod[0].items],
            reason: "Razorpay Error: Invalid signature passed.",
            dimension7: getEncryptedEmail()
        };
        return PAYMENT_FAILED;
    },
    SHIPPING_DETAILS: (data) => {
        const SHIPPING_DETAILS_DATA = {
            event: "shipping_details",
            eventLabel: "checkout",
            addressArray: { ...data },
            addressString: `${data.street[0]},${data.city},${data.region.region} ${data.postcode} ${data.telephone} ${data.country_code} `,
        };
        return SHIPPING_DETAILS_DATA;
    },
    PAYMENT_FALIURE: (data) => {

        const PAYMENT_FAILUE_GA4 = {
            event: "event_paymentfailed",
            eventCategory: "Payment",
            eventAction: "Transaction Faliure",
            eventLabel: data.order_id,
            eventValue: (data.amount / 100),
            data: data,
            orderId:data.order_id,
            dimension7:getEncryptedEmail(),
        };

        const PAYMENT_FALIURE_DATA = {
            event: "paymentfailed",
            eventCategory: "Payment",
            eventAction: "Transaction Faliure",
            eventLabel: data.order_id,
            eventValue: (data.amount / 100),
            data: data,
            orderId:data.order_id,
            dimension7:getEncryptedEmail(),
        };
        return {ga3:PAYMENT_FALIURE_DATA, ga4:PAYMENT_FAILUE_GA4}
    },

    RETRY_PAYMENT : (order_id) => {
        const RETRY_PAYMENT_DATA_GA4 = {
            event:'event_retrypayment',
            eventCategory: 'Payment',
            eventAction: 'Click',
            eventLabel:'Retry Payment',
            order_id:order_id

        }
        const RETRY_PAYMENT_DATA = {
            event:'retrypayment',
            eventCategory: 'Payment',
            eventAction: 'Click',
            eventLabel:'Retry Payment',
            order_id:order_id

        }
        return {ga3: RETRY_PAYMENT_DATA, ga4:RETRY_PAYMENT_DATA_GA4}
    },



    ORDER_SUCCESS: (data) => {
        let { cart, orderData } = data;
        cart = cart.cart;
        
        const products = [];
        const dymad_pname = [];
        const dymad_prodid = [];
        const dymad_pval = [];
        let ga4_products = []
        // let tax = "0";
        if (cart) {
            cart.items.map((row) => {
                products.push(productData(row.product));
                ga4_products.push(ga4Items(row.product))
                dymad_pname.push(row.product.name);
                dymad_pval.push(row.product.price.minimalPrice.amount.value);
                dymad_prodid.push(row.product.sku);
                return null;
            });
        }
        const ORDER_SUCCESS_DATA = {
            event: "purchaseSuccess",
            pageName: "detnalkart|Purchase Success",
            ecommerce: {
                purchase: {
                    actionField: {
                        id: orderData.orderId, // Transaction ID. Required for purchases and refunds.
                        affiliation: "Online Store",
                        revenue: cart?.prices?.grand_total?.value, // Total transaction value (incl. tax and shipping)
                        tax: cart?.prices?.applied_taxes[0]?.amount?.value,
                        coupon:
                        cart && cart.applied_coupons ? cart.applied_coupons.code : "",
                        dimension7: getEncryptedEmail(),
                        dimension11: orderData.payment_method ? orderData.payment_method : "",
                        currencyCode: cookie.load("currency_code"),
                    },
                    products: products,
                    items:[...ga4_products]
                },
            },
            ecomm_pagetype: "purchase",
            ecomm_prodid: [...dymad_prodid],
            ecomm_pname: [...dymad_pname],
            ecomm_pval: [...dymad_pval],
        };
        return ORDER_SUCCESS_DATA;
    },

    PURCHASE_PAGE_LOAD: (data) => {
        const prodId = [];
        const pname = [];
        let pcat = [];
        const pvalue = [];
        data.cart.items.map((item) => {
            prodId.push(item.product.sku);
            pname.push(item.product.name);
            pcat = [...pcat, ...item.product.categories];
            pvalue.push(item.product.price.maximalPrice.amount.value.toFixed(2));
            return null;
        });
        const PURCHASE_PAGE_LOAD_DATA = {
            ecomm_pagetype: "purchase",
            ecomm_pcat: [...pcat],
            ecomm_prodid: [...prodId],
            ecomm_pname: [...pname],
            ecomm_pvalue: [...pvalue],
            ecomm_totalvalue: data.cart.prices.grand_total.values,
            dimension7: storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest",
        };
        return PURCHASE_PAGE_LOAD_DATA;
    },

}
export default PaymentPageEvents;
