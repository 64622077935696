import { gql } from '@apollo/client';

const GET_MERGE_CART= gql`
    mutation getMergeCart($source_cart_id:String!, $destination_cart_id:String!){
        mergeCarts(source_cart_id: $source_cart_id, destination_cart_id: $destination_cart_id){
            items{
                id
                product{
                    name
                    sku
                }
                quantity
            }
        }
        
    }

`;

export default GET_MERGE_CART;
