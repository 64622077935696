import { lazy } from "react";

export const HomePageScreen = lazy(() => import("./homePage"));
export const BrandPageScreen = lazy(() => import("./brands"));
export const CountryScreen = lazy(() => import("./country"));
export const cartScreen = lazy(() => import("./cart"));
export const ProductDetailsOrListingComponent = lazy(() =>
  import("./productOrCategoryList")
);
export const WriteReview = lazy(() => import("./reviews/writeReview"));
export const AccountScreen = lazy(() => import("./account/accountScreen"));
export const WeightSlabInformationTable = lazy(() =>
  import("./weightWiseShippingCharge")
);
export const Payment = lazy(() => import("./payment"));
export const erroScreen404 = lazy(() => import("@components/errorScreen"));
export const GuestCheckout = lazy(() => import("./guestCheckout"));
export const NavigationScreen = lazy(() => import("./navigation"));
export const ProductReviews = lazy(() => import("./reviews/productReviews"));
export const DealerForm = lazy(() => import("./dealerForm"));
export const SellOnDentalkart = lazy(() => import("./sellOnDentalkart"));
export const ReturnPolicy = lazy(() => import("./returnPolicy"));
export const TermsConditions = lazy(() => import("./tnc"));
export const PrivacyPolicy = lazy(() => import("./privacyPolicy"));
export const SearchScreen = lazy(() => import("./search/customSearch"));
export const AboutUs = lazy(() => import("./aboutUs"));
export const SaleScreen = lazy(() => import("./sale"));
export const GenericFormScreen = lazy(() => import("./genericForm"));
export const MembershipSreen = lazy(() => import("./memberShipScreen"));
export const Magazine = lazy(() => import("./magazine"));
export const FlipBook = lazy(() => import("./magazine/module/pdfReader/flipBook"));
export const ProductSearch = lazy(() => import("./productSearch"));
export const Investors = lazy(() => import("./investors")); 
export const InvestorsScreen = lazy(() => import("./investorPage")); 