import BrowserPersistence from "@helpers/browser_persistence";
const storage = new BrowserPersistence();

const token = storage.getItem("token");

export const postRequest = (url, params, api_key = "") => {
  return fetch(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-api-key": api_key,
    }),
    body: JSON.stringify(params),
  });
};
