import { gql } from '@apollo/client';

const cartRewardFragment = gql`
    fragment cartRewardFragment on RewardsCartInfo {
        earn_points
        earn_points_value
        balance
        reward_term
        reward_icon_url
        exchange_rate_info
        exchange_rate
        exchange_rate_currency
        max_point_to_checkout
        max_point_message
        applied_points
        applied_points_value
        reward_gain_info
        currency
    }
`;

export default cartRewardFragment;
