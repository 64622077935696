
export const sendQueryResponse = (resdata = {},url) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "cyUi8JOplpParc8RqoeQcYkQiFNzOjePQJZMLAYNi");
    myHeaders.append("Content-Type", "application/json");

    let jsonData = JSON.stringify({...resdata});
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: jsonData,
        redirect: 'follow',
    };

    // fetch(`https://tracer.dentalkart.com/prod/api/V1${url}`, requestOptions)
    fetch(`https://8berd09w4d.execute-api.ap-south-1.amazonaws.com/prod/api/V1${url}`, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

}

export const queryUrl = {
    product_view: "/product_view",
    query_error:"/query_error",
    add_to_cart:"/add_to_cart",
    wishlist_action:"/wishlist_action"
}
