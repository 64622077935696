import React, { useContext, Fragment, useState } from 'react';
import Style from './nav.module.scss';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import chunkify from '@helpers/chunkify';
import {isArray} from '@helpers/variableDataType';
import { Link } from 'react-router-dom';
import GetQueryData from '@components/getQueryComponent'
import { GET_NAVIGATION_DESKTOP } from '@graphql';
import AppContext from '@helpers/appContext';
import sortCategory from '@helpers/sortByPosition';
import {appSyncLiveCLient} from '@apolloClient';
import fireEvents from '@helpers/event_data_layer';

const NavigationContainer  =  (props)  => {
    const {routes} = useContext(AppContext);

    const [state, setState] = useState({
        activeCat: ''
    })

    let navigationImages = {
        8: {name: "Orthodontics", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/orthodontics.png"},
        37: {name: "Endodontics", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/endodontic.png"},
        59: {name: "Oral Surgery", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/orals.png"},
        71: {name: "Prosthodontics", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/prosthodontics.png"},
        99: {name: "Periodontics", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/periodontics.png"},
        105: {name: "Paedodontics", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/paedo.png"},
        125: {name: "Implantology", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/implantology.png"},
        131: {name: "General Dentistry", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/General-Dentistry.png"},
        133: {name: "Restorative", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/restrorative.png"},
        152: {name: "Laboratory", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/Laboratory.png"},
        180: {name: "Clinic Setup", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/clinic-setup.png"},
        182: {name: "Sterilization", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/sterilization.png"},
        186: {name: "Equipments", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/equipment.png"},
        269: {name: "Instruments", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/Instruments.png"},
        270: {name: "Patient Education", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/patient-education-model.png"},
        605: {name: "Offer Zone", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/offer-zone.png"},
        613: {name: "Student Section", image_url: "https://s3.ap-south-1.amazonaws.com/dentalkart-media/navigation+banner/Student-Section.png"}
    }


    const showCat = data => _ => {
        if(state.activeCat !== data.id)
            setState({activeCat: data.id});
        else return;
    }

    const renderSubCategory = (data, bgImage='') => {
        let item = {...data}
        if(item.children.length === 0)
            return null;
        item.children = sortCategory(item.children);
        const sub_cat = chunkify(item.children, 3, 20);

        const handleSubCategoryEvent = (child_cat,item) => {
            const subItem = {...child_cat,parent_name:item.name};
            fireEvents('GTM', 'SHOP_BY_SUB_CATEGORIES',subItem);
        }

        return(
            <div className={Style.child_list_container} style={{backgroundImage: `url(${bgImage})`}}>
                <div className={Style.wrap_columns}>
                    {sub_cat.map((column, index) =>
                        <ul key={index+'parent'}>
                            {isArray(column) && column.map((child_cat, index) =>
                                <Fragment key={index}>
                                    {child_cat.include_in_menu === 1 &&
                                        <li className={Style.child_list}>
                                            <Link to={`/${child_cat.url_path}.html`} onClick={()=> handleSubCategoryEvent(child_cat,item)}>
                                                {child_cat.name}
                                            </Link>
                                        </li>
                                    }
                                </Fragment>
                            )}
                            {sub_cat.length-1 === index &&
                                <li className={Style.all_category} key={column.length+'parent'}>
                                    <Link to={item.id !== 496 ? `/${item.url_path}.html` : routes.GO_TO_FEATURED_BRANDS} onClick={()=>fireEvents('GTM', 'SHOP_BY_SUB_CATEGORIES_ITEM',item)}>
                                        All {item.name}
                                    </Link>
                                </li>
                            }
                        </ul>
                    )}
                </div>
            </div>
        )
    }

    const queryConfiguration= {
        gql: GET_NAVIGATION_DESKTOP,
        queryClient: appSyncLiveCLient,
        queryFetchPolicy :'cache-and-network'
    }

    return (
        <Fragment>
             <GetQueryData queryConfiguration={queryConfiguration} displayError={false}>
                {(data) => {
                    if (data && data.categoryList) {
                        let categoryObj = {...data.categoryList[0]};
                        categoryObj.children = sortCategory(data.categoryList[0].children);
                        const navImg = navigationImages;
                        return(
                            <ul className={Style.list_container}>
                                {categoryObj.children.map(parent_cat =>
                                    <Fragment key={parent_cat.id}>
                                        {parent_cat.include_in_menu === 1 &&
                                            <li onMouseOver={showCat(parent_cat)}>
                                                <Link to={parent_cat.id === 496 ? routes.GO_TO_FEATURED_BRANDS : `/${parent_cat.url_path}.html`} onClick={() => fireEvents('GTM', 'SHOP_BY_CATEGORIES',parent_cat)} >
                                                    {parent_cat.name}
                                                    {parent_cat.children.length !== 0 && <KeyboardArrowRight />}
                                                </Link>
                                                {renderSubCategory(parent_cat, navImg[parent_cat.id] ? navImg[parent_cat.id].image_url : '')}
                                            </li>
                                        }
                                    </Fragment>
                                )}
                                <li className={Style.store_list}>
                                    <Link className={Style.store_list_text} to={routes.GO_TO_NAVIGATION}>Full Store Directory</Link>
                                </li>
                            </ul>
                        )
                    }else return null;
                }}
            </GetQueryData>
        </Fragment>
    )

}
export default NavigationContainer;
