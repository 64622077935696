import { gql } from '@apollo/client';

const GET_INVESTOR_RELATION = gql`
    query getInvestorRelation{
        getInvestorRelation{
                _id
                module_name
                collapsableItems{
                    id
                    title
                    text
                    list{
                        id
                        title
                        url
                    }
                }
                text
                list{
                    id
                    title
                    url
                }
                updated_at
                created_at
        }
    }
`;

export default GET_INVESTOR_RELATION;