import { gql } from "@apollo/client";

const FETCH_ORDER_DETAILS = (isVersionTwo = false) =>
  isVersionTwo
    ? gql`
        query fetchOrderV2(
          $order_id: String!
          $rzp_payment_id: String
          $rzp_order_id: String
        ) {
          fetchOrderV2(
            input: {
              order_id: $order_id
              rzp_payment_id: $rzp_payment_id
              rzp_order_id: $rzp_order_id
            }
          ) {
            order_id
            status
            order_detail_available
            can_refetch
            can_retry_payment
            amount
            currency
            order_created_at
            failure_wait_time
            error_msg
            reference_number
            merchant_id
          }
        }
      `
    : gql`
        query fetchOrder(
          $order_id: String!
          $rzp_payment_id: String
          $rzp_order_id: String
          $rzp_signature: String
          $ORDERID: String
          $MID: String
          $TXNID: String
          $TXNAMOUNT: Float
          $PAYMENTMODE: String
          $CURRENCY: String
          $TXNDATE: String
          $STATUS: String
          $RESPCODE: String
          $RESPMSG: String
          $GATEWAYNAME: String
          $BANKTXNID: String
          $BANKNAME: String
          $CHECKSUMHASH: String
        ) {
          fetchOrder(
            input: {
              order_id: $order_id
              rzp_payment_id: $rzp_payment_id
              rzp_order_id: $rzp_order_id
              rzp_signature: $rzp_signature
              ORDERID: $ORDERID
              MID: $MID
              TXNID: $TXNID
              TXNAMOUNT: $TXNAMOUNT
              PAYMENTMODE: $PAYMENTMODE
              CURRENCY: $CURRENCY
              TXNDATE: $TXNDATE
              STATUS: $STATUS
              RESPCODE: $RESPCODE
              RESPMSG: $RESPMSG
              GATEWAYNAME: $GATEWAYNAME
              BANKTXNID: $BANKTXNID
              BANKNAME: $BANKNAME
              CHECKSUMHASH: $CHECKSUMHASH
            }
          ) {
            order_id
            status
            order_detail_available
            can_refetch
            can_retry_payment
            amount
            currency
            order_created_at
            failure_wait_time
            error_msg
            reference_number
            merchant_id
          }
        }
      `;

export default FETCH_ORDER_DETAILS;
