import {gql} from '@apollo/client';

const SOCIAL_LOGIN = gql`
    mutation generateSocialLoginCustomerToken($token: String!, $type: String!, $quoteId: String){
        dkgenerateSocialLoginCustomerToken(token: $token, type: $type, quoteId: $quoteId){
            token
        }
    }
`;

export default SOCIAL_LOGIN
