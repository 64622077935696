import gql from "graphql-tag";

const SELL_ON_DENTALKART = gql`
  mutation sellOnDentalkart(
    $company_name: String!
    $city: String!
    $address: String!
    $gst_number: String!
    $product_name: String!
    $email_id: String!
    $contact_no: String!
    $company_type: String
    $catalogue: String
  ) {
    SellOnDentalkart(
      input: {
        company_name: $company_name
        email_id: $email_id
        company_type: $company_type
        address: $address
        city: $city
        contact_no: $contact_no
        gst_number: $gst_number
        product_name: $product_name
        catalogue: $catalogue
      }
    ) {
      message
    }
  }
`;

export default SELL_ON_DENTALKART;
