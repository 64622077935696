import React, { Fragment } from 'react';
import { Mobile, Desktop, isDesktop } from '@config/dentalkart.environment';
import Style from './homePageStyle.module.scss';
import Skeleton from '../../skeleton';

const BrandBox = () => {
    return (
        <div style={{ width: isDesktop ? '12%' : '25%', textAlign: 'center' }}>
            <div
                style={{
                    borderRadius: '50%',
                    background: '#fff',
                    padding: '4px', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: isDesktop ? '115px' : '65px',
                    height: isDesktop ? '115px' : '65px',
                    margin: 'auto'
                }}
            >
                <p>
                    <Skeleton width={isDesktop ? '50px' : '25px'} height={isDesktop ? '50px' : '25px'} />
                </p>
            </div>
            <p style={{ paddingTop: '4px' }}>
                <Skeleton width={isDesktop ? '80px' : '60px'} height={'10px'} />
            </p>
        </div>
    )
}

const GetTopDescriptionSkeleton = () => {
    return (
        <div style={{ padding: '7px' }} className={Style.flex_class}>
            <p><Skeleton width={isDesktop ? '260px' : '120px'} height={isDesktop ? '25px' : '20px'} /></p>
            <p> <Skeleton width={isDesktop ? '210px' : '120px'} height={isDesktop ? '50px' : '35px'} /></p>
        </div>
    )
}

const ThreeBannerSectionSkeleton = ({ width, height }) => {
    return (
        <div className={Style.flex_class}>
            {[0,1,2].map((index) => {
                return(
                    <div style={{padding:'5px', width:'33.33%'}} key={index}><Skeleton width={width} height={height}/></div>
                )
            })}
        </div>
    )
}

const TopBannerAndBrandSkeleton = () => {
    return (
        <div>
            <div style={{ padding: '7px', background: '#fff' }}>
                <Skeleton width={'100%'} height={isDesktop ? '240px' : '150px'} />
            </div>
            <GetTopDescriptionSkeleton />
            <Desktop>
                <div style={{ display: 'flex', flexWrap: 'no-wrap', marginBottom: '10px' }}>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((key, index) => {
                        return (
                            <BrandBox key={index} />
                        )
                    })}
                </div>
                <div style={{ background: '#fff' }} className={Style.flex_class}>
                    <div style={{ width: '100%', padding: '10px 10px' }}>
                        <GetTopDescriptionSkeleton />
                        <ThreeBannerSectionSkeleton width={"430px"} height={'200px'} />
                    </div>
                    <div style={{ padding: '5px' }}>
                        <Skeleton width={'285px'} height={'320px'} />
                    </div>
                </div>
            </Desktop>
            <Mobile>
                <div style={{ display: 'flex', flexWrap: 'no-wrap', marginBottom: '10px' }}>
                    {
                        [0, 1, 2, 3].map((index) => {
                            return (
                                <BrandBox key={index} />
                            )
                        })
                    }
                </div>
            </Mobile>
        </div>
    )

}

const ImageBoxSkeleton = () => {
    return (
        <div style={{ textAlign: 'center', padding: isDesktop ? '15px' : '4px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton width={isDesktop ? '150px' : '120px'} height={isDesktop ? '150px' : '120px'} />
            </div>
            <p style={{ marginTop: '5px' }}><Skeleton width={isDesktop ? '200px' : '140px'} height='15px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '140px'} height='15px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '120px'} height='10px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '100px'} height='10px' /></p>
        </div>
    )
}

export const GetHomePageProductsSkeleton = ({ IndexKey }) => {
    return (
        <Fragment>
            <div className={Style.skeleton_box}>
                <div style={{ borderBottom: '1px solid #dddd' }}>
                    <GetTopDescriptionSkeleton />
                </div>
                {isDesktop ?
                    <div className={Style.flex_class}>
                        {
                            [1,2,3,4,5,6,7].map((data, index)=>{
                                return(
                                    <div style={{width: '14%', margin:'auto'}} key={index}>
                                        <ImageBoxSkeleton/>
                                    </div>
                                )
                            })
                        }
                    </div>: null
               }
               <Mobile>
                   <div style={{display:'flex', flexWrap : 'wrap'}}>
                       {
                           [1,2,3,4].map((data, index)=>{
                               return(
                                   <div style={{width: '50%'}} key={`Id${index}`}>
                                       <ImageBoxSkeleton/>
                                   </div>
                               )
                           })
                       }
                   </div>
               </Mobile>
            </div>
            {(IndexKey !== 8 && IndexKey !== 9) &&
                <Fragment>
                    {isDesktop ?
                        <div style={{ padding: '10px' }} className={Style.skeleton_box}>
                            <ThreeBannerSectionSkeleton width={"540px"} height={'280px'} />
                        </div> :
                        <div style={{ padding: '10px' }} className={Style.skeleton_box}>
                            <Skeleton width={'100%'} height='150px' />
                        </div>
                    }
                </Fragment>
            }
        </Fragment>
    )
}

const HomePageSkeleton = () => {
    return(
         <>
            <TopBannerAndBrandSkeleton/>
            {[1,2,3,4,5,6,7,8,9,10].map((index)=>{
                return(
                    <GetHomePageProductsSkeleton IndexKey={index} key={index}/>
                )
            })}
        </>
    )

}

export default HomePageSkeleton;
