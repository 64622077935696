import React from 'react';
import { isDesktop } from '@config/dentalkart.environment';
import Skeleton from '../../skeleton';
import Style from './orderListSkeleton.module.scss';

const SkeletonComponent = () => {
    return isDesktop ? (
        <div className={[Style.flex_class, Style.order_wrapper].join(' ')}>
            <p> <Skeleton height={'30px'} width={'250px'} /></p>
            {[0, 1, 2, 3, 4, 5].map((item, index) => {
                return (
                    <p key={index}><Skeleton height={'30px'} width={'120px'} /></p>
                )
            })
            }
        </div>
    ) :
        (
            <div className={Style.container_wrapper}>
                <div className={Style.flex_class}>
                    <p> <Skeleton height={'20px'} width={'100px'} /></p>
                    <p> <Skeleton height={'20px'} width={'100px'} /></p>
                </div>
                <p> <Skeleton height={'20px'} width={'150px'} /></p>
                <div className={Style.flex_class}>
                    <p> <Skeleton height={'20px'} width={'100px'} /></p>
                    <p> <Skeleton height={'20px'} width={'100px'} /></p>
                </div>
                <div className={Style.mobile_button_wrapper}>
                    <p style={{ marginRight: '15px' }}> <Skeleton height={'20px'} width={'100px'} /></p>
                    <p> <Skeleton height={'20px'} width={'100px'} /></p>
                </div>
            </div>
        )

}

const OrderListSkeleton = () => {
    return(
        <div className={isDesktop ? Style.container_wrapper : ''}>
            {
                [0, 1, 2, 3, 4, 5, 6, 7, 8].map((data, index) => {
                    return (
                        <SkeletonComponent key={index} />
                    )
                })
            }
        </div>
    )
}

export default OrderListSkeleton;
