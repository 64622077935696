import {gql} from '@apollo/client';

const URL_RESOLVER_QUERY = gql`
    query urlResolver($url: String!){
        urlResolver(url: $url) {
            id
            type
        }
    }`;

export default URL_RESOLVER_QUERY;
