const headerConfiguration = {
    default: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    home: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    category: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    cart: {
        params: { logoSection :true, searchSection: true, walletSection: false, profileSection: true, moreSection: false, cartSection: false, bellSection: true},
        icons: {}
    },
    payment: {
        params: { logoSection :true, searchSection: false, walletSection: false, profileSection: false, moreSection: false, cartSection: false, bellSection: false},
        icons: {}
    },
    login: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: false, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    account: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    navigation: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    product: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    writeReview: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    productReviews: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    country: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    brands: {
        params: { logoSection :true, searchSection: true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {}
    },
    pdr: {
        params: { logoSection :true, searchSection: false, walletSection: false, profileSection: false, moreSection: false, cartSection: false, bellSection: true},
        icons: {}
    },
     algoliaSearchScreen : {
		params: { logoSection :true, searchSection: false, algoliaSearch:true, walletSection: true, profileSection: true, moreSection: true, cartSection: true, bellSection: true},
        icons: {},
        algoliaScreen: true
    }
}
export default headerConfiguration;
