import { gql } from '@apollo/client';

const GET_FEATURED_SEARCH = gql`
    query{
        searchpageDetails {
		    image_data {
		      img
		      link
		    }
    		product_data
        }
    }
`;
export default GET_FEATURED_SEARCH;
