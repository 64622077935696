const fixedFooter = (isDesktop) => {
  return {
    sections: {
      about: {
        visible: true,
        heading: "About",
        list: [
          { name: "Contact Us", url: "GO_TO_HELP_CENTER", event: "CONTACT_US" },
          { name: "About Us", url: "GO_TO_ABOUT_US", event: "ABOUT_US" },
          // {
          //   name: "Investor relations",
          //   url: "GO_TO_INVESTORS",
          //   new: true,
          //   newPage: true,
          //   event: "INVESTOR_FOOTER",
          // },
          {
            name: "Investor relations",
            url: "GO_TO_INVESTORS_NEW",
            new: true,
            newPage: true,
            event: "INVESTOR_FOOTER",
          },
          {
            name: "Careers",
            url: "GO_TO_CAREERS",
            new: true,
            newPage: true,
            event: "CAREERS_FOOTER",
          },
          {
            name: "Dealer Price Bulk Inquiry",
            url: "GO_TO_DEALER_FORM",
            new: true,
            event: "DEALER_PARTNERSHIP",
          },
          {
            name: "Sell On Dentalkart",
            url: "GO_TO_SELL_ON_DENTALKART",
            new: true,
            event: "SELL_ON_DENTALKART",
          },
          {
            name: "Blogs",
            url: "GO_TO_BLOGS",
            new: true,
            newPage: true,
            event: "FOOTER_BLOGS",
          },
        ],
      },
      help: {
        visible: isDesktop,
        heading: "Help",
        list: [
          { name: "Orders", url: "GO_TO_FAQ_ORDERS", event: "ORDERS_FOOTER" },
          {
            name: "Refunds",
            url: "GO_TO_FAQ_REFUNDS",
            event: "REFUNDS_FOOTER",
          },
          {
            name: "Payments",
            url: "GO_TO_FAQ_PAYMENTS",
            event: "PAYMENTS_FOOTER",
          },
          {
            name: "Rewards",
            url: "GO_TO_FAQ_REWARDS",
            event: "REWARDS_FOOTER",
          },
          {
            name: "Dentalkart International",
            url: "GO_TO_FAQ_INTERNATIONAL",
            event: "DENTALKART_INTERNATIONAL_FOOTER",
          },
          // {
          //   name: "Request a Product",
          //   url: "GO_TO_SEARCH",
          //   event: "",
          // },
        ],
      },
      policy: {
        visible: true,
        heading: "Policy",
        list: [
          {
            name: "Return Policy",
            url: "GO_TO_RETURN_POLICY",
            event: "RETURN_POLICY_FOOTER",
          },
          {
            name: "Term Of Use",
            url: "GO_TO_TERMS_OF_USE",
            event: "TERMS_OF_USE_FOOTER",
          },
          { name: "Privacy", url: "GO_TO_PRIVACY", event: "PRIVACY_FOOTER" },
          { name: "Sitemap", url: "GO_TO_SITEMAP", event: "SITEMAP_FOOTER" , newPage: true,},
        ],
      },
      social: {
        visible: isDesktop,
        heading: "Social",
        list: [
          {
            name: "Facebook",
            url: "GO_TO_FACEBOOK",
            icon: "facebook-square",
            newPage: true,
          },
          {
            name: "Twitter",
            url: "GO_TO_TWITTER",
            icon: "twitter-square",
            newPage: true,
          },
          {
            name: "Pinterest",
            url: "GO_TO_PINTREST",
            icon: "pinterest-square",
            newPage: true,
          },
          {
            name: "Youtube",
            url: "GO_TO_YOUTUBE",
            icon: "youtube-square",
            newPage: true,
          },
          {
            name: "Linkedin",
            url: "GO_TO_LINKEDIN",
            icon: "linkedin",
            newPage: true,
          },
          {
            name: "Instagram",
            url: "GO_TO_INSTAGRAM",
            icon: "instagram",
            newPage: true,
          },
        ],
      },
    },
    address: {
      company: `VASA DENTICTY LIMITED,`,
      street: `Khasra No. 714, Village, P.O.- Chattarpur,`,
      locality: `Opp. DLF Gate No.-2,`,
      landmark: `Near Geetanjali Salon`,
      city: `New Delhi,`,
      country: `India,`,
      postcode: `110074`,
      phone: `+91-728-9999-456`,
      phoneCompact: `+91-7289999456`,
    },
  };
};

export default fixedFooter;
