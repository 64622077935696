import { gql } from '@apollo/client';

const LOGOUT = gql`
    mutation{
        revokeCustomerToken{
            result
        }
    }
`;

export default LOGOUT
