import { gql } from "@apollo/client";

const ADD_DEALER_ADDRESS = gql`
  mutation dealerInquiry(
    $first_name: String!
    $last_name: String!
    $type_of_company: String!
    $email: String!
    $company_name: String!
    $tax_vat_number: String!
    $address: String!
    $website: String!
    $city: String!
    $zip_postal_code: String!
    $country: String!
    $state: String!
    $contact_number: String!
    $alternate_mobile: String
    $brands: String
    $investment: String
    $business_description: String!
    $files: [String]!
    $product_template: String
  ) {
    dealerInquiry(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        type_of_company: $type_of_company
        company_name: $company_name
        tax_vat_number: $tax_vat_number
        address: $address
        website: $website
        city: $city
        zip_postal_code: $zip_postal_code
        country: $country
        state: $state
        contact_number: $contact_number
        alternate_mobile: $alternate_mobile
        brands: $brands
        investment: $investment
        business_description: $business_description
        files: $files
        product_template: $product_template
      }
    ) {
      message
    }
  }
`;
export default ADD_DEALER_ADDRESS;
