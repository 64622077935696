import React, {
    useState,
    Fragment,
    useContext,
    useEffect,
    useRef,
  } from "react";
  import Style from "./desktopHeader.module.scss";
  import Button from "@material-ui/core/Button";
  import Search from "@material-ui/icons/Search";
  import SearchBar from "@screens/search/modules/searchBar";
  import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
  import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
  import CartIcon from "@material-ui/icons/ShoppingCart";
  import NotificationsIcon from "@material-ui/icons/Notifications";
  import MenuList from "./menuList.js";
  import { Link } from "react-router-dom";
  import NavigationContainer from "@screens/navigation/modules/desktopNavigation";
  import AppContext from "@helpers/appContext";
  import cookie from "react-cookies";
  import isDeepEqual from "fast-deep-equal/react";
  import fireEvents from "@helpers/event_data_layer";
  // import CountryModal from "./countryModal";
  import BrowserPersistence from "@helpers/browser_persistence";


  const storage = new BrowserPersistence();

  const DesktopHeader = (props) => {
    const {
      configuration,
      history: {
        location: { pathname },
      },
    } = props;
    const headerRef = useRef(configuration);
    
    const handleOpenAddressModal = () => {
      setIsAddressModalOpen(true);
    };
  
    const [state, setState] = useState({
      configurationData: configuration,
    });
  
    if (!isDeepEqual(headerRef.current, configuration)) {
      headerRef.current = configuration;
    }
  
    useEffect(() => {
      setState({ configurationData: configuration });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current]);
  
    const hanndleLoginSignup = () => {
      globalObject.showAuthenticationModal();
      fireEvents("GTM", "LOGIN_SIGNUP");
    };
  
    const country_id = cookie.load("country_id");
    // const country_id = "IN";
 

    const { routes, globalObject, DentalkartConfig, history, shippingAddress, setShippingAddress, IsAddressModalOpen ,setIsAddressModalOpen} =
      useContext(AppContext);
    const { walletMoney, isLoggedIn, userInfo, setSearchValue } = props;
    const { configurationData } = state;
    const cartItemCount = globalObject.state.cartCount;
    const {
      params: {
        logoSection,
        searchSection,
        algoliaSearch,
        walletSection,
        profileSection,
        moreSection,
        cartSection,
        bellSection,
      },
    } = configurationData;

    return (
      <div className={Style.desktop_header}>
        <div className={Style.header_wrapper}>
          <div className={Style.header}>
            {logoSection && (
              <Link
                to={routes.GO_TO_HOME}
                className={Style.dentalkart_logo}
                onClick={() => fireEvents("GTM", "HOME_LOGO", { pathname })}
              >
                <img
                  src="https://images.dentalkart.com/Dentalkart_Logo_XDiq2JLyp.png?updatedAt=1686124352821"
                  alt="Dentalkart"
                />
              </Link>
            )}
            <div className={Style.country_wrapper}>
              {country_id ? (
                <div className={`${Style.country} ${pathname !== "/payment" ? Style.add_pointer:""}` } onClick={pathname !== "/payment" ? handleOpenAddressModal:null}>
                  <img
                    src={`${
                      routes.MEDIA_URL
                    }flags/${country_id.toLowerCase()}.png`}
                    alt="flag"
                  />
                </div>
              ) : (
                <>
                  <div className={`${Style.country} ${pathname !== "/payment" ? Style.add_pointer:""}` } onClick={pathname !== "/payment" ? handleOpenAddressModal:null}>
                    <img src={`${routes.MEDIA_URL}flags/in.png`} alt="flag" />
                  </div>
                    {/* <div className={Style.country_modal_wrapper}>
                      <CountryModal 
                      IsCountryModalOpen={props.IsCountryModalOpen} 
                      setIsCountryModalOpen={props.setIsCountryModalOpen} 
                      setIsAddressModalOpen={setIsAddressModalOpen} 
                      handleCloseCountryModal={props.handleCloseCountryModal}/>
                    </div> */}
                </>
              )}
            </div>
            {/* {!country_id && <div className={`${Style.country_modal_wrapper_outer}${props.IsCountryModalOpen ? Style.country_modal_wrapper_hide : ""}`} onClick={props.handleCloseCountryModal}>
            </div>} */}
            {searchSection ? (
              <Fragment>
                <div className={Style.shop_by_category_wrapper}>
                  <div className={Style.shop_by_category}>
                    Shop by Category
                    <ArrowDown />
                  </div>
                  <div className={Style.navigation_container}>
                    <NavigationContainer />
                  </div>
                </div>
                <div
                  className={Style.search_wrapper}
                  onClick={() => history.push({ pathname: routes.GO_TO_SEARCH })}
                >
                  <span className={Style.search_input_placeholder}>
                    Search for products, brands and more
                  </span>
                  <div className={Style.search_button_wrapper}>
                    <Button className={Style.search_button}>
                      <Search />
                    </Button>
                  </div>
                </div>
              </Fragment>
            ) : null}
            {algoliaSearch ? (
              <div>
                <SearchBar
                  setSearchValue={setSearchValue}
                  customSearch={props.customSearch}
                />
              </div>
            ) : null}
            <div className={Style.sections_wrapper}>
              {isLoggedIn && walletSection && country_id === "IN" ? (
                <Link
                  to={routes.GO_TO_REWARDS}
                  onClick={() => fireEvents("GTM", "REWARD_ZONE")}
                >
                  <div className={Style.wallet_section}>
                    <WalletIcon className={Style.wallet_icon} />
                    <div className={Style.wallet_amount_wrapper}>
                      <span>Rewards</span>
                      {isLoggedIn ? (
                        <span className={Style.wallet_money}>
                          {" "}
                          {walletMoney}{" "}
                        </span>
                      ) : (
                        <span className={Style.wallet_money}> 0 </span>
                      )}
                    </div>
                  </div>
                </Link>
              ) : null}
              {profileSection && (
                <Fragment>
                  {isLoggedIn ? (
                    <div className={Style.profile_section}>
                      <div className={Style.username}>
                        {userInfo ? userInfo.firstname : ""}
                      </div>
                      <ArrowDown className={Style.arrow_down} />
                      <div className={Style.menu_list}>
                        <MenuList data={DentalkartConfig.UserListConfiguration} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={Style.login_signup}
                      onClick={() => hanndleLoginSignup()}
                    >
                      Login & SignUp
                    </div>
                  )}
                </Fragment>
              )}
              {moreSection ? (
                <div className={Style.more_section}>
                  <div className={Style.more}>More</div>
                  <ArrowDown className={Style.arrow_down} />
                  <div className={Style.menu_list}>
                    <MenuList data={DentalkartConfig.MoreListConfiguration} />
                  </div>
                </div>
              ) : null}
              {cartSection && (
                <Link
                  to={routes.GO_TO_CART}
                  className={Style.cart_section}
                  onClick={() => fireEvents("GTM", "CART")}
                >
                  <div className={Style.cart_icon}>
                    <CartIcon />
                    <span className={Style.cart_badge}>{cartItemCount}</span>
                  </div>
                  <span>Cart</span>
                </Link>
              )}
              {bellSection && (
                <div
                  className={Style.notification_icon}
                  id="notification"
                  onClick={() => fireEvents("GTM", "NOTIFICATIONS")}
                >
                  <NotificationsIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default DesktopHeader;
  
