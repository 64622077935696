import { gql } from "@apollo/client";

const GET_MEMBERSHIP_INFO = gql`
  query {
    memberShipInfo {
      isActive
      plans {
        id
        sku
        price
        plan_duration
        is_default
      }
    }
  }
`;

export default GET_MEMBERSHIP_INFO;
