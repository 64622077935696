import React from "react";
import Style from "./productSkeleton.module.scss";
import {
    isDesktop,
} from "@config/dentalkart.environment";
import Skeleton from '../../skeleton';
import  CarauselSkeleton  from '../carauselSkeleton';

const ProductDetailsSkeleton = () => {
    return (
        <>
            <div className={Style.skeleton_loader}>
                <div
                    className={
                        isDesktop
                        ? Style.product_screen_wrapper
                        : Style.product_screen_wrapper_placeholder
                    }
                    >
                    <div className={Style.product_sections_wrapper}>
                        <div className={Style.left_section}>
                            <div className={Style.big_image_placeholder_wrapper}>
                                <Skeleton width="100%" height="350px" />
                            </div>
                            <div className={Style.small_image_placeholder_wrapper}>
                                {[1, 2, 3, 4].map((item, index) => (
                                    <div className={Style.small_image_placeholder}>
                                        <Skeleton key={index} width="65px" height="65px" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={Style.middle_section}>
                            <div className={Style.heading_placeholder_wrapper}>
                                <div className={Style.breadcrumb_placeholder_wrapper}>
                                    <Skeleton width="75px" height="15px" />
                                    <Skeleton width="75px" height="15px" />
                                    <Skeleton width="150px" height="15px" />
                                </div>
                                <div className={Style.content_placeholder_wrapper}>
                                    <Skeleton width="70%" height="30px" />
                                    <Skeleton width="55%" height="20px" />
                                    <Skeleton width="20%" height="12px" />
                                    <Skeleton width="25%" height="12px" />
                                    <Skeleton width="50%" height="27px" />
                                    <Skeleton width="30%" height="10px" />
                                    <Skeleton width="30%" height="10px" />
                                </div>
                                <div className={Style.quantity_placeholder_wrapper}>
                                    <Skeleton width="5%" height="10px" />
                                    <Skeleton width="10%" height="20px" />
                                    <Skeleton width="15%" height="20px" />
                                    <Skeleton width="10%" height="20px" />
                                </div>
                                <Skeleton width="100%" height="120px" />
                            </div>
                        </div>
                        <div className={Style.right_section}>
                            {[1].map((item, index) => (
                                <div key={index} className={Style.product_placeholder}>
                                    <div className={Style.details_placeholder_wrapper}>
                                        <Skeleton
                                            width="90%"
                                            height={isDesktop ? "20px" : "10px"}
                                        />
                                        <Skeleton
                                            width="35%"
                                            height={isDesktop ? "20px" : "10px"}
                                        />
                                        <Skeleton
                                            width="80%"
                                            height={isDesktop ? "20px" : "10px"}
                                        />
                                        <Skeleton
                                            width="50%"
                                            height={isDesktop ? "20px" : "10px"}
                                        />
                                    </div>
                                    <div className={Style.image_placeholder_wrapper}>
                                        <Skeleton width="100%" height={"160px"} />
                                        <Skeleton width="100%" height={"180px"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {[1,2,3].map((index)=>{
                return(
                    <CarauselSkeleton IndexKey={index} key={index}/>
                )
            })}
        </>

    );
};

export default ProductDetailsSkeleton;
