import { useState, useEffect, useRef } from "react";
import sortByPosition from './sortByPosition';
import { height } from "@config/dentalkart.environment";
import PropTypes from 'prop-types';
import isDeepEqual from "fast-deep-equal/react";

const ProductGalleryConfig = (props) => {
    const [state, setState] = useState({
        activeImageIndex: 0,
        galleryEntries: props.galleryData,
        hasSeenZoomInfo: localStorage.getItem('hasSeenZoomInfo'),
        zoomModal: false,
        biggerZoom: false,
        threeSixtyImageArray:[],
        bigImageInlineStyle: {
            maxHeight: (height*80)/100
        }
    });

    const ObjRef = useRef(props.galleryData);

    if(!isDeepEqual(ObjRef.current, ObjRef)) {
       ObjRef.current = ObjRef
    }

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            galleryEntries: props.galleryData
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ObjRef.current]);

    const handleImageChange = activeImageIndex => {
        setState((prevState) => ({
            ...prevState,
            activeImageIndex
        }));
    }

    const openZoomModal = () => {
        let hasSeenZoomInfo = localStorage.getItem('hasSeenZoomInfo');
        setState((prevState) => ({
            ...prevState,
            zoomModal: true ,
            hasSeenZoomInfo: hasSeenZoomInfo === null && false
        }));
    }

    const closeZoomModal = () => {
        setState((prevState) => ({
            ...prevState,
            zoomModal: false 
        }));

    }

    const disableZoomInfo = () => {
        localStorage.setItem('hasSeenZoomInfo', true);
        setState((prevState) => ({
            ...prevState,
            hasSeenZoomInfo : true
        }));
    }

    const translateImage = () => {
        const {x, y, z} = {x : 0, y: 0, z: 0};
        const { bigImageInlineStyle } = state;
        let tempBigImageInlineStyle = Object.assign({}, bigImageInlineStyle);
        tempBigImageInlineStyle.transform = `translate3d(-50%, -50%, 0) scale(2.5) translate3d(${x}px, ${y}px, ${z})`;
        tempBigImageInlineStyle.transition = 'none 0s ease 0s';
        setState((prevState) => ({
            ...prevState,
            bigImageInlineStyle: tempBigImageInlineStyle
        }));
    }

    const unZoomImage = () => {
        const { bigImageInlineStyle } = state;
        let tempBigImageInlineStyle = Object.assign({}, bigImageInlineStyle);;
        tempBigImageInlineStyle.transition = 'transform 0.3s ease-out 0s';
        tempBigImageInlineStyle.transform = 'translate3d(-50%, -50%, 0) scale(1) translate3d(0, 0, 0)';
        setState((prevState) => ({
            ...prevState,
            biggerZoom: false,
            bigImageInlineStyle: tempBigImageInlineStyle
        }));
    }

    const zoomImage = (event) => {
        const {x, y, z} = {x : event.nativeEvent.offsetX, y: event.nativeEvent.offsetY, z: 0};
        const { bigImageInlineStyle } = state;
        let tempBigImageInlineStyle = Object.assign({}, bigImageInlineStyle);
        tempBigImageInlineStyle.transformOrigin = `${x}px ${y}px ${z}`;
        tempBigImageInlineStyle.transform = 'translate3d(-50%, -50%, 0) scale(2.5) translate3d(0, 0, 0)';
        setState((prevState) => ({
            ...prevState,
            biggerZoom: true,
            bigImageInlineStyle: tempBigImageInlineStyle
        }));
    }

    const { activeImageIndex, galleryEntries } = state;
    const activeEntries = galleryEntries.filter(entry => !entry.disabled);
    let videoEntries = [];
    let imageEntries = [];
    let Image360=[];
    activeEntries.map(item => item.media_type === 'image' ? imageEntries.push(item) : (item.media_type === 'image_360') ?  Image360.push(item): videoEntries.push(item));
    imageEntries = sortByPosition(imageEntries);

    const firstImage = imageEntries.shift();
    let sortedEntries = videoEntries.concat(Image360).concat(imageEntries);
    if (firstImage) {
        sortedEntries.unshift(firstImage)
    };

    const {product} = props;

    const parentDataAndFunctions = {
        state,
        product,
        entries : sortedEntries,
        activeImageIndex,
        setState,
        handleImageChange,
        openZoomModal,
        closeZoomModal,
        disableZoomInfo,
        translateImage,
        unZoomImage,
        zoomImage  
    };

    return props.render(parentDataAndFunctions);
       
};

ProductGalleryConfig.propTypes = {
    galleryData: PropTypes.array.isRequired
};

ProductGalleryConfig.defaultProps = {
    galleryData: []
}

export default ProductGalleryConfig;
