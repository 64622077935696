const headerConfiguration = {
    default: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    home: {
        params: { topSection: true, bottomSection: true, backHeader: false },
        icons: { drawer: true, search: false, cart: true, logo: false, auth: true, currency: false, reward: false, bell: true }
    },
    navigation: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: true, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    category: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: true, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    product: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: true, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    writeReview: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: true, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    brands: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: false, auth: false, currency: false, reward: false, bell: true }
    },
    productReviews: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: true, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    cart: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: true, cart: false, logo: true, auth: true, currency: false, reward: false, bell: true }
    },
    payment: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: false }
    },
    login: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    account: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    country: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    help: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
    pdr: {
        params: { topSection: false, bottomSection: false, backHeader: true },
        icons: { drawer: false, search: false, cart: false, logo: true, auth: false, currency: false, reward: false, bell: true }
    },
     algoliaSearchScreen : {
        params: { topSection: false, bottomSection: false, backHeader: true, },
        icons: { drawer: false, algoliaSearch: true, search: false,  cart: false, false: true, auth: false, currency: false, reward: false, bell: false },
        algoliaScreen: true
    }
}
export default headerConfiguration;
