import {gql} from '@apollo/client';

const TRACK_BY_TRACK_NUMBER_QUERY_V2 = gql`
    query trackByTrackNumberV2($order_id : String!,$track_number: String!, $courier: String!){
        trackByTrackNumberV2(order_id: $order_id, track_number: $track_number, courier: $courier){
            status
            response {
                current_status
                from
                to
                time
                scan {
                    time
                    location
                    status_detail
                }
            }
            error {
                transporter
                url
                track_number
            }
        }
    }
`;

export default TRACK_BY_TRACK_NUMBER_QUERY_V2;
