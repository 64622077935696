import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Style from './topSection.module.scss';
import {DentalkartConsumer} from '@helpers/appContext';
import NotificationsIcon from '@material-ui/icons/Notifications';

library.add({faUserMd});

const TopSection = (props) => {
    const {
            globalObject, 
            drawerLeft, 
            history,
            icons: {auth, cart, currency, drawer, reward, bell}, 
            cartCount
    } = props;
    
    return(
        <DentalkartConsumer>
            {({routes}) => <div className={Style.top_section}>
                <div className={Style.top_left_section}>
                    {drawer ?
                        <div className={Style.drawer_icon} onClick={() => globalObject.toggleDrawer(drawerLeft, true)}>
                            <img className={Style.icon} src="https://s3.ap-south-1.amazonaws.com/dentalkart-media/Header/hamburger.png" alt="hamburger" />
                        </div> : null
                    }
                    <div 
                        className={Style.dentalkart_logo} 
                        onClick={()=> 
                            history.push({
                            pathname : routes.GO_TO_HOME
                        })}
                    >
                        <img className={Style.icon} src="https://images.dentalkart.com/Dentalkart_Logo_XDiq2JLyp.png?updatedAt=1686124352821" alt="Dentalkart" />
                    </div>
                </div>
                <div className={Style.top_right_section}>
                    {cart ?
                        <Link to={routes.GO_TO_CART}>
                            <div className={Style.cart_icon}>
                                <Badge className={Style.cart_count} showZero badgeContent={cartCount} color="primary">
                                    <ShoppingCartIcon />
                                </Badge>
                            </div>
                        </Link> : null
                    }
                    {bell && <div className={Style.notification_icon} id="notification">
                        <NotificationsIcon />
                    </div>}
                    {auth && !globalObject.state.isLoggedIn ?
                        <div className={Style.auth_icon} onClick={() => globalObject.showAuthenticationModal()}>
                            <FontAwesomeIcon icon="user-md" />
                        </div> : null
                    }
                    {currency ?
                        <Link to={routes.GO_TO_SELECT_COUNTRY}>
                            <div className={Style.currency_icon}>
                                <img className={Style.icon} src="https://s3.ap-south-1.amazonaws.com/dentalkart-media/Header/in.png" alt="flag" />
                            </div>
                        </Link> : null
                    }
                    {reward ?
                        <Link to={routes.GO_TO_REWARDS}>
                            <div className={Style.reward_icon}>
                                <img className={Style.icon} src="https://www.dentalkart.com/media/mw_rewardpoint/default/Final-design-rewards_4.png" alt="reward" />
                            </div>
                        </Link> : null
                    }
                </div>
            </div>}
        </DentalkartConsumer>
    );
}

TopSection.propTypes = {
    globalObject: PropTypes.object,
    drawerLeft: PropTypes.oneOf(['left', ''])
};
TopSection.defaultProps = {
    drawerLeft: 'left'
}

export default TopSection;
