import cookie from "react-cookies";
import { productData, generalEventData } from "./helper.js";
// import getAlgoliaObj from "@helpers/getAlgoliaObj";

const SearchBarPageEvents = {
    PRODUCT_CLICK_SEARCH: (data) => {
        // const algoliaObj = getAlgoliaObj(data);
        const PRODUCT_CLICK_SEARCH_DATA = {
            event: "productClick",
            ecommerce: {
                click: {
                    actionField: { list: "Search Results" }, // Optional list property.
                    products: [productData(data)],
                    adsProduct: [productData(data)],
                },
            },
            // algolia: algoliaObj,
        };
        return PRODUCT_CLICK_SEARCH_DATA;
    },
    ADD_TO_CART_SEARCH: (data) => {
        // const algoliaObj = getAlgoliaObj(data);
        const ADD_TO_CART_SEARCH_DATA = {
            event: "addToCart",
            eventCategory: "HeaderNav",
            eventAction: "SearchBar",
            eventLabel: "AddToCart",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                add: {
                    actionField: "Search Bar",
                    products: [productData(data)],
                    adsProduct: [productData(data)],
                },
            },
            // algolia: algoliaObj,
        };
        return ADD_TO_CART_SEARCH_DATA;
    },
    SEARCH_PROMOTION_BANNER: (data) => {
        const eventData = {
            category: "Search",
            action: "BannerClick",
            label: data.title,
        };
        return generalEventData(eventData);
    },
    SEARCH_SHOW_MORE: (data) => {
        const eventData = {
            category: "Search",
            action: "ShowMore",
            label: data.keyword || "NO KEYWORD",
        };
        return generalEventData(eventData);
    },

    SEARCH_PRODUCT_KEY: (data) => {
        let ga4eventData = {
            event:"event_search",
            eventCategory: "search",
            eventAction:data.search_key,
            eventLabel:data.res.hits.nbHits
        }
        const eventData = {
            event: "search_key",
            eventCategory: "search",
            eventAction:data.search_key,
            eventLabel:data.res.hits.nbHits
        }
        return {ga3:eventData, ga4:ga4eventData};
    }

};
export default SearchBarPageEvents;
