import BrowserPersistence from "@helpers/browser_persistence";

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
};

export const generalEventData = (data) => ({
    event: "Click",
    eventCategory: data.category || "Checkout",
    eventAction: data.action || data.product.name,
    eventLabel: data.label || `Quantity Updated | ${data.quantity} | ${data.updatedQuantity}`,
    dimension7: getEncryptedEmail(),
});

const getRevenue = (data, qty) => {
    if (data.revenue) return data.revenue;
    else {
        if (data.quantity || qty) {
            const revenue = qty ? qty * data?.price?.minimalPrice?.amount?.value : (data.quantity * data?.price?.minimalPrice?.amount?.value);
            return revenue;
        }
        return 1 * data?.price?.minimalPrice?.amount?.value;
    }
};

export const productData = (data, qty) => ({
    name: data.name,
    id: data.sku,
    sku: data.sku,
    p_id: data.id,
    price: data?.price?.minimalPrice?.amount?.value || data?.price?.regularPrice?.amount?.value,
    mrp:  data?.price?.regularPrice?.amount?.value,
    quantity: qty ? qty : (data.quantity ? data.quantity : 1),
    brands: data.manufacturer ? data.manufacturer : "Brand not assigned",
    imageUrl: data.image
        ? data.image.url
        : "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/Default-Product-Image-Place-Holder.png",
    productUrl: `https://www.dentalkart.com/${data.url_key}.html`,
    totalPrice: data.quantity
        ? data.quantity * data?.price?.minimalPrice?.amount?.value
        : 1 * data?.price?.minimalPrice?.amount?.value,
        revenue: getRevenue(data, qty),
    dimension7: getEncryptedEmail(),
    dimension4: "ProductDetailPage",
    dimension14: data.name,
});

export const ga4Items = (data,list_name,index, qty) => ({
    item_id:data.sku,
    item_name: data.name,
    currency: data?.price?.minimalPrice?.amount?.currency || data?.price?.regularPrice?.amount?.currency,
    index: index || 0,
    item_brand: data.manufacturer ? data.manufacturer : "Brand not assigned",
    item_list_id: list_name,
    item_list_name: list_name,
    price: data?.price?.minimalPrice?.amount?.value || data?.price?.regularPrice?.amount?.value,
    quantity: qty ? qty : (data.quantity ? data.quantity : 1),
})


export const wishlistEvent = (event, page, data) => {
    return {
        event: event,
        eventCategory: "Wishlist",
        eventAction: page,
        eventLabel: data.sku,
        wishlistProduct: [productData(data)],
        eventValue: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
        dimension6: data.name,
        dimension7: getEncryptedEmail(),
    };
};
