import { gql } from "@apollo/client";

const GET_VALIDATION_RULES = gql`
  query GetValidationRules($countryId: String!) {
    getValidationRules(country_id: $countryId) {
      alternate_telephone_required
      emailsignup
      mobilesignup
      postcode_format
      postcode_required
      service_availability_enabled
      state_dropdown_required
      state_required
      tax_format
      tax_label
      tax_required
      telephone_code
      telephone_format
      whatsappsignup
    }
  }
`;

export default GET_VALIDATION_RULES;
