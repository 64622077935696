import { gql } from '@apollo/client';

const CREATE_NEW_USER = gql`
    mutation checkOTP($customerEmail: String!, $firstname: String!, $lastname: String!, $password: String!){
        createCustomer(input: {
        	firstname: $firstname,
        	lastname: $lastname,
        	email: $customerEmail,
        	password: $password
        }){
            customer {
                firstname
                lastname
                email
                customer_unique_id


            }
        }
    }
`;

export default CREATE_NEW_USER;
