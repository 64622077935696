import { gql } from '@apollo/client';
// eslint-disable-next-line no-unused-vars


const GET_CATEGORY_FILTERS =
     gql`
        query categoryFilters($category_id: Int!){
            getCategoryFilters(category_id: $category_id){
                category_id
                filters{
                   type
                   display
                   label
                   code
                   options{
                       value
                       label
                   }
                }
	        }
        }
    `;


export default GET_CATEGORY_FILTERS;
