import BrowserPersistence from "@helpers/browser_persistence";
import {generalEventData} from './helper.js';

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const headerData = (label,action) =>({
    event: "Click",
    eventCategory: "HeaderNav",
    eventAction: action,
    eventLabel: label,
    dimension7: getEncryptedEmail()
});

const headerNavData = (label) => ({
    event: "Click",
    eventCategory: "HeaderNav",
    eventAction: "Click",
    eventLabel: label,
    dimension7: getEncryptedEmail()
});


const HeaderEvents = {
    HELP_CENTER: () => {
        return headerData("HELP_CENTER","More")
    },
    REWARD_ZONE: () => {
        return headerData("RewardZone","More")
    },
    ANDROID_APP: () => {
        return headerData("AndroidApp","More")
    },
    IOS_APP: () => {
        return headerData("IosApp","More");
    },
    CAREERS: () => {
        return headerData("Careers","More");
    },
    HEADER_BLOGS: () => {
        return headerData("Blogs","More");
    },
    MY_PROFILE: () => {
        return headerData("My Profile","LoggedInPerson");
    },
    MY_REWARDS: () => {
        return headerData("My Rewards","LoggedInPerson");
    },
    MY_ORDERS: () => {
        return headerData("My Order","LoggedInPerson");
    },
    MY_WISHLIST: () => {
        return headerData("My WishList","LoggedInPerson");
    },
    MY_TICKETS: () => {
        return headerData("My Tickets","LoggedInPerson");
    },
    LOGIN_SIGNUP: () => {
        storage.removeItem("customer_email");
        return headerNavData("Login&Signup");
    },
    CART: () => {
        return headerNavData("Cart");
    },
    NOTIFICATIONS: () => {
        return headerNavData("Notifications");
    },

    SHOP_BY_CATEGORIES: (data) => {
        return headerData(data.name,"ShopByCategory");
    },
    SHOP_BY_SUB_CATEGORIES: (data) => {
        return headerData(`${data.parent_name} | ${data.name}`,"ShopBySubCategory")
    },
    SEARCH: () => {
        const eventData = {
            category:"Search",
            action:"Click",
            label:window.location.pathname
        }
        return generalEventData(eventData);
    },
}

export default HeaderEvents;
