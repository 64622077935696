import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Style from "./desktopHeader.module.scss";
import { primaryColor } from "@config/dentalkart.environment.js";
import { Link } from "react-router-dom";
import { DentalkartConsumer } from "@helpers/appContext";
import Icon from "@helpers/icons";
import fireEvents from "@helpers/event_data_layer";

const styles = (theme) => ({
    itemTextPadding: {
        padding: "10px 10px",
        margin: 0,
    },
    iconMargin: {
        marginRight: 0,
        color: primaryColor,
        minWidth: "25px",
    },
    size: {
        fontSize: "12px",
        fontWeight: "500",
    },
    listTextWrapper: {
        padding: "0 8px",
    },
});

const MenuList = (props) => {
    const menuItem = (item) => {
        const { classes } = props;
        return (
            <ListItem
                button
                divider={true}
                onClick={() => fireEvents("GTM", `${item.event}`)}
                classes={{ gutters: classes.itemTextPadding }}
            >
                {item.leftIcon.title ? (
                    <ListItemIcon classes={{ root: classes.iconMargin }}>
                        <Icon
                            type={item.leftIcon.type}
                            title={item.leftIcon.title}
                            styling={`${Style.icon} ${item.leftIcon.type === "material" ? Style.material_icon : ""}`}
                        />
                    </ListItemIcon>
                ) : null}
                <ListItemText primary={item.title} classes={{ root: classes.listTextWrapper, primary: classes.size }} />
                {item.rightIcon.title ? (
                    <ListItemSecondaryAction className={Style.secondary_icon_wrapper}>
                        <Icon
                            type={item.rightIcon.type}
                            title={item.rightIcon.title}
                            styling={`${Style.icon} ${item.rightIcon.type === "material" ? Style.material_icon : ""}`}
                        />
                    </ListItemSecondaryAction>
                ) : null}
            </ListItem>
        );
    };

    const { data } = props;
    return (
        <DentalkartConsumer>
            {({ routes }) => (
                <div className={Style.list_item_wrapper}>
                    <List component="nav">
                        {data.map((item, index) =>
                            item.pathType === "relative" ? (
                                <Link key={index} to={routes[item.path] || '#'}>
                                    {menuItem(item)}
                                </Link>
                            ) : (
                                <a key={index} href={routes[item.path] || "#"} target="_blank" rel="noopener noreferrer">
                                    {menuItem(item)}
                                </a>
                            ),
                        )}
                    </List>
                </div>
            )}
        </DentalkartConsumer>
    );
};

MenuList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuList);
