import gql from "graphql-tag";

const GET_PRODUCT_DATA = gql`
  query ProductData($id: [Int], $sku: [String]) {
    productData(id: $id, sku: $sku) {
      id
      image_url
      name
      sku
      special_price

      url_key
      thumbnail_url
      price {
        regularPrice {
          amount {
            value
          }
        }
        minimalPrice {
          amount {
            value
          }
        }
      }
      short_description
      type_id
      manufacturer
      average_rating
      rating_count
      is_in_stock
    }
  }
`;

export default GET_PRODUCT_DATA;
