import { gql } from "@apollo/client";

const FAQ_CATEGORY_QUESTIONS_AND_ANSWERS = gql`
  query ($id: Int!) {
    faqcategoryitem(id: $id) {
      id
      question
      answer
    }
  }
`;

export default FAQ_CATEGORY_QUESTIONS_AND_ANSWERS;
