import {gql} from '@apollo/client';
import {ProductListingFragment, cartPriceFragment, cartShippingAddressFragment} from '../fragments';

const UPDATE_CART_ITEM = gql`
	mutation updateCartItems($cart_id: String!, $cart_item_id: Int!, $quantity:Float!){
        updateCartItems(
            input: {
                cart_id : $cart_id,
                cart_items: [
                    {
                        cart_item_id: $cart_item_id
                        quantity: $quantity
                    }
                ]
            }
        )
        {
            cart{
				global_errors
				total_quantity
				applied_coupons {
					code
				}
				...ProductListingFragment
				prices {
					...cartPriceFragment
				}
				shipping_addresses{
					...cartShippingAddressFragment
				}
			}
        }
    }
    ${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
`;

export default UPDATE_CART_ITEM;
