import { gql } from '@apollo/client';

const GET_CUSTOMER_COUPONS = gql`
    query {
        CustomerCoupons{
            coupon_code
            description
            expiry_date
       }
    }
`;

export default GET_CUSTOMER_COUPONS;
