import React from 'react';
import Responsive from 'react-responsive';
import {
    desktopHeaderConfiguration,
    mobileHeaderConfiguration,
    moreListConfiguration,
    userListConfiguration
} from './header';
import {
    footerHighlights as footerHighlightsConf,
    copyrights as copyrightsConf,
    fixedFooter as fixedFooterConf,
    footerConf
} from './footer';
import accountSidebarConfiguration from './accountSidebar';

import drawerList from './drawer';








//Divice Congiguration
export const Desktop = props => <Responsive {...props} minWidth={950} />;
export const Mobile = props => <Responsive {...props} maxWidth={949} />;

export const BASE_URL = `https://${window.location.host}/`;

// Theme colors
export const primaryColor='#151a20';
export const secondaryColor='#f3943d';
export const tertiaryColor='#27313b';

//Device Deimensions
export const width = window.innerWidth;
export const height = window.innerHeight;
export const isDesktop = window.innerWidth >= 950;
export const MediaBaseUrl = "https://images.dentalkart.com/media/catalog/product";
export const Brand_Logo_Media_Base_Url = "https://images.dentalkart.com/media/";
export const Category_Sale_Base_Url = "https://images.dentalkart.com/media/catalog/category/";
export const productImageFallback = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/Default-Product-Image-Place-Holder.png";
export const brandImageFallback = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/Default-Product-Image-Place-Holder.png";
export const desktopSliderImageFallback = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/sliderfbimage.jpg";
export const desktopBrandsImageFallback = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/brandfbimage.jpg";
export const desktopThreeBannerImageFallback = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/threebannerfbimage.jpg";
export const productImageSkeletal = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/imageloading.gif";
export const mobileFallbackImage = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/mobilefbImage.jpg";
export const orderSuccessImage = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/order_succcess.gif";
export const err404Image = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Error/err404.png";
export const somethingWentWrongImage = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/sww.png";
export const rewardCoinImage = "https://dentalkart-application-media.s3.ap-south-1.amazonaws.com/website-icons/coin.png";
export const accountUserImage = "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/user.png";
export const whatsAppAuthLink = "https://dentalkart.authlink.me";

//header footer drawer mapping data
export const FooterConfiguration = footerConf;
export const HeaderConfiguration = isDesktop ? desktopHeaderConfiguration : mobileHeaderConfiguration;
export const MoreListConfiguration = moreListConfiguration;
export const UserListConfiguration = userListConfiguration;
export const AccountSidebarConfiguration = accountSidebarConfiguration;
export const fixedFooter = fixedFooterConf(isDesktop);
export const footerHighlights = footerHighlightsConf;
export const copyrights = copyrightsConf;
export const DrawerList = drawerList;
const mode = process.env.NODE_ENV;
// Tag Manager Configuration
const gtmId = 'GTM-WWXZCZ8';
const dataLayerName = 'dataLayer';
const auth = '--3Ejzj-oKuemWvz5NwHUQ';
const preview = 'env-3';
export const tagManagerArgs = mode !== 'production' ? {gtmId, dataLayerName, auth, preview} : {gtmId, dataLayerName};
//
// const gtmId = 'GTM-TDCHNQ';
// const dataLayerName = 'dataLayer';
// export const tagManagerArgs = {gtmId, dataLayerName};

//snackBarConfiguration
export const toggleSnackOptions = isDesktop ? {"anchorOrigin"  : { vertical: 'bottom', horizontal: 'right'}} : {"anchorOrigin": { vertical: 'top', horizontal: 'center',}}

export const  whatsapp_icon =
  "https://sale-banners-dtkrt-2020.s3.ap-south-1.amazonaws.com/icons8-whatsapp+(1).svg";
