import React, { Fragment } from 'react';
import { Mobile, Desktop, MediaBaseUrl, productImageFallback } from '@config/dentalkart.environment';
import Style from './productGalleryStyle.module.scss';

export const BigEntries = ({ parentDataAndFunctions}) => {
    const {entries, activeImageIndex, product} = parentDataAndFunctions;
    const activeImage = entries[activeImageIndex] && entries[activeImageIndex].file ?
        entries[activeImageIndex].file.charAt(0) !== '/' ?
        '/'+entries[activeImageIndex].file : entries[activeImageIndex].file : null;
    const video = (entries.length > 0 && entries[activeImageIndex]) ? entries[activeImageIndex].media_type === 'external-video' : false;
    return(
        <div className={Style.big_images_wrapper} onClick={()=> parentDataAndFunctions.openZoomModal()}>
                    <Mobile>
                        <div className={Style.image_block}>
                         <img className={`${Style.image} ${Style.image_opacity}`} src={MediaBaseUrl+activeImage} alt={'no-label'} onError={(e)=>{e.target.onerror = null; e.target.src=productImageFallback}} />
                        </div>
                    </Mobile>
                    <Desktop>
                        <div className={Style.image_block}>
                            {video ?
                                null :
                                <img className={`${Style.image} ${Style.image_opacity}`} src={MediaBaseUrl+activeImage} alt={`${product.name}`} onError={(e)=>{e.target.onerror = null; e.target.src=productImageFallback}} />
                            }
                        </div>
                    </Desktop>
        </div>
    );
}


export const SmallEntries = ({ parentDataAndFunctions }) => {
    const {entries,activeImageIndex} = parentDataAndFunctions;
    return(
        <div className={Style.small_image_wrapper}>
            {entries.map((item, index) => {
                const image = (item && item.file) ? item.file.charAt(0) !== '/' ? '/'+item.file : item.file : null;
                // const video = (item && item.media_type === 'external-video') ? true : false;
                return(
                    <Fragment key={index}>
                        {(index < 5) ?
                            <div
                                className={`${Style.small_image_block} ${index === activeImageIndex ? Style.gallery_active_image : ''} ${index === 4 && entries.length > 5 ? Style.last_small_image : ''}`}
                                onClick={index === 4 && entries.length > 5 ?  () => parentDataAndFunctions.openZoomModal() : () => parentDataAndFunctions.setState((prevstate) => ({...prevstate, activeImageIndex: index}))}
                                onMouseOver={() => parentDataAndFunctions.setState((prevState) =>({...prevState, activeImageIndex: index}))}
                                data-length={index === 4 ? `+${entries.length-5}` : null}
                            >
                                <img className={Style.small_image} src={MediaBaseUrl+image} alt={item.label} onError={(e)=>{e.target.onerror = null; e.target.src=productImageFallback}} />
                            </div>
                            : null
                        }
                    </Fragment>
            )})}
        </div>
    )
};
