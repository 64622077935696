import { gql } from '@apollo/client';

const ADD_LOWER_PRICE_DETAILS = gql`
mutation AddLowerPriceDetails($url: String, $price: Float, $shipping_cost:Float, $date_of_price:String, $store_name:String, $city:String , $state:String,$product_id:String,$product_sku:String) {
    addLowerPriceDetails(input: {url: $url, price: $price, shipping_cost:$shipping_cost, date_of_price:$date_of_price, store_name:$store_name, city:$city ,state:$state,product_id:$product_id,product_sku:$product_sku}) {
         url
         price
         shipping_cost
         date_of_price
         store_name
         city
         product_sku
         product_id
         state
        }
    }
`;

export default ADD_LOWER_PRICE_DETAILS;