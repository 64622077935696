import BrowserPersistence from "@helpers/browser_persistence";
import {generalEventData} from './helper.js';

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const NotImportantEvents = {
    EDIT_PERSONAL_INFO: () => {
        const eventData = {
            category:"AccountSettings",
            action: "PersonalInformation",
            label:"Edit"
        }
        return generalEventData(eventData);
    },
    SAVE_PERSONAL_INFO: () => {
        const eventData = {
            category:"AccountSettings",
            action: "PersonalInformation",
            label:"Save"
        }
        return generalEventData(eventData);
    },
    CANCEL_PERSONAL_INFO: () => {
        const eventData = {
            category:"AccountSettings",
            action: "PersonalInformation",
            label:"Cancel"
        }
        return generalEventData(eventData);
    },
    CHANGE_PASSWORD: () => {
        const eventData = {
            category:"AccountSettings",
            action: "ChangePassword",
            label:"Step1"
        }
        return generalEventData(eventData);
    },
    SUBMIT_CHANGE_PASSWORD: () => {
        const eventData = {
            category:"AccountSettings",
            action: "ChangePassword",
            label:"Step2"
        }
        return generalEventData(eventData);
    },

    EDIT_GST: () => {
        const eventData = {
            category:"AccountSettings",
            action: "GSTNumber",
            label:"Edit"
        }
        return generalEventData(eventData);
    },
    SAVE_GST: () => {
        const eventData = {
            category:"AccountSettings",
            action: "GSTNumber",
            label:"Save"
        }
        return generalEventData(eventData);
    },
    CANCEL_GST: () => {
        const eventData = {
            category:"AccountSettings",
            action: "GSTNumber",
            label:"Cancel"
        }
        return generalEventData(eventData);
    },
    INITIATE_GBQ: (data) => {
        const eventData = {
            category:"GetBulkQuoteNow",
            action: data.name,
            label:"Initiate"
        }
        return generalEventData(eventData);
    },
    SUBMIT_GBQ: (data) => {
        const eventData = {
            category:"GetBulkQuoteNow",
            action: data.name,
            label:data.pin
        }
        return generalEventData(eventData);
        //     eventValue: data.qty,
        //     metric2: data.expectedPrice,
    },

    CANCEL_GBQ: (data) => {
        const eventData = {
            category:"GetBulkQuoteNow",
            action: data.name,
            label:"Cancel"
        }
        return generalEventData(eventData);
    },

    SUBMIT_ERROR_GBQ: (data) => {
        const eventData = {
            category:"GetBulkQuoteNow",
            action: data.name,
            label:"Error"
        }
        return generalEventData(eventData);
        // dimension8: data.errorDetail,
    },
    ERROR_INFO: (data) => {
        const eventData = {
            event:"httperror",
            category:`${data.http_code}: ${data.http_message}`,
            action:data.url,
            label:data.referrer
        }
        return generalEventData(eventData);
    },

    CREATE_NEW_TICKET: () => {
        const eventData = {
            category:"MyTickets",
            action:"CreateNewTicket",
            label:getEncryptedEmail()
        }
        return generalEventData(eventData);
    },

    SUBMIT_NEW_TICKET: (data) => {
        const eventData = {
            category:"MyTickets",
            action: `Submit | ${data.message}`,
            label: getEncryptedEmail()
        }
        return generalEventData(eventData);
    },
    CREATE_NEW_TICKET_ERROR: (data) => {
        const eventData = {
            category:"MyTickets",
            action:"Error",
            label:data.message
        }
        return generalEventData(eventData);
    },
    CLOSE_TICKET_FORM: () => {
        const eventData = {
            category:"MyTickets",
            action:"Cancel",
            label:getEncryptedEmail()
        }
        return generalEventData(eventData);
    },
}
export default NotImportantEvents;
