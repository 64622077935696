import React from "react";
import { Route, Redirect } from "react-router-dom";
import isRouteRedirect from "@helpers/routeRedirect";

const routeComponent = (isLoggedIn, route, args, newOrderInit) => {
  return (
    <Route
      exact={route.exact}
      path={route.path}
      key={`key-${route.name}`}
      render={(props) => {
        const isRedirect = isRouteRedirect({
          screenType: route.type,
          isLoggedIn,
          newOrderInit,
        });
        if (isRedirect && route.type !== "payment") {
          return (
            <Redirect
              to={{
                pathname: route.redirectPath,
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <route.component
            {...route}
            {...props}
            {...args}
            isRedirect={isRedirect}
          />
        );
      }}
    />
  );
};

export default routeComponent;
