
import {gql} from '@apollo/client';

const GET_NON_RETURNABLE = gql `
query getNonReturnable( $rowsPerPage:Int , $pageNumber:Int ){
    getNonReturnable(pagination:{rowsPerPage:$rowsPerPage , pageNumber:$pageNumber}) {
      result {
        product_id
        product_name
        sku
        created_at
      }
      count
    }
  }
`;

export default GET_NON_RETURNABLE;
