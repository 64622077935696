const footerConf = {
    default: {fixedSection: true, copyrightSection: true, checkoutSection: false, contentSection: false},
    home: {fixedSection: true, copyrightSection: true, checkoutSection: false, contentSection: true},
    account: {fixedSection: true, copyrightSection: true, checkoutSection: false, contentSection: false},
    cart: {fixedSection: false, copyrightSection: false, checkoutSection: true, contentSection: false},
    payment: {fixedSection: false, copyrightSection: false, checkoutSection: true, contentSection: false},
    brands: {fixedSection: true, copyrightSection: false, checkoutSection: false, contentSection: false}
}

export {footerConf};
