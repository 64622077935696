import { gql } from '@apollo/client';

const ADD_MULTIPLE_PRODUCTS_TO_CART = gql`
    mutation addSimpleProductsToCart($cart_id : String!, $cart_items:[SimpleProductCartItemInput]!){
        addSimpleProductsToCart(
            input: {
              cart_id: $cart_id
              cart_items: $cart_items
            }
          ) {
            cart {
              items {
                id
                product {
                  name
                  sku
                }
                quantity
              }
            }
       }
}`;

export default ADD_MULTIPLE_PRODUCTS_TO_CART;
