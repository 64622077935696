import {gql} from '@apollo/client';

const GET_WEIGHT_SLABS_FOR_COUNTRIES = gql`
	query GetWeightSlabsForCountries($country_id : String!){
	    WeightSlabsForCountries(country_id : $country_id){
			price
	        weight_range
	        weight
	        currency
    	}
	}
`;

export default GET_WEIGHT_SLABS_FOR_COUNTRIES;
