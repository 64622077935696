import { gql } from '@apollo/client';

const SUBMIT_REVIEWS = gql`
    mutation postReviews(
        $hashKey: String!,
        $id: Int!,
        $rating: Int,
        $comment: String,
        $participation: Boolean!
    ){
        postReviews(
            hashKey: $hashKey,
            reasonIdRating: {
                id: $id,
                rating: $rating,
                comment: $comment,
            }
            opt_as_participant: $participation
        ){
            message
        }
    }
`;

export default SUBMIT_REVIEWS;
