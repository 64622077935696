import { isDesktop } from "@config/dentalkart.environment";

import {
    HomePageScreen,
    BrandPageScreen,
    CountryScreen,
    cartScreen,
    AccountScreen,
    WriteReview,
    NavigationScreen,
    WeightSlabInformationTable,
    Payment,
    GuestCheckout,
    ProductReviews,
    DealerForm,
    ReturnPolicy,
    SellOnDentalkart,
    TermsConditions,
    PrivacyPolicy,
    AboutUs,
    SearchScreen,
    SaleScreen,
    GenericFormScreen,
    MembershipSreen,
    Magazine,
    FlipBook,
    Investors,
    InvestorsScreen
} from "@screens";

import {
    profileScreen,
    AddressScreen,
    couponScreen,
    OrdersList,
    Rewards,
    OrderSuccess,
    OrderReturnScreen,
    Authentication,
    TrackingScreen,
    MobileShippmentTransitDetail,
    MobileAccount,
    KnowRewards,
    MyMembership,
    Wishlist,
    WishlistProduct,
    PastOrderProductDisplay
} from "@screens/account";

import { FAQCategories, FAQQuestions, FAQAnswer, HelpCenter } from "@screens/helpCenter";

import ErrorScreen from "@components/errorScreen";
import ProductSearch from "../screens/productSearch";


const memberShipPage = {
    path: "/membership",
    staticPath: "/membership",
    name: "MemberSHip",
    component: MembershipSreen,
    exact: true,
    type: "public",
};

const magazine = {
    path: "/magazine",
    staticPath: "/magazine",
    name: "magazine",
    component: Magazine,
    exact: true,
    type: "public",
}

const flipbook = {
    path: "/flipbook/:file",
    staticPath: "/flipbook",
    name: "flipbook",
    component: FlipBook,
    exact: true,
    type: "public",
}

const homePage = {
    path: "/",
    staticPath: "/",
    name: "homePage",
    component: HomePageScreen,
    exact: true,
    type: "public",
};

const returnOrder = {
    path:"/account/return-order/:order_id",
    staticPath:"/account/return-order",
    name:"returnOrder",
    exact:true,
    component:OrderReturnScreen,
    sidebar:false,
    redirectPath:"/checkout/login",
    type:"protected"
}

const orderDetails = {
    path: "/account/order-details/:order_id",
    staticPath: "/account/order-details",
    name: "orderDetailNew",
    exact: true,
    component: TrackingScreen,
    sidebar: false,
    redirectPath: "/checkout/login",
    type: "public",
};

const orderDetailsV2 = {
    path: "/account/order-details-v2/:order_id",
    staticPath: "/account/order-details-v2",
    name: "orderDetailNewV2",
    exact: true,
    component: TrackingScreen,
    sidebar: false,
    redirectPath: "/checkout/login",
    type: "public",
};



const orderSuccess = {
    path: "/order/success&:id",
    staticPath: "/order/success",
    name: "orderSuccess",
    component: OrderSuccess,
    redirectPath: "/",
    type: "order_success",
    exact: true,
};

const brandPage = {
    path: "/brands",
    staticPath: "/brands",
    name: "allBrands",
    exact: true,
    component: BrandPageScreen,
    redirectPath: "/",
    type: "public",
};

const productSearch = {
    path: "/prod-search",
    staticPath: "/prod-search",
    name: "productSearch",
    exact: true,
    component: ProductSearch,
    redirectPath: "/",
    type: "public",
}

const countryPage = {
    path: "/select-country",
    staticPath: "/select-country",
    name: "country",
    component: CountryScreen,
};

const payment = {
    path: "/payment",
    staticPath: "/payment",
    name: "payment",
    component: Payment,
    redirectPath: "/cart",
    type: "payment",
    exact: true,
};

const cart = {
    path: "/cart",
    staticPath: "/cart",
    name: "cart",
    component: cartScreen,
    exact: true,
};

const writeReview = {
    path: "/:title_slug/write-review/:item_id/:type_id",
    staticPath: "/write-review",
    name: "writeReview",
    exact: true,
    component: WriteReview,
    redirectPath: "/",
    type: "protected",
};

const profile = {
    path: "/account/",
    staticPath: "/account/",
    name: "profile",
    exact: true,
    component: isDesktop ? profileScreen : MobileAccount,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};

const profileDummy = {
    path: "/account/profile",
    staticPath: "/account/profile",
    name: "profile",
    exact: true,
    component: profileScreen,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};

const customerCoupons = {
    path: "/account/coupon-list",
    staticPath: "/account/coupon-list",
    name: "customerCoupons",
    exact: true,
    component: couponScreen,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};
const search = {
  path: "/search-products",
  staticPath: "/search-products",
  name: "search",
  component: SearchScreen,
  exact: true
};

const ordersList = {
    path: "/account/orders",
    staticPath: "/account/orders",
    name: "ordersList",
    exact: true,
    component: OrdersList,
    sidebar: false,
    redirectPath: "/checkout/login",
    type: "protected",
};

const wishlist = {
    path: "/account/wishlist",
    staticPath: "/account/wishlist",
    name: "wishlist",
    exact: true,
    component: Wishlist,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};

const wishlistProduct = {
    path: "/account/wishlistProduct/:wishlist_id",
    staticPath: "/account/wishlistProduct",
    name: "wishlistProduct",
    exact: true,
    component: WishlistProduct,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};

const rewards = {
    path: "/account/rewards",
    staticPath: "/account/rewards",
    name: "rewards",
    exact: true,
    component: Rewards,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};
const buyFromPastOrders = {
    path: "/buy-again",
    staticPath: "/buy-again",
    name: "buyFromPastOrders",
    exact: true,
    component: PastOrderProductDisplay,
    sidebar: false,
    redirectPath: '/checkout/login',
    type: 'protected'};

const shiippingTable = {
    path: "/shipping_charge_information",
    staticPath: "/shipping_charge_information",
    name: "WeightSlabInformationTable",
    component: WeightSlabInformationTable,
    redirectPath: "/",
    type: "public",
    exact: true,
};

const myMembership = {
    path: "/account/mymembership",
    staticPath: "/account/mymembership",
    name: "mymembership",
    exact: true,
    component: MyMembership,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
  };


const dealerForm = {
    path: "/partner-with-us",
    staticPath: "/partner-with-us",
    name: "dealer-Form",
    exact: true,
    component: DealerForm,
    type: "public",
};

const sellOnDentalkart = {
    path: "/list-your-product",
    staticPath: "/list-your-product",
    name: "sell-on-dentalkart",
    exact: true,
    component: SellOnDentalkart,
    type: "public",
};

const aboutUs = {
    path: "/about-us",
    staticPath: "/about-us",
    name: "about-us",
    exact: true,
    component: AboutUs,
    type: "public",
};

const investors = {
    path: "/investors",
    staticPath: "/investors",
    name: "investors",
    exact: true,
    component: Investors,
    type: "public",
};

const investorsScreen = {
    path: "/investors-new",
    staticPath: "/investors-new",
    name: "investorsScreen",
    exact: true,
    component: InvestorsScreen,
    type: "public",
};

const sale = {
    path: "/sale/:salename/:templateid",
    staticPath: "/sale",
    name: "sale",
    exact: true,
    component: SaleScreen,
    type: "public",
};

const genericForm = {
    path: "/form/:id",
    staticPath: "/form",
    name: "form",
    exact: true,
    component: GenericFormScreen,
    type: "public",
};

const authentication = {
    path: "/checkout/login",
    staticPath: "/checkout/login",
    name: "login",
    exact: true,
    component: Authentication,
    sidebar: false,
    redirectPath: "/cart",
    type: "auth",
};

const guestCheckout = {
    path: "/guest-checkout",
    staticPath: "/guest-checkout",
    name: "guest-checkout",
    exact: true,
    component: GuestCheckout,
    sidebar: false,
    redirectPath: "/cart",
    type: "auth",
};

const mobileTransitDetail = {
    path: "/account/transit-details",
    staticPath: "/account/transit-details",
    name: "transitDetails",
    exact: true,
    component: MobileShippmentTransitDetail,
    sidebar: false,
    redirectPath: "/checkout/login",
    type: "protected",
};

const account404 = {
    name: "account404",
    path: "/account/:ambiguous",
    component: ErrorScreen,
};

const addresses = {
    path: "/account/addresses",
    staticPath: "/account/addresses",
    name: "addresses",
    exact: true,
    component: AddressScreen,
    sidebar: true,
    redirectPath: "/checkout/login",
    type: "protected",
};
const knowRewards = {
    path: "/account/know-rewards",
    staticPath: "/account/know-rewards",
    name: "knowRewards",
    exact: true,
    component: KnowRewards,
    sidebar: false,
    type: "public",
};
const navigation = {
    path: "/categories",
    staticPath: "/categories",
    name: "navigation",
    component: NavigationScreen,
    exact: true,
};

const faqCategories = {
    path: "/help-center/",
    staticPath: "/help-center",
    name: "helpCenter",
    component: FAQCategories,
    exact: true,
    sidebar: false,
};

const faqQuestions = {
    path: "/help-center/questions/:category_id/:category_name",
    staticPath: "/help-center/questions",
    name: "faqQuestions",
    component: FAQQuestions,
    sidebar: true,
    exact: true,
};
const faqAnswer = {
    path: "/help-center/answer/:category_id/:category_name/:question_id",
    staticPath: "/help-center/answer",
    name: "faqAnswer",
    component: FAQAnswer,
    exact: true,
    sidebar: true,
};

const productReviews = {
    path: "/:title_slug/product-reviews/:item_id",
    staticPath: "/product-reviews",
    name: "productReviews",
    exact: true,
    component: ProductReviews,
    redirectPath: "/",
    type: "public",
};
const faq404 = {
    name: "faq404",
    path: "/help-center/:ambiguous",
    component: ErrorScreen,
};
const account = {
    path: "/account",
    staticPath: "/account",
    name: "account",
    component: AccountScreen,
    screenRoutes: [
        profile,
        profileDummy,
        addresses,
        customerCoupons,
        ordersList,
        rewards,
        myMembership,
        wishlist,
        wishlistProduct,
        knowRewards,
        orderDetails,
        orderDetailsV2,
        returnOrder,
        mobileTransitDetail,
        account404,
    ],
};

const returnpolicy = {
    path: "/return-policy",
    staticPath: "/return-policy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
    type: "public",
};
const termsConditions = {
    path: "/terms-and-conditions",
    staticPath: "/terms-and-conditions",
    name: "TermsConditions",
    component: TermsConditions,
    type: "public",
};
const privacypolicy = {
    path: "/privacy-policy",
    staticPath: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
};

const helpCenter = {
    path: "/help-center",
    staticPath: "/help-center",
    name: "helpCenter",
    component: HelpCenter,
    screenRoutes: [faqCategories, faqQuestions, faqAnswer, faq404],
};

export const screens = [
    account,
    homePage,
    brandPage,
    countryPage,
    cart,
    shiippingTable,
    writeReview,
    authentication,
    payment,
    guestCheckout,
    orderSuccess,
    navigation,
	search,
    productReviews,
    helpCenter,
    dealerForm,
    sellOnDentalkart,
    aboutUs,
    returnpolicy,
    termsConditions,
    privacypolicy,
    buyFromPastOrders,
    sale,
    genericForm,
    memberShipPage,
    magazine,
    flipbook,
    productSearch,
    investors,
    investorsScreen
];

export const GO_TO_HOME = homePage.staticPath;
export const GO_TO_WRITE_REVIEW = writeReview.staticPath;
export const GO_TO_CART = cart.staticPath;
export const GO_TO_PROFILE = profileDummy.staticPath;
export const GO_TO_ORDERS_LIST = ordersList.staticPath;
export const GO_TO_CUSTOMER_COUPON_LIST = customerCoupons.staticPath;
export const GO_TO_REWARDS = rewards.staticPath;
export const GO_TO_SHIPPING_CHARGE_DETAILS = shiippingTable.staticPath;
export const GO_TO_ADDRESSES = addresses.staticPath;
export const GO_TO_WISHLIST = wishlist.staticPath;
export const GO_TO_WISHLIST_PRODUCTS = wishlistProduct.staticPath;
export const GO_TO_PAYMENT = payment.staticPath;
export const GO_TO_LOGIN = authentication.staticPath;
export const GO_TO_ORDER_SUCCESS = orderSuccess.staticPath;
export const GO_TO_ORDER_DETAIL_NEW = orderDetailsV2.staticPath;
export const GO_TO_ORDER_DETAIL_NEW_v2 = orderDetails.staticPath;
export const GO_TO_ORDER_RETURN = returnOrder.staticPath;
export const GO_TO_TRANSITION_DETAILS = mobileTransitDetail.staticPath;
export const GO_TO_GUEST_CHECKOUT = guestCheckout.staticPath;
export const GO_TO_KNOW_REWARDS = knowRewards.staticPath;
export const GO_TO_FEATURED_BRANDS = brandPage.staticPath;
export const GO_TO_NAVIGATION = navigation.staticPath;
export const GO_TO_SELECT_COUNTRY = countryPage.staticPath;
export const GO_TO_ACCOUNT = account.staticPath;
export const GO_TO_HELP_CENTER = helpCenter.staticPath;
export const GO_TO_FAQ_QUESTIONS = faqQuestions.staticPath;
export const GO_TO_ABOUT_US = aboutUs.staticPath;
export const GO_TO_FAQ_ANSWER = faqAnswer.staticPath;
export const GO_TO_PRODUCT_REVIEWS = productReviews.staticPath;
export const GO_TO_DEALER_FORM = dealerForm.staticPath;
export const GO_TO_SELL_ON_DENTALKART = sellOnDentalkart.staticPath;
export const GO_TO_RETURN_POLICY = returnpolicy.staticPath;
export const GO_TO_TERMS_OF_USE = termsConditions.staticPath;
export const GO_TO_PRIVACY = privacypolicy.staticPath;
export const GO_TO_SEARCH = search.staticPath;
export const GO_TO_GENERIC_FORM = genericForm.staticPath;
export const GO_TO_BUY_FROM_PAST_ORDERS = buyFromPastOrders.staticPath;
export const GO_TO_MEMBERSHIPSCREEN = memberShipPage.staticPath;
export const GO_TO_MY_MEMBERSHIP = myMembership.staticPath;
export const GO_TO_MAGAZINE = magazine.staticPath;
export const GO_TO_FLIPBOOK = flipbook.staticPath;
export const GO_TO_PRODUCT_SEARCH = productSearch.staticPath;
export const GO_TO_INVESTORS = investors.staticPath;
export const GO_TO_INVESTORS_NEW = investorsScreen.staticPath;

export const GO_TO_FAQ_ORDERS = "/help-center/questions/3/Orders";
export const GO_TO_FAQ_REFUNDS = "/help-center/questions/4/Refunds";
export const GO_TO_FAQ_PAYMENTS = "/help-center/questions/6/Payment";
export const GO_TO_FAQ_REWARDS = "/help-center/questions/12/Rewards";
// export const GO_TO_CAREERS = "https://dentalkart.zohorecruit.com/jobs/Careers"
export const GO_TO_CAREERS = "https://dentalkart.qandle.com/#/jobs";
export const MEDIA_URL = "https://images.dentalkart.com/media/";
export const GO_TO_FACEBOOK = "https://www.facebook.com/dentalkart.in/";
export const GO_TO_TWITTER = "https://twitter.com/dentalkart";
export const GO_TO_PINTREST = "https://www.pinterest.com/dentalkart/";
export const GO_TO_YOUTUBE = "https://www.youtube.com/channel/UCEcBPyIVP7rK7K04FOYGY8Q";
export const GO_TO_LINKEDIN = "https://in.linkedin.com/company/dentalkart";
export const GO_TO_INSTAGRAM = "https://www.instagram.com/dentalkart/";
export const GO_TO_PLAY_STORE = "https://play.google.com/store/apps/details?id=com.vasadental.dentalkart";
export const GO_TO_APP_STORE = "https://itunes.apple.com/app/dentalkart/id1382207992?ls=1&mt=8";
export const GO_TO_BLOGS = "http://blogs.dentalkart.com/";
export const GO_TO_SITEMAP = "https://www.dentalkart.com/sitemap.xml";
