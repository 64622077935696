import { lazy } from "react";

export const profileScreen = lazy(() => import("./modules/profile"));
export const couponScreen = lazy(() => import("./modules/customerCoupons"));
export const OrdersList = lazy(() => import("./modules/orders"));
export const Wishlist = lazy(() => import("./modules/wishlist"));
export const WishlistProduct = lazy(() => import("./modules/wishlistProduct"));
export const Rewards = lazy(() => import("./modules/rewards"));
export const Authentication = lazy(() =>
  import("./modules/authentication/modules/authComponents")
);
export const AuthModal = lazy(() => import("./modules/authentication"));
export const OrderSuccess = lazy(() => import("./modules/orders/orderSuccess"));
export const AddressScreen = lazy(() => import("./modules/address"));
export const TrackingScreen = lazy(() => import("./modules/tracking"));
export const OrderReturnScreen = lazy(() => import("./modules/returnOrder"));
export const MobileShippmentTransitDetail = lazy(() =>
  import("./modules/tracking/modules/mobileTransitDetails")
);
export const AddressForm = lazy(() =>
  import("./modules/address/modules/addressForm")
);
export const KnowRewards = lazy(() =>
  import("./modules/rewards/modules/knowRewards")
);
export const OtpScreen = lazy(() =>
  import("./modules/authentication/modules/otp")
);
export const MobileAccount = lazy(() => import("./modules/mobileAccount"));
export const PastOrderProductDisplay = lazy (() => import("./modules/orders/buyFromPastOders"))

export const MyMembership = lazy(() => import("./modules/myMembership"));
