import { gql } from '@apollo/client';

const GET_CATEGORY_BANNERS = gql`
    {
        getCategoryPageBanner{
            image
            image_mobile
            alt
            link
        }
    }
`;
export default GET_CATEGORY_BANNERS;
