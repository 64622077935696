import React from "react";
import Style from "./productView.module.scss";
import ProductGalleryConfig from "./ProductGalleryConfig";
import RenderGalleryData from "./RenderGalleryData";

const RenderDesktopProductDetails = ({ product }) => {
  
    return (
      <>
        <div className={Style.product_sections_wrapper}>
              <ProductGalleryConfig
                product={product}
                galleryData={product.media_gallery_entries}
                render={(parentDataAndFunctions) => (
                  <RenderGalleryData
                  parentDataAndFunctions={parentDataAndFunctions}
                  />
                )}
              />
        </div>
      </>
    );
  };
  
  const RenderMobileProductDetails = ({ product, parentDataAndFunctions }) => {
  
    return (
      <>
        <div className={Style.product_sections_wrapper}>
          <ProductGalleryConfig
            product={product}
            galleryData={product.media_gallery_entries}
            render={(parentDataAndFunctions) => (
              <RenderGalleryData parentDataAndFunctions={parentDataAndFunctions} />
            )}
          />
        </div>
      </>
    );
  };
  
  export { RenderDesktopProductDetails, RenderMobileProductDetails };
  