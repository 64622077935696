import React, { useState } from "react";
import Style from "./cookies.module.scss";
import cookie from "react-cookies";
import BrowserPersistence from "@helpers/browser_persistence";
import CancelIcon from "@mui/icons-material/Cancel";

const storage = new BrowserPersistence();

function Cookies() {
  const Iscookies = storage.getItem("cookie");
  const [status, setStatus] = useState(Iscookies);

  const handleCookieSubmit = () => {
    storage.setItem("cookie", true);
    setStatus(true);
  };
  const handleCookieClose = () => {
    setStatus(true);
  };

  return (
    !status && (
      <div className={`${Style.cookies}`}>
        <p className={Style.cookies_text}>
          Cookies help us deliver our services. By using our services, you agree
          to our use of cookies.{" "}
          {/* <span className={Style.learn_more_text}>Learn more</span> */}
        </p>
        <div className={Style.btns}>
          <button className={Style.cookies_button} onClick={handleCookieSubmit}>
            <span className={Style.cookies_button_text}>OK</span>
          </button>
            <CancelIcon className={Style.cancel} sx={{ color: "#25303c" }} onClick={handleCookieClose}/>
        </div>
      </div>
    )
  );
}

export default Cookies;
