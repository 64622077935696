import React, { useContext, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import cookie from "react-cookies";
import { primaryColor } from "@config/dentalkart.environment";
import { withRouter } from "react-router-dom";
import AppContext from "@helpers/appContext";
import Style from "./countryList.module.scss";
import setShippingAddressFun from "@helpers/setShippngAddress";
import { Autocomplete } from "@mui/material";
import getCountryIdFromName from "@helpers/getCountryIdFromName";

const styles = (theme) => ({
  root: {
    color: "#8B8B8B8B",
  },
  cssFocused: {
    color: primaryColor,
  },
  cssLabel: {
    "&$cssFocused": {
      color: primaryColor,
    },
  },
  focused: {
    "&:after": {
      borderBottom: `2px solid ${primaryColor}`,
    },
  },
});

const CountryList = React.memo((props) => {
  const { routes, setAllCountries, globalObject } = useContext(AppContext);

  const [state, setState] = useState({
    searchKey: "",
    countries: props.countries,
    countryChunks: [],
    searchResultsAvailable: true,
    switchCountry: null,
  });

  const handleSetCountry = (countryData) => {
    cookie.save("country", countryData.full_name_english, {path: "/"});
    cookie.save("country_id", countryData.two_letter_abbreviation, {path: "/"});
    setShippingAddressFun(globalObject.setCartTotals);
    props.setIsAddressModalOpen(false);
  };

  useEffect(() => {
    if(state.countries){
    setAllCountries(state.countries);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { classes } = props;
  const { countryChunks } = state;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if(state.countries.findIndex((country) => event.target.value === country.full_name_english) !== -1)
      {
      cookie.save("country", event.target.value, {path: "/"});
      cookie.save("country_id", getCountryIdFromName(event.target.value,state.countries), {path: "/"});
      setShippingAddressFun(globalObject.setCartTotals);
      props.setIsAddressModalOpen(false);
      }
    }
  };
  return (
    <div className={Style.content_wrapper}>
      <div className={Style.search_wrapper}>
        {countryChunks && (
          <Autocomplete
            options={state.countries}
            autoHighlight
            getOptionLabel={(option) => option.full_name_english}
            onKeyPress={(event) => handleKeyPress(event)}
            renderOption={(props, option) => (
              <li
                className={Style.list_column}
                key={option.id}
                {...props}
                onClick={() => handleSetCountry(option)}
                
              >
                  <div className={Style.flag_country_wrapper}>
                    <img
                      className={Style.flag}
                      src={`${
                        routes.MEDIA_URL
                      }flags/${option.two_letter_abbreviation.toLowerCase()}.png`}
                      alt="flag"
                    />
                    <span>{option.full_name_english}</span>
                  </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search your country"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
});

export default withRouter(withStyles(styles)(CountryList));
