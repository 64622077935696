import { gql } from '@apollo/client';

const GET_FORM_BY_ID = gql`
  query GetFormById($form_id:String!){
        getFormById(input:{form_id:$form_id}){
            _id
            form_name
            submit_endpoint
            enable
            fields{
              _id
              name
              type
              key
              regex
              isRequired
              form_id
              options
            }
        }
    }
`;

export default GET_FORM_BY_ID;
