import { gql } from "@apollo/client";

const GET_ALGOLIA_KEY = gql`
  query {
    storeConfig {
      algolia_api_key
      algolia_application_id
      search_mapping {
        current
        providers {
          provider_code
          searched_items
          filters
          searched_term
          total_pages
          current_page
          numerical_filter_stats
          total_hits
        }
      }
    }
  }
`;
export default GET_ALGOLIA_KEY;
