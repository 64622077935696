import { FooterConfiguration } from '@config/dentalkart.environment';

const generateFooterConfiguration = (payload) => {
    let currentConf;
    if (FooterConfiguration[payload.screen]){
        currentConf = FooterConfiguration[payload.screen];
    }
    else {
        console.log('Wrong Screen name provided for footer configuration');
        currentConf = FooterConfiguration.default;
    }
    return currentConf;
}

export default generateFooterConfiguration;