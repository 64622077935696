const API = {
    checkout: {
        estimate_shipping_methods: `rest/default/V1/carts/mine/estimate-shipping-methods`,
        set_estimate_shipping_methods: (cart_id) => `rest/V1/guest-carts/${cart_id}/estimate-shipping-methods`,
        set_shipping_information: (cart_id) => `rest/V1/guest-carts/${cart_id}/shipping-information`,
        set_shipping_and_billing_information: `rest/default/V1/carts/mine/shipping-information`,
        send_payment_information :`rest/default/V1/carts/mine/dt-payment-information`,
        send_payment_information_guest : `rest/V1/carts/guest/dt-payment-information`,
        order_success : (order_id) => `/rest/V2/checkout/order/success/${order_id}`,
        razorpay: `/rest/V1/razorpay/payment/order`,
        razorpayGuest: (cart_id) => `rest/V1/razorpay/payment/guest/order/${cart_id}`,
        get_product_details: (prod_id) => `rest/V1/BulkOrderDataValues/${prod_id}`,
        set_dk_shipping_informmation:`/rest/default/V1/carts/mine/dk-shipping-information`
    },
    validateFields: `/rest/V1/mobileapp/directory/checkOptionalFields`,
   downloadInvoice: (orderIncrementId) => `/rest/V1/invoices/pdf/${orderIncrementId}`,
    algolia_search:
    "https://api-apollo.dentalkart.com/search/results",
    klv_search:
    "https://ksejf1oco7.execute-api.ap-south-1.amazonaws.com/prod/V1/search/results",
    whatsappLogin: 
    "rest/V1/customers/whatsappLogin",
}

export default API;
