import React, { Fragment } from 'react';
import { Mobile, isDesktop } from '@config/dentalkart.environment';
import Style from './carauselSkeleton.module.scss';
import Skeleton from '../../skeleton';

const GetTopDescriptionSkeleton = () => {
    return (
        <div style={{ padding: '7px' }} className={Style.flex_class}>
            <p><Skeleton width={isDesktop ? '260px' : '120px'} height={isDesktop ? '25px' : '20px'} /></p>
            <p> <Skeleton width={isDesktop ? '210px' : '120px'} height={isDesktop ? '50px' : '35px'} /></p>
        </div>
    )
}

const ImageBoxSkeleton = () => {
    return (
        <div style={{ textAlign: 'center', padding: isDesktop ? '15px' : '4px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton width={isDesktop ? '150px' : '120px'} height={isDesktop ? '150px' : '120px'} />
            </div>
            <p style={{ marginTop: '5px' }}><Skeleton width={isDesktop ? '200px' : '140px'} height='15px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '140px'} height='15px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '120px'} height='10px' /></p>
            <p><Skeleton width={isDesktop ? '200px' : '100px'} height='10px' /></p>
        </div>
    )
}

export const GetHomePageProductsSkeleton = ({ IndexKey }) => {
    return (
        <Fragment>
            <div className={Style.skeleton_box}>
                <div style={{ borderBottom: '1px solid #dddd' }}>
                    <GetTopDescriptionSkeleton />
                </div>
                {isDesktop ?
                    <div className={Style.flex_class}>
                        {
                            [1,2,3,4,5,6,7].map((data, index)=>{
                                return(
                                    <div style={{width: '14%', margin:'auto'}} key={index}>
                                        <ImageBoxSkeleton/>
                                    </div>
                                )
                            })
                        }
                    </div>: null
               }
               <Mobile>
                   <div style={{display:'flex', flexWrap : 'wrap'}}>
                       {
                           [1,2,3,4].map((data, index)=>{
                               return(
                                   <div style={{width: '50%'}} key={`Id${index}`}>
                                       <ImageBoxSkeleton/>
                                   </div>
                               )
                           })
                       }
                   </div>
               </Mobile>
            </div>
        </Fragment>
    )
}

const CarauselSkeleton = () => {
    return(
         <>
            {[1].map((index)=>{
                return(
                    <GetHomePageProductsSkeleton IndexKey={index} key={index}/>
                )
            })}
        </>
    )

}

export default CarauselSkeleton;
