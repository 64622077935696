import React, {useContext} from "react";
import {Link} from 'react-router-dom';
import {err404Image} from '@config/dentalkart.environment';
import AppContext from '@helpers/appContext';
import Style from './error.module.scss';

const ErrorScreen = () => {
    const {routes} = useContext(AppContext);

    return(
        <div className={Style.err_wrapper}>
            <img className={Style.err_image} src={err404Image} alt="errImage" />
            <p>Oops!! the page have been moved.</p>
            <Link to={routes.GO_TO_HOME}><span>Go to our home page.</span></Link>
        </div>
    )
}

export default ErrorScreen;



