import { gql } from '@apollo/client';
import productDataFragment from './fragments/productDataFragment.gql'

const GET_GROUP_PRODUCT_QUERY = gql`
    query groupedProductData ($id : Int){
        childProductV2(id:$id)
        {
            parent_price
            parent_stock_status
            items{
                id
                image_url
                name
                sku
                special_price
                url_key
                thumbnail_url
                short_description
                type_id
                manufacturer
                average_rating
                rating_count
                is_in_stock
                ...productDataFragment
            }

        }
    }
    ${productDataFragment}
`;

export default GET_GROUP_PRODUCT_QUERY;
