import React, { useContext, useEffect } from "react";
import AppContext from "@helpers/appContext";
import { GET_ADDRESSES_QUERY } from "@graphql";
import getCountryNameFromId from "@helpers/getCountryNameFromId";
import setShippingAddressFun from "@helpers/setShippngAddress";
import BrowserPersistence from "@helpers/browser_persistence";
import Style from "./addressModal.module.scss";
import cookie from "react-cookies";

const AddressSection = ({addressData, setAddressData, handleCloseAddressModal}) => {
  const { globalObject, client,  isLoggedIn, shippingAddress, setShippingAddress, allCountries, setPincode } =
  useContext(AppContext);
  const storage = new BrowserPersistence();
  const getCustomerAddresses = async (client) => {
    try {
      const { data } = await client.query({
        query: GET_ADDRESSES_QUERY,
      });
      if (
        data &&
        data?.customer?.addresses !== null &&
        data.customer.addresses.length > 0
      ) {
        setAddressData(data.customer.addresses);
      }
    } catch (e) {
      globalObject.handleError(e);
    }
  };

  const handleShippingAddressClick = (data) => {
    if (data.id !== shippingAddress.id) {
      storage.setItem("ShippingAddress", data);
      storage.setItem("pincode", data.postcode);
      setShippingAddress(data);
      setPincode(data.postcode);
    }
      cookie.save("country_id", data.country_code, { path: "/" });
      cookie.save("country", getCountryNameFromId(data.country_code,allCountries), { path: "/" });
      setShippingAddressFun(globalObject.setCartTotals);
      handleCloseAddressModal();
  };
  
  useEffect(() => {
    console.log("called");
    isLoggedIn && getCustomerAddresses(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DisplayAddressData = ({ data, index }) => {
    return (
      <div
        key={index}
        onClick={() => handleShippingAddressClick(data)}
        className={
          data.id === shippingAddress.id
            ? Style.active_address
            : Style.shipping_content_text
        }
      >
        <span>
          {data.firstname} {data.lastname}, {data.telephone},&nbsp;
        </span>
        <span>
          {data.street[0] ? `${data.street[0]}, ` : ""}
          {data.street[1] ? `${data.street[1]}, ` : ""}
          {data.city}, {data.region.region}, {data.postcode}
        </span>
        {data.default_shipping && (
          <p className={Style.default_text}>{`Your default address`} </p>
        )}
      </div>
    );
  };

  return (
    <div>
      {addressData?.length > 0 &&
        addressData.map((data, index) => {
          return <DisplayAddressData data={data} key={index} />;
        })}
    </div>
  );
};

export default AddressSection;
