import { gql } from "@apollo/client";

const BULK_ORDER = gql`
    mutation Bulk_order(
        $name: String
        $email: String!
        $phone: String!
        $postcode: String
        $product_id: Int!
        $quantity: Int!
        $expected_price: Float!
        $source: Int
        $address: String
    ) {
        bulkOrder(
            input: {
                name: $name
                email: $email
                phone: $phone
                postcode: $postcode
                product_id: $product_id
                quantity: $quantity
                expected_price: $expected_price
                source: $source
                address: $address
            }
        ) {
            message
        }
    }
`;

export default BULK_ORDER;
