import React from 'react';
import { Link } from 'react-router-dom';
import Style from './bottomSection.module.scss';
import Button from '@material-ui/core/Button';
import {DentalkartConsumer} from '@helpers/appContext';

const BottomSection = (props) => {
    return(
        <DentalkartConsumer>
            {({routes,history}) => <div className={Style.bottom_section}>
                <Link to={routes.GO_TO_NAVIGATION}>
                    <div className={Style.category_button_section}>
                        <button className={Style.category_button} type="button">Shop By <br/> Category</button>
                    </div>
                </Link>
                <Button 
                    className={Style.search_input_section}    
                    onClick={()=>
                            history.push({pathname:routes.GO_TO_SEARCH})
                        }
                    >
                    Search for products
                </Button>
            </div>}
        </DentalkartConsumer>
    );

}

export default BottomSection;
