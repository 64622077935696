import { gql } from "@apollo/client";

const cartShippingAddressFragment = gql`
  fragment cartShippingAddressFragment on ShippingCartAddress {
    available_shipping_methods {
      carrier_code
      carrier_title
      method_code
      method_title
      error_message
      amount {
        value
        currency
        currency_symbol
      }
      base_amount {
        value
        currency
        currency_symbol
      }
      price_excl_tax {
        value
        currency
        currency_symbol
      }
      price_incl_tax {
        value
        currency
        currency_symbol
      }
      available
    }
  }
`;

export default cartShippingAddressFragment;
