import BrowserPersistence from "@helpers/browser_persistence";


const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const footerData = (label,action) => ({
    event: "Click",
    eventCategory: "Footer",
    eventAction: action,
    eventLabel: label,
    dimension7: getEncryptedEmail()
});


const FooterEvents = {
    CONTACT_US: () => {
        return footerData("ContactUs","About");
    },
    ABOUT_US: () => {
        return footerData("AboutUs","About");
    },
    FOOTER_BLOGS: () => {
        return footerData("Blogs","About");
    },
    DEALER_PARTNERSHIP: () => {
        return footerData("DealerPartnership","About");
    },
    CAREERS_FOOTER: () => {
        return footerData("Careers","About");
    },
    ORDERS_FOOTER: () => {
        return footerData("Orders","Help");
    },
    REFUNDS_FOOTER: () => {
        return footerData("Refunds","Help");
    },
    REWARDS_FOOTER: () => {
        return footerData("Rewards","Help");
    },
    PAYMENTS_FOOTER: () => {
        return footerData("Payments","Help");
    },
    DENTALKART_INTERNATIONAL_FOOTER: () => {
        return footerData("Dentalkart International","Help");
    },
    RETURN_POLICY_FOOTER: () => {
        return footerData("Return Policy","Policy");
    },
    PRIVACY_FOOTER: () => {
        return footerData("Privacy","Policy");
    },
    SITEMAP_FOOTER: () => {
        return footerData("Sitemap","Policy");
    },

    TERMS_OF_USE_FOOTER: () => {
        return footerData("TermsOfUse","Policy");
    },
}
export default FooterEvents;
