import { gql } from '@apollo/client';
import {
    ProductListingFragment, 
    cartPriceFragment, cartShippingAddressFragment, cartRewardFragment
} from './fragments';

const GET_NEW_CART = gql`
	query cart($cart_id: String!){
		cart(cart_id: $cart_id){
			global_errors
			applied_coupons {
                code
            }
            total_quantity
			...ProductListingFragment
            prices {
			   ...cartPriceFragment
            }
			shipping_addresses{
			    ...cartShippingAddressFragment
		    }
        }
        getRewardsCartInfo{
          ...cartRewardFragment
        }
	}
	${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
    ${cartRewardFragment}
`;

export default GET_NEW_CART;
