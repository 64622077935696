import { gql } from '@apollo/client';

const GET_INVESTOR_BANNER = gql`
    {
        getInvestorBanner {
            _id
            mob_img
            web_img
            description
            updated_at
            created_at
    
        }
    }
`;

export default GET_INVESTOR_BANNER;