import React, {useState} from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Styles from './searchHeader.module.scss';

const CustomsaerchBox =  ({refine, currentRefinement, delay, setSearchValue}) => {
	let timerId = null;

	const [serachText, setSearchText] = useState(currentRefinement || '');
	
	const handleSearchFieldChange = (event) => {
		const value = event.target.value;
		clearTimeout(timerId);
		timerId = setTimeout(() => { refine?.(value); setSearchValue?.(value) }, delay);
		setSearchText(value)
	}

	return(
		<FormControl fullWidth className={Styles.search_field}>
			<Input
				id="adornment-search"
				placeholder = 'Search for products, brands and more'
				value={serachText}
				autoFocus
				onChange={handleSearchFieldChange}
				autoComplete={'off'}
                disableUnderline={true}
				endAdornment={
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				}
				className={Styles.input_field}
			/>
		</FormControl>
	)
}

const AlgoliaSearchField =  connectSearchBox(CustomsaerchBox);

const SearchBar = (props) => {
  	return(
		<div className={Styles.search_wrapper}> 
				{true ? <CustomsaerchBox delay={300} {...props}/> : <AlgoliaSearchField />}
		</div>  
    )
};

export default SearchBar;