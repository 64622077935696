import {gql} from '@apollo/client';

const GET_BRAND_CODE = gql`
query{
    getBrandsCode {
        label
        value
      }
}

`

export default GET_BRAND_CODE;
