import { gql } from '@apollo/client';

const GET_CAROUSEL_PRODUCTS = gql`
    query productData ($sku : [String]!){
        products(filter : {
            sku : {
                in : $sku
            }
        }, sort : {name: ASC}, pageSize: 100)
        {
            items{
                id
                name
                image{
                    label
                    url
                }
                media_gallery_entries{
                    id
                    file        
                    types
                }
                url_key
                url_path
                type_id
                stock_status
                short_description{
                    html
                }
                special_price
                manufacturer
                reward_point_product
                sku
                average_rating
                rating_count
                msrp
                tier_prices{
                    qty
                    value
                    percentage_value
                }
                price{
                    regularPrice{
                        amount{
                            value
                            currency
                        }
                    }
                    minimalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                    maximalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                }
                categories{
                    name
                    url_path
                    position
                }
            }
        }
    }
`;

export default GET_CAROUSEL_PRODUCTS;
