import { gql } from '@apollo/client';

const GET_PAST_ORDER_PRODUCT = gql`
    query {
		buyAgain{
			average_rating
			id
			image_url
            is_in_stock
			manufacturer
			name
			rating_count
			reward_point_product
			short_description
			sku
			special_price
			thumbnail_url
			url_key
			price {
				minimalPrice {
					amount {
						currency_symbol
						value
						currency
					}
					adjustments
				}
				maximalPrice {
					amount {
						currency_symbol
						value
						currency
					}
					adjustments
				}
				regularPrice {
					amount {
						currency_symbol
						value
						currency
					}
					adjustments
				}
			}
			type_id
			tier_prices {
				customer_group_id
				qty
				value
				percentage_value
				website_id
			}
			
		}
    }
`;

export default GET_PAST_ORDER_PRODUCT;
