import {gql} from '@apollo/client';

const UPDATE_ADDRESS = gql`
    mutation updateAddress(
        $id: Int!,
        $firstname: String,
        $lastname: String,
        $postcode: String,
        $telephone: String,
        $street:[String],
        $country_id: CountryCodeEnum,
        $region_id: Int,
        $city: String,
        $vat_id: String,
        $mobile_no_code:String!,
        $mobile_no_value:String!,
        $default_shipping: Boolean,
        $default_billing:Boolean
    ){
        updateCustomerAddress( id: $id, input: {
            firstname: $firstname,
            lastname: $lastname,
            postcode: $postcode,
            telephone: $telephone,
            street: $street,
            country_code: $country_id,
            region: {
                region_id: $region_id
            },
            city: $city,
            custom_attributes:[
                {attribute_code:$mobile_no_code, value:$mobile_no_value}
            ],
            vat_id: $vat_id,
            default_shipping: $default_shipping,
            default_billing:$default_billing
        }){
                id,
                firstname,
                lastname,
                postcode,
                telephone,
                street,
                region{
                    region
                    region_id
                    region_code
                },
                country_code,
                custom_attributes{
                    attribute_code
                    value
                },
                city,
                vat_id,
                default_shipping,
                default_billing
        }
    }
`;

export default UPDATE_ADDRESS;
