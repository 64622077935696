import {gql} from '@apollo/client';

const GET_MAGAZINE = gql `
query getMagazine(
    $magazine_name: String
    $year: String
    $month: String

    $pageNumber: Int
    $rowsPerPage: Int 
){
    getMagazine(
        filters: {
            magazine_name: $magazine_name
            year: $year
            month: $month
        }
        pagination:{
            rowsPerPage: $rowsPerPage
            pageNumber: $pageNumber
        }
    ){
        result{
            _id
            magazine_url
            magazine_name
            author
            publisher
            date_of_publish
            coverPage_url
            created_At
            updated_At
            enable
        }
        count
    }
}
`;

export default GET_MAGAZINE;