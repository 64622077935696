import { gql } from '@apollo/client';

const GET_CART_ITEM_COUNT = gql`
	query cart($cart_id: String!){
		cart(cart_id: $cart_id){
            id
		    items {
				id
			}
        }
	}
`;

export default GET_CART_ITEM_COUNT;
