import { gql } from '@apollo/client';
// eslint-disable-next-line no-unused-vars



const GET_CATEGORY_PRODUCTS_NEW =  gql`
        query CategoryProducts(
          $category_id:Int!,$page_no:Int, $price: CategoryPriceFilterInput, $sortBy:SortEnum,$manufacturer:[String]){
            getCategoryProducts(
                 category_id:$category_id,
                 page_no:$page_no,
                filter:{
                    manufacturer:$manufacturer,
                    price:$price

                },
                sort:{price:$sortBy }
            ){
                 product_count
                 page_no
                 category_id
                 name
                 url_key
                 description
                 image
                 meta_title
                 meta_keywords
                 meta_description
                 ads_banner{
                 type
                 banners{
                   web_url
                   web_img
                   alt
                   app_url
                   mobile_img
                 }
               }
                 items{
                    average_rating
                    id
                    image_url
                    manufacturer
                    name
                    rating_count
                    reward_point_product
                    short_description
                    sku
                    special_price
                    thumbnail_url
                    msrp
                    demo_available 
                    url_key
                    price{
                      minimalPrice{
                        amount{
                          currency
                          value
                          currency_symbol
                        }
                      }
                      maximalPrice{
                        amount{
                          currency
                          value
                          currency_symbol
                        }
                      }
                      regularPrice{
                        amount{
                          currency
                          value
                          currency_symbol
                        }
                      }
                    }

                    type_id
                    tier_prices{
                       customer_group_id
                       qty
                       value
                       percentage_value
                       website_id
                    }
                    is_in_stock
                    


                }
            }
        }

    `;


export default GET_CATEGORY_PRODUCTS_NEW;
