import React from 'react';
import Style from './skeleton.module.scss';

const  Skeleton = (props) => {
    const { width, height, borderRadius} = props;
    return(
        <span className={Style.skeleton_box} style={{width: width, height: height, borderRadius:borderRadius ? borderRadius: 0}}></span>
    )
}

export default Skeleton;


