import React, { useState, useEffect } from "react";
import {ComponentLoader} from '@components/loader';
import {
    CategoryScreenSkeleton,
    FiltersSkelelton,
    CartSkeleton,
    ProductDetailsSkeleton,
    HomePageSkeleton,
    OrderListSkeleton,
    CarauselSkeleton
} from '../screenSkeletons';

const FetchSkeleton = ({page}) => {
    const [state, setState] = useState(page);

    useEffect(() => {
        setState(page)
    }, [page])

    const getComponent = {
        'homepage': <HomePageSkeleton/>,
        'category': <CategoryScreenSkeleton/>,
        'categoryFilters': <FiltersSkelelton/>,
        'cartPage': <CartSkeleton/>,
        'productDetail':<ProductDetailsSkeleton/>,
        'orderPage':<OrderListSkeleton />,
        'productCarousel': <CarauselSkeleton />
    }

   return getComponent[state] || <ComponentLoader/>

};

export default FetchSkeleton;
