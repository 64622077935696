import React, { Suspense, useEffect, useState, useCallback, lazy } from "react";
import { Switch, Route, withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import AppContext from "@helpers/appContext";
import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";
import {
  GET_GUEST_CART_ID,
  GET_CUSTOMER_CART_ID,
  GET_MERGE_CART,
  GET_RECENTLY_VIEWED_PRODUCTS,
  LOGOUT_QUERY,
  BASE_COUNTRY_QUERY,
  SET_COUNTRY_QUERY,
  CUSTOMER_INFO_QUERY,
  GET_CART_ITEM_COUNT,
  GET_ALGOLIA_KEY,
  GET_WHATSAPP_API,
  GET_BRAND_CODE
} from "@graphql";
import setShippingAddressFun from "@helpers/setShippngAddress";
import {
  HeaderConfiguration,
  FooterConfiguration,
} from "@config/dentalkart.environment";
import * as dentalkartConfig from "@config/dentalkart.environment";
import getLoginStatus from "@helpers/getLoginStatus";
import routeComponent from "@helpers/routeComponent";
import { FullScreenLoader } from "@components/loader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ProductDetailsOrListingComponent } from "@screens";
import generateHeaderConfiguration from "@helpers/generateHeaderConf";
import generateFooterConfiguration from "@helpers/generateFooterConf";
import { AuthModal } from "@screens/account";
import * as Routes from "../config/route";
import Style from "./app.module.scss";
import isInvalidCart from "@helpers/inActiveCartError";
import Header from "@components/header";
import fireEvents from "@helpers/event_data_layer";
import { appSyncLiveCLientTwo, appSyncLiveCLient } from "@apolloClient";
import Cookies from "../components/cookies";
import AddressModal from "@components/addressModal";

const Drawer = lazy(() => import("@components/drawer"));
const Sidebar = lazy(() => import("@components/sidebar"));
const Footer = lazy(() => import("@components/footer"));

const storage = new BrowserPersistence();

const App = (props) => {
  const [accountSidebar, setAccountSidebar] = useState({
    visiblity: false,
    activeLinkName: "Personal Information",
  });
  const [cartTotals, setCartTotals] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(storage.getItem("ShippingAddress") || "");
  const [pincode, setPincode] = useState(storage.getItem("pincode") || "");
  const [newOrderState, setNewOrderState] = useState({
    newOrderPlaced: false,
    newOrderInit: false,
  });

  const [mergeCartLoader, setMergeCartLoader] = useState(false);
  const [allCountries, setAllCountries] = useState([]); 
  const [IsAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [brandsCode, setBrandsCode] = useState();
  const [state, setState] = useState({
    drawer: props.drawer,
    addToCartLoaderIds: [],
    addToWishlistLoaderIds: [],
    isLoggedIn: getLoginStatus(),
    cartCount: storage.getItem("cartCount") || 0,
    headerConf: props.headerConf,
    footerConf: props.footerConf,
    loaderVisiblity: false,
    authModalVisibility: false,
    baseCountry: "",
  });

  const [whatsappLink, setWhatsappLink] = useState();
  const {
    isLoggedIn,
    authModalVisibility,
    headerConf,
    footerConf,
    baseCountry,
  } = state;

  const { newOrderInit } = newOrderState;

  const { history, client } = props;

  const setHeaderConf = useCallback(
    (payload = {}) => {
      let currentConf = generateHeaderConfiguration(payload);
      setState((prevState) => ({ ...prevState, headerConf: currentConf }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(headerConf)]
  );

  const setfooterConf = useCallback(
    (payload = {}) => {
      let currentConf = generateFooterConfiguration(payload);
      setState((prevState) => ({ ...prevState, footerConf: currentConf }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(footerConf)]
  );

  const setSideBarState = useCallback(
    (payload) => {
      setAccountSidebar({ ...payload });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(accountSidebar)]
  );

  // const setAddress = useCallback((payload) => {
  //   setState((prevState) => ({ ...prevState, address: payload }));
  // }, []);

  const addToWishlistLoaderIds = (id, action) => {
    const { addToWishlistLoaderIds } = state;
    if (action === "add") {
      addToWishlistLoaderIds.push(id);
    } else if (action === "remove") {
      addToWishlistLoaderIds.splice(addToWishlistLoaderIds.indexOf(id), 1);
    }
    setState((prevState) => ({ ...prevState, addToWishlistLoaderIds }));
  };

  const addToCartLoaderIds = (sku, action) => {
    const { addToCartLoaderIds } = state;
    if (action === "add") {
      addToCartLoaderIds.push(sku);
    } else if (action === "remove") {
      addToCartLoaderIds.splice(addToCartLoaderIds.indexOf(sku), 1);
    }
    setState((prevState) => ({ ...prevState, addToCartLoaderIds }));
  };

  const toggleSnackBar = (message, options = {}) => {
    const action = (key) => (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeSnackbar(key)}
      >
        <CloseIcon className={Style.icon} />
      </IconButton>
    );
    message = message || "Specify message."; // message: 'shown here'
    options.action = action;
    options.variant = options.variant || "success";
    // options.variant: oneOf(['default', 'error', 'success', 'warning', 'info'])
    options.anchorOrigin = options.anchorOrigin || {
      vertical: "bottom",
      horizontal: "right",
    }; // options.anchorOrigin: { vertical: 'bottom', horizontal: 'center',}
    options.autoHideDuration = options.autoHideDuration || 3000;
    options.preventDuplicate = true;
    props.enqueueSnackbar(message, options); // options.autoHideDuration: 3000
  };

  const getUserInfo = async () => {
    try {
      if (state.isLoggedIn) {
        const { data } = await client.query({
          query: CUSTOMER_INFO_QUERY,
          fetchPolicy: "network-only",
        });
        if (data && data.customer) {
          const userInfo = data.customer;
          fireEvents("GTM", "USER_INFO", { ...userInfo, gclid: state.gclid });
          setUserInfo(userInfo);
        }
      } else {
        fireEvents("GTM", "USER_INFO", { ...userInfo, gclid: state.gclid });
      }
    } catch (error) {
      handleError(error);
    }
    return null;
  };

  const getCodeBrand = async () => {

    try {
      const { data } = await client.query({
        query: GET_BRAND_CODE,
        fetchPolicy: "network-only",
      })
      if (data?.getBrandsCode) {
        setBrandsCode(data.getBrandsCode);
      }
    } catch (error) {
      handleError(error);
    }
  };


  const sendRecentlyViewdProducts = async () => {
    const storedSku = storage.getItem("skus");
    if (storedSku && storedSku.length > 1) {
      try {
        const { data } = await client.mutate({
          mutation: GET_RECENTLY_VIEWED_PRODUCTS,
          variables: { sku: storedSku },
        });
        if (data) {
          storage.setItem("skus", []);
        }
      } catch (error) {
        console.log("something went wrong ");
      }
    }
  };

  const getRecentlyViewedProducts = async () => {
    let product = [];
    if (state.isLoggedIn) {
      const storedSkus = storage.getItem("skus");
      if (storedSkus && storedSkus.length > 0) {
        product = storedSkus;
      }

      try {
        const { data } = await client.mutate({
          mutation: GET_RECENTLY_VIEWED_PRODUCTS,
          variables: { sku: product },
        });
        product = data.recentlyViewed;
        storage.setItem("skus", []);
      } catch (error) {
        console.log("something went wrong ");
      }
    }
    return product;
  };

  const checkGooglAds = () => {
    const searchString = window.location.search;
    const searchParams = new URLSearchParams(searchString);
    if (searchParams.has("gclid")) {
      setState((prevState) => ({
        ...prevState,
        gclid: searchParams.get("gclid"),
      }));
      fireEvents("GTM", "GOOGLE_ADD_USER", {
        gclid: searchParams.get("gclid"),
      });
      if (!state.isLoggedIn) {
        fireEvents("GTM", "USER_INFO", { gclid: searchParams.get("gclid") });
      }
    }
    if (searchParams.has("dclid")) {
      setState((prevState) => ({
        ...prevState,
        dclid: searchParams.get("dclid"),
      }));
      fireEvents("GTM", "GOOGLE_ADD_USER", {
        dclid: searchParams.get("dclid"),
      });
    }
  };

  const getCartItemCount = (count) => {
    setState((prevState) => ({ ...prevState, cartCount: count }));
    storage.setItem("cartCount", count);
  };

  const getCartItems = async (cart_id, getGuestAndCustomerCartId) => {
    if (cart_id) {
      try {
        const { data } = await client.query({
          query: GET_CART_ITEM_COUNT,
          variables: { cart_id: cart_id },
          fetchPolicy: "network-only",
        });
        if (data?.cart?.items) {
          getCartItemCount(data.cart.items.length);
        }
      } catch (e) {
        const shouldRetry = !e.networkError && isInvalidCart(e);
        if (shouldRetry) {
          storage.removeItem("customer_cart_id");
          storage.removeItem("guest_cart_id");
          await getGuestAndCustomerCartId();
        }
        console.log(e);
      }
    }
  };

  const getMergeCart = async (guest_cart_id, customer_cart_id) => {
    try {
      if (guest_cart_id && customer_cart_id) {
        setMergeCartLoader(true);
        const { data } = await client.mutate({
          mutation: GET_MERGE_CART,
          variables: {
            source_cart_id: guest_cart_id,
            destination_cart_id: customer_cart_id,
          },
        });
        if (data?.mergeCarts?.items) {
          setMergeCartLoader(false);
          storage.removeItem("guest_cart_id");
          return data.mergeCarts.items.length;
        }
      }
    } catch (err) {
      setMergeCartLoader(false);
      console.log(err);
    }
  };

  const getGuestAndCustomerCartId = async (loggedIn = false) => {
    let cartId,
      cartCount = state.cartCount;
    const guest_cart_id = storage.getItem("guest_cart_id");
    const customer_cart_id = storage.getItem("customer_cart_id");
    const loggedInStatus = await getLoginStatus();
    cartId = loggedInStatus ? customer_cart_id : guest_cart_id;

    try {
      if (!loggedInStatus && !guest_cart_id) {
        const { data } = await client.mutate({
          mutation: GET_GUEST_CART_ID,
        });
        if (data) {
          cartId = data.createEmptyCart;
          cartCount = 0;
          storage.setItem("guest_cart_id", data.createEmptyCart);
          getCartItemCount(0);
        }
      } else if (loggedInStatus && !customer_cart_id) {
        const { data } = await client.query({
          query: GET_CUSTOMER_CART_ID,
          fetchPolicy: "network-only",
        });
        if (data) {
          cartId = data.customerCart.id;
          storage.setItem("customer_cart_id", data.customerCart.id);
          cartCount = data.customerCart.items.length;
          if (guest_cart_id) {
            cartCount = await getMergeCart(guest_cart_id, data.customerCart.id);
          }
          getCartItemCount(cartCount);
        }
      } else if (customer_cart_id || guest_cart_id) {
        const cart_id = loggedInStatus ? customer_cart_id : guest_cart_id;
        getCartItems(cart_id, getGuestAndCustomerCartId);
      }
      if (cartId && cartCount > 0)
        await setShippingAddressFun(setCartTotals, cartId);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleDrawer = (side, value) => {
    let drawer = state.drawer;
    drawer[side] = value;
    setState((prevState) => ({ ...prevState, drawer }));
  };

  const showAuthenticationModal = useCallback(() => {
    setState((prevState) => ({ ...prevState, authModalVisibility: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authModalVisibility]);

  const logout = async () => {
    const token = storage.getItem("token");
    const logoutOperation = async () => {
      cookie.save("country_id", "IN", { path: "/" });
      cookie.save("country","India", { path: "/" });
      storage.removeItem("guest_cart_id");
      storage.removeItem("customer_cart_id");
      storage.removeItem("token");
      storage.removeItem("ShippingAddress");
      storage.removeItem("pincode");
      storage.removeItem("checkout_pincode");
      storage.removeItem("cartCount");
      storage.removeItem("sku");
      toggleSnackBar("Logged Out Successfully.", { variant: "info" });
      window.location.reload();
    };
    setState((prevState) => ({ ...prevState, loaderVisiblity: true }));
    try {
      const { data } = await client.mutate({ mutation: LOGOUT_QUERY });

      if (data?.revokeCustomerToken) {
        logoutOperation();
      }
    } catch (err) {
      token && logoutOperation();
      setState((prevState) => ({ ...prevState, loaderVisiblity: false }));
      console.error(err);
    }
  };

  const handleError = ({
    graphQLErrors,
    networkError,
    operation,
    response,
  }) => {
    const type = "error";
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions, path, debugMessage }) => {
        let category = extensions?.category;
        switch (category) {
          case "graphql-authorization":
            logout();
            toggleSnackBar(`${message}`, { variant: type });
            break;
          case "internal":
            toggleSnackBar(`${debugMessage || message}`, { variant: type });
            break;
          case "graphql-no-such-entity":
            toggleSnackBar(`${message}`, { variant: type });
            break;
          default:
            toggleSnackBar(`${message}`, { variant: type });
        }
        return null;
      });
    } else if (networkError) {
      toggleSnackBar(`${networkError.message}`, { variant: type });
    }
  };

  const getBaseCountry = async () => {
    let baseCountry = {};
    const searchString = window.location.search;
    const searchParams = new URLSearchParams(searchString);
    const getDefaultBehaviour = async () => {
      try {
        if (cookie.load("country_id")) {
          baseCountry = {
            country_id: cookie.load("country_id"),
            country: cookie.load("country"),
          };
        } else {
          const { data } = await client.query({
            query: BASE_COUNTRY_QUERY,
            fetchPolicy: "network-only",
          });
          cookie.save("country_id", data.baseCountry.country_id, { path: "/" });
          cookie.save("country", data.baseCountry.country, { path: "/" });
          cookie.save("currency_code", data.baseCountry.currency_code, {
            path: "/",
          });
          baseCountry = data.baseCountry;
        }
        setState((prevState) => ({ ...prevState, baseCountry: baseCountry }));
      } catch (error) {
        toggleSnackBar(error.message, { variant: "error" });
      }
    };
    if (searchParams.has("cc")) {
      const country_id = searchParams.get("cc").toUpperCase();
      try {
        const { data } = await client.mutate({
          mutation: SET_COUNTRY_QUERY,
          variables: { country_code: country_id },
        });
        cookie.save("currency_code", data.setCurrency.currency_code, {
          path: "/",
        });
        cookie.save("country_id", country_id, { path: "/" });
        cookie.save("country", data.setCurrency.full_name_english, {
          path: "/",
        });
        const baseCountry = {
          country_id: country_id,
          country: data.setCurrency.full_name_english,
          currency_code: data.setCurrency.currency_code,
        };
        setState((prevState) => ({ ...prevState, baseCountry: baseCountry }));
      } catch (error) {
        getDefaultBehaviour();
      }
    } else {
      getDefaultBehaviour();
    }
  };

  const getAlgoliaKey = async () => {
    try {
      const { data } = await appSyncLiveCLientTwo.query({
        query: GET_ALGOLIA_KEY,
        fetchPolicy: "network-only",
      });
      if (data?.storeConfig?.search_mapping) {
        data.storeConfig?.search_mapping &&
          storage.setItem("algolia_config", data.storeConfig?.search_mapping);
        return data.storeConfig?.search_mapping;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getWhatsAppLink = async () => {
    try {
      const { data } = await appSyncLiveCLient.query({
        query: GET_WHATSAPP_API,
        fetchPolicy: "network-only",
      });
      if (data?.getWhatsappApi) {
        setWhatsappLink(data.getWhatsappApi);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const listenRoute = () => {
    fireEvents("GTM", "USER_INFO", { ...userInfo, gclid: state.gclid });
  };

  const checkIfHomePage = () => {
    if (location.pathname === "/") return true;

    return false;
  };
  const location = useLocation();

  useEffect(() => {
    listenRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    getGuestAndCustomerCartId();
    getUserInfo();
    getBaseCountry();
    getAlgoliaKey();
    //setShippingAddressFun(setCartTotals);
    checkGooglAds();
    getWhatsAppLink();
    getCodeBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const globalObject = {
    state,
    setState,
    showAuthenticationModal,
    getMergeCart,
    setNewOrderState,
    addToWishlistLoaderIds,
    addToCartLoaderIds,
    toggleSnackBar,
    toggleDrawer,
    getCartItemCount,
    handleError,
    getGuestAndCustomerCartId,
    sendRecentlyViewdProducts,
    getRecentlyViewedProducts,
    setSideBarState,
    setCartTotals,
    cartTotals,
  };

  const contextValues = {
    isLoggedIn,
    routes: Routes,
    history: history,
    client: client,
    userInfo: userInfo,
    getAlgoliaKey,
    setUserInfo: setUserInfo,
    accountSidebar,
    globalObject,
    DentalkartConfig: dentalkartConfig,
    headerConf,
    setHeaderConf,
    setfooterConf,
    baseCountry,
    mergeCartLoader,
    logout,
    shippingAddress,
    setShippingAddress,
    pincode, 
    setPincode,
    IsAddressModalOpen, 
    setIsAddressModalOpen,
    allCountries, 
    setAllCountries,
    brandsCode
  };
  
  const { algoliaScreen } = headerConf;
  return (
    <AppContext.Provider value={contextValues}>
       <AddressModal IsAddressModalOpen={IsAddressModalOpen} setIsAddressModalOpen={setIsAddressModalOpen} />
      {!algoliaScreen && (
        <Header
          configuration={headerConf}
          drawerLeft="left"
          isLoggedIn={isLoggedIn}
          logout={logout}
          userInfo={userInfo}
          client={client}
          baseCountry={baseCountry}
          history={history}
        />
      )}
      <Suspense fallback={<FullScreenLoader />}>
        <Drawer
          drawer={state.drawer}
          toggleDrawer={toggleDrawer}
          side="left"
          content={<Sidebar userInfo={userInfo} toggleDrawer={toggleDrawer} />}
        />
        <div className={Style.route_content}>
          <AuthModal
            authModalVisibility={state.authModalVisibility}
            client={client}
            setState={setState}
            globalObject={globalObject}
          />
          <Switch>
            {Routes.screens.map((route) =>
              routeComponent(
                isLoggedIn,
                { ...route },
                { ...contextValues },
                newOrderInit
              )
            )}
            <Route
              render={(props) => (
                <ProductDetailsOrListingComponent {...props} />
              )}
            />
          </Switch>
        </div>
        <Footer configuration={footerConf} />
        {state.loaderVisiblity ? <FullScreenLoader /> : null}
      </Suspense>
      {whatsappLink && whatsappLink.enable && checkIfHomePage() && (
        <a
          href={whatsappLink.web_link}
          target="_blank"
          className={Style.whatsapp}
        >
          <img src="https://sale-banners-dtkrt-2020.s3.ap-south-1.amazonaws.com/icons8-whatsapp+(1).svg" />
        </a>
      )}
       <Cookies />
    </AppContext.Provider>
  );
};

App.propTypes = {
  drawer: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  headerConf: PropTypes.object.isRequired,
  footerConf: PropTypes.object.isRequired,
};

App.defaultProps = {
  drawer: { left: false, top: false, right: false, bottom: false },
  headerConf: { ...HeaderConfiguration.default, heading: "Dentalkart" },
  footerConf: { ...FooterConfiguration.default },
};

export default withRouter(withSnackbar(App));
