import { gql } from '@apollo/client';

const GET_BANNERS = gql`
    {
        getBrandPageBanners{
            image_url
            link
            alt
            sort_order
        }
    }
`;
export default GET_BANNERS;
