
import {gql} from '@apollo/client';

const SET_GUEST_EMAIL_ON_CART = gql`
mutation setGuestEmailOnCart(
    $cart_id: String!
    $email: String!
){
    setGuestEmailOnCart(input: {
        cart_id: $cart_id
        email: $email
    }) {
        cart {
            id
            available_payment_methods {
                 code
                 title
            }
        }
    }
}
`;
export default SET_GUEST_EMAIL_ON_CART;
