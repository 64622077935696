import { gql } from '@apollo/client';

const DEMO_REQUEST_MUTATION = gql`
    mutation demoRequest($input: DemoRequestInput){
        demoRequest(input: $input){
            message
        }
    }
`;

export default DEMO_REQUEST_MUTATION;
