import { gql } from '@apollo/client';

const GET_ALGOLIA_KEY = gql`
    query{
        storeConfig{
            algolia_api_key
            algolia_application_id
        }
    }
`
export default GET_ALGOLIA_KEY;