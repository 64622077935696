import { gql } from '@apollo/client';

const SUBMIT_REVIEW = gql`
    mutation submitReview(
        $id: Int,
        $product_id: Int!,
        $title: String!,
        $details: String!,
        $nickname: String!,
        $rating:Int!
    ){
        postProductReview(review: {
            id: $id,
            product_id: $product_id,
            title: $title,
            details: $details,
            nickname: $nickname,
            rating: $rating
        })
        {
            id 
        }
    }
`;
export default SUBMIT_REVIEW;
