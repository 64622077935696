import { gql } from '@apollo/client';

const GET_AVAILABLE_PAYMENT_METHOD = gql`
    query GetAvailableMethodsV2($pincode : String!, $is_cod:Boolean!, $total_weight:Float!, $total_amount:Float!, $country_code:String){
        GetAvailableMethodsV2(pincode: $pincode, is_cod: $is_cod, total_weight:$total_weight, total_amount:$total_amount, country_code: $country_code){
            payment_methods{
                code
                title
            }
            checkcod{
            message
            message_arr
            cod_available
            service_available
            message_arr
            }
        }

    }
`;

export default GET_AVAILABLE_PAYMENT_METHOD;
