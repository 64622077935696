import React, { useEffect, useContext, useState } from "react";
import  AppContext  from '@helpers/appContext';
import { GET_PDP_PRODUCT_DATA,GET_PRODUCT_SPECIFICATIONS_QUERY } from "@graphql";
import Paper from "@mui/material/Paper";
import Style from "./productSearch.module.scss";
import { TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import { ComponentLoader } from "@components/loader";
import ProductGallery from "./components/ProductGallery";

const ProductSearch = () => {
    const { globalObject, client } = useContext(AppContext);
    const [sku, setSku] = useState(null);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState(null);
    const [prodSpecicification, setProdSpecification] = useState(null);

    const getProductData = async (sku) => {
        try {
            setLoading(true)
            const { data } = await client.query({
                query: GET_PDP_PRODUCT_DATA,
                variables: { sku: [sku] },
                fetchPolicy: "cache-first",
            });
            if(data){
                setProductData(data?.productData);
            }
            
            globalObject.handleError("Product not found.");
            setLoading(false);
            const { data:specData } = await client.query({
                query: GET_PRODUCT_SPECIFICATIONS_QUERY,
                variables: { id: data?.productData[0]?.id },
                fetchPolicy: "cache-first",
            });
            console.log("ssss",specData)

            setProdSpecification(specData.GetAttributesBySku);
           
        } catch (error) {
            globalObject.handleError(error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            getProductData(sku);
        }
      };

    
    return(
        <div className={Style.prod_Container}>
            <Paper className={Style.paper_class}>
                <div className={Style.search_container}>
                    <TextField 
                        id="sku-search"
                        label="Search by sku code"
                        size="small"
                        value={sku}
                        onChange={(e) => setSku(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={Style.search_button}
                        startIcon={<SearchIcon />}
                        onClick={() => getProductData(sku)}
                        >
                        Search
                    </Button>
                </div>
                {
                    loading ? 
                    <div className={Style.componentLoader_wrapper}>
                        <div className={Style.componentLoader_circle_wrapper}>
                            <ComponentLoader />
                        </div>
                    </div> : 
                       (productData?.length > 0 ? (
                        <div className={Style.data_container}>
                          <br />
                          <br />
                          <h2>{productData[0]?.sku}</h2>
                          <p>{productData[0]?.name}</p>
                          <br />
                          <div className={Style.prod_gallery}>
                            <ProductGallery product={productData[0]}/>
                          </div>
                          <p>
                            MRP: <span>₹ </span>
                            {parseFloat(productData[0]?.price?.regularPrice?.amount?.value) }
                          </p>
                          <p>
                            Sale Price:{" "}
                            {productData[0]?.special_price && (
                              <span>
                                <span>₹ </span>
                                {productData[0]?.special_price }
                              </span>
                            )}
                          </p>
                          <br />
                          <p>
                            Current Status on Dentalkart.com:{" "}
                            {productData[0]?.is_in_stock ? (
                              <span className={Style.inStock}>In Stock</span>
                            ) : (
                              <span className={Style.outOfStock}>Out Of Stock</span>
                            )}
                          </p>
                          <p>
                            Current Stock Qty on Dentalkart.com:{" "}
                            {productData[0]?.qty ? productData[0]?.qty : "NA"}
                          </p>
                          <p>
                            Expiry Date on Dentalkart.com:{" "}
                            {productData[0]?.pd_expiry_date ? <span>{productData[0]?.pd_expiry_date}</span> : <span>NA</span>}
                          </p> 
                          <p>Weight on Dentalkart.com: {productData[0]?.weight ? productData[0]?.weigh : "NA"}</p>
                          <br />
                          <div className={Style.details_container}>
                            <p className={Style.title}>Short Description:</p>
                            <div>{productData[0]?.short_description}</div>
                            <br />
                            {prodSpecicification && console.log(prodSpecicification)}
                            {
                                
                                prodSpecicification?.attributes?.length > 0 &&
                                (
                                    prodSpecicification?.attributes?.map(item => (
                                        <div>
                                            <p className={Style.title}>{item.attribute_label}</p>
                                           <div style={{paddingLeft:"5px"}} dangerouslySetInnerHTML={{ __html: item.attribute_value }}></div>
                                           <br />
                                        </div>
                                    
                                    ))
                                )
                            }
                          </div>
                          <a href={`http://www.dentalkart.com/${productData[0]?.url_key}.html`} target="_blank" rel="noreferrer">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<LaunchIcon />}
                              className={Style.web_btn}
                            >
                              View on Website
                            </Button>
                          </a>
                        </div>
                      )
                      : ""
                      )
                }
               
            </Paper>
        </div>
    )
}

export default ProductSearch;