import cookie from "react-cookies";
import BrowserPersistence from "./browser_persistence";
import { postRequest } from "./postRequest";
import getLoginStatus from "./getLoginStatus";
import API from "@config/api";

const storage = new BrowserPersistence();

const setShippingAddressFun = async (setCartTotals,  cartId=null) => {
  try {
    setCartTotals && setCartTotals(null);
    const country_id = cookie.load("country_id") || "IN";
    const guest_cart_id = storage.getItem("guest_cart_id");
    const customer_cart_id = storage.getItem("customer_cart_id");
    const loggedInStatus = await getLoginStatus();
    const cart_id = loggedInStatus ? customer_cart_id : guest_cart_id;
    const address_data = {
      region: "",
      postcode: "",
      country_id: country_id,
    };
    const address_payload = { address: { ...address_data } };
    const apiUrl = loggedInStatus
      ? API.checkout.set_dk_shipping_informmation
      : API.checkout.set_shipping_information(cartId || cart_id);

    const res = await postRequest(
      API.checkout.set_estimate_shipping_methods(cartId || cart_id),
      address_payload
    );

    if (!res.ok) throw res;
    const shipping_methods = await res.json();
    const shipping_info_res = await postRequest(apiUrl, {
      addressInformation: {
        shipping_address: { ...address_data },
        billing_address: { ...address_data },
        shipping_carrier_code: shipping_methods?.[0]?.carrier_code ?? null,
        shipping_method_code: shipping_methods?.[0]?.method_code ?? null,
      },
    });
    if (!shipping_info_res.ok) throw shipping_info_res;
    const info_data = await shipping_info_res.json();
    setCartTotals && info_data && setCartTotals(info_data?.totals);

    return true;
  } catch (e) {
    console.log(e, "cart Total of undefined");
    setCartTotals("error")
    return false;
  }
};

export default setShippingAddressFun;
