import BrowserPersistence from "@helpers/browser_persistence";
import { generalEventData } from "./helper.js";

const storage = new BrowserPersistence();

const AuthenticationEvents = {
    SIGNUP_FAILED: (data) => {
        const SIGNUP_FAILED_DATA = {
            event: "SignUpFailed",
            eventCategory: "SignUp",
            reason: data,
        };
        return SIGNUP_FAILED_DATA;
    },

    SIGNUP_A: (data) => {
        const SIGNUP_A_DATA = {
            event: "Click",
            eventCategory: "SignUp",
            eventAction: data ? "Checkout Signup" : "Direct Signup",
            eventLabel: "Step 1-sign up",
        };
        return SIGNUP_A_DATA;
    },
    SIGNUP_B: (data) => {
        const SIGNUP_B_DATA = {
            event: "Click",
            eventCategory: "SignUp",
            eventAction: "Direct ignup",
            eventLabel: "Step 2-sign up",
            ...(data.email && { email: data.email }),
            ...(data.mobile && { mobile: data.mobile }),
        };
        return SIGNUP_B_DATA;
    },
    SIGNUP_C: (data) => {
        const SIGNUP_C_DATA = {
            event: "Click",
            eventCategory: "Sign Up",
            eventAction: "Direct Signup",
            eventLabel: "Step 3-sign up",
            email: data.email && data.email,
            mobile: data.mobile && data.mobile,
            firstname: data.firstname,
            lastname: data.lastname,
            customer_id: window.btoa(data.email ? data.email : `${data.mobile}@dentalkart.user`),
        };
        return SIGNUP_C_DATA;
    },

    SIGNUP_D: (data) => {
        const eventData = {
            category: "SignUp",
            action: data ? "CheckoutSignup" : "DirectSignup",
            label: "Step4-SignUp",
        };
        return generalEventData(eventData);
    },

    SIGNUP_E: (data) => {
        const SIGNUP_E_DATA = {
            event: "Click",
            virtualPageURL: data.landingUrl,
            virtualPageTitle: data.title,
            eventCategory: "Sign Up",
            customer_id: window.btoa(data.c_id),
            eventAction: data.checkoutFlow ? "Checkout Signup" : "Direct Signup",
            eventLabel: "Signup Success",
        };
        return SIGNUP_E_DATA;
    },
    SIGNUP_F: (data) => {
        const SIGNUP_F_DATA = {
            event: "Click",
            eventCategory: "Sign Up",
            eventAction: data.checkoutFlow ? "Checkout Signup" : "Direct Signup",
            eventLabel: "OTP Error",
            dimension8: data.msg,
        };
        return SIGNUP_F_DATA;
    },

    LOGOUT: (data) => {
        storage.removeItem("customer_email");
        const LOGOUT_DATA = {
            event: "Click_logout",
            eventCategory: "My Profile",
            eventAction: "Click",
            eventLabel: "Logout",
        };
        return LOGOUT_DATA;
    },
    LOGIN_FAILED: (data) => {
        const eventData = {
            category: "Login",
            action: data.checkoutFlow ? "Checkout Login" : "Direct Login",
            label: "LoginFailed",
        };
        const result = generalEventData(eventData);
        result.errorMessage = data.message;
        return result;
    },

    LOGIN_B: (data) => {
        data.email
            ? storage.setItem("customer_email", data.email)
            : storage.setItem("customer_email", `${data.mobile}@dentalkart.user`);
        const LOGIN_B_DATA = {
            event: "Click",
            customer_id:data.c_id,
            customer_id: data.email ? window.btoa(data.email) : window.btoa(`${data.mobile}@dentalkart.user`),
            email: data.email && data.email,
            mobile: data.mobile && data.mobile,
            virtualPageURL: data.landingUrl,
            virtualPageTitle: data.title,
            eventCategory: "Login",
            eventAction: data.checkoutFlow ? "Checkout Login" : "Direct Login",
            eventLabel: "Login Success",
        };
        return LOGIN_B_DATA;
    },
    LOGIN_C: (data) => {
        const LOGIN_C_DATA = {
            event: "Click",
            eventCategory: "Login",
            eventAction: data.checkoutFlow ? "Checkout Login" : "Direct Login",
            eventLabel: "Login Error",
            dimension8: data.msg,
        };
        return LOGIN_C_DATA;
    },
};

export default AuthenticationEvents;
