import { gql } from '@apollo/client';

const GET_PDR_DETAILS = gql`
    query getList($hashKey: String!){
        getPDRList(hashKey: $hashKey){
            questions{
                id
                label
            }
            name
            expire
        }
    }
`;

export default GET_PDR_DETAILS;
