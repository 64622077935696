import React, { useState, Fragment, useContext, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Mobile, Desktop } from '@config/dentalkart.environment';
import DesktopHeader from './desktop';
import InfoHeader from './infoHeader';
import Style from './header.module.scss';
import TopSection from './mobile/topSection';
import BottomSection from './mobile/bottomSection';
import BackHeader from './mobile/backHeader';
import AppContext from '@helpers/appContext';
import isDeepEqual from 'fast-deep-equal/react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import cookie from 'react-cookies';
import CountryModal from './desktop/countryModal';
import { useLocation } from 'react-router-dom';

const Header = (props) => {
    const location = useLocation();
    const country_id = cookie.load('country_id');
    const country = cookie.load('country')
    const [state, setState] = useState({
        configuration: props.configuration,
        balance:0,
        open: false
    });

    if(!isDeepEqual(state.configuration, props.configuration)) {
        setState({
            configuration: props.configuration
        });
    }

    const headerRef = useRef(props.configuration);

    if (!isDeepEqual(headerRef.current, props.configuration)) {
        headerRef.current = props.configuration
    }

    useEffect(() => {
        setState({
            configuration: props.configuration, 
            balance:0,
            open: false
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current])
    
    const {setSearchValue}= props

    const { globalObject, isLoggedIn, history,setIsAddressModalOpen} = useContext(AppContext);

    const { configuration, balance } = state;

    const { params: { topSection, bottomSection, backHeader }, icons } = configuration;
    const { routes } =
      useContext(AppContext);
    const handleOpenAddressModal = () => {
      setIsAddressModalOpen(true);
    };
    const [IsCountryModalOpen, setIsCountryModalOpen] = useState(!!cookie.load("country_id"));
    const handleCloseCountryModal = () => {
        const expires = new Date();
        expires.setDate(200);
      setIsCountryModalOpen(true);
      cookie.save("country_id", "IN", { path: "/" , expires});
      cookie.save("country", "India", { path: "/" });
    };
    return (
        <Fragment>
            <Desktop>
                <header className={Style.app_header}>
                    <InfoHeader />
                    <DesktopHeader 
                    IsCountryModalOpen={IsCountryModalOpen} 
                    setIsCountryModalOpen={setIsCountryModalOpen}
                    handleCloseCountryModal={handleCloseCountryModal}
                    walletMoney={balance} {...props} />
                </header>
            </Desktop>
            <Mobile>
                <InfoHeader />
                <header
                    className={`${Style.app_header} ${backHeader ? Style.app_back_header : ''} ${bottomSection ? Style.app_home_header : ''}`}
                >
                    {topSection ?
                        <TopSection
                            drawerLeft="left"
                            history={history}
                            globalObject={globalObject}
                            icons={icons}
                            cartCount={globalObject.state.cartCount}
                        /> : null
                    }
                    {bottomSection ? 
                        <BottomSection
                            icons={icons}
                        /> : null
                    }
                    {backHeader ?
                        <BackHeader
                            history={history}
                            heading={configuration.heading}
                            setSearchValue={setSearchValue}
                            cartCount={globalObject.state.cartCount}
                            icons={icons}
                            isLoggedIn={isLoggedIn}
                            globalObject={globalObject}
                            /> : null
                    }
                  
                    <div className={Style.deliver_country}>
                        <LocationOnOutlinedIcon className={Style.location_icon} fontSize={"small"}/>
                        <p className={Style.address_text}>Deliver To {" "}
                        {country ? country : "India"}{" "}
                        </p>
                        <div className={Style.country_wrapper}>
                        {country_id ? (
                        <div className={Style.country} onClick={location.pathname !== "/payment" ? handleOpenAddressModal : null}>
                                <img
                                    src={`${
                                    routes.MEDIA_URL
                                    }flags/${country_id.toLowerCase()}.png`}
                                    alt="flag"
                                />
                            </div>
                            ) : (
                           
                            <div className={Style.country} onClick={location.pathname !== "/payment" ? handleOpenAddressModal : null}>
                                <img src={`${routes.MEDIA_URL}flags/in.png`} alt="flag" />
                            </div>
                            )}
                        </div>
                    </div> 
                    </header>
                    {/* <div className={Style.country_modal_wrapper}>
                        <CountryModal 
                        IsCountryModalOpen={IsCountryModalOpen} 
                        setIsCountryModalOpen={setIsCountryModalOpen}
                        handleCloseCountryModal={handleCloseCountryModal}
                        setIsAddressModalOpen={setIsAddressModalOpen} />
                    </div> */}
            </Mobile>
        
        </Fragment>
    );

}

Header.propTypes = {
    drawerLeft: PropTypes.oneOf(['left', '']),
    configuration: PropTypes.object.isRequired
};

Header.defaultProps = {
    drawerLeft: 'left'
}

export default Header;
