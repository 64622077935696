import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ShopByCategoryIcon from '@material-ui/icons/Ballot';
import MyOrdersIcon from '@material-ui/icons/MoveToInbox';
import MyRewardsIcon from '@material-ui/icons/MonetizationOn';
import MyAddressIcon from '@material-ui/icons/RecentActors';
import MyWishlistIcon from '@material-ui/icons/FavoriteBorder';
import MyAccountIcon from '@material-ui/icons/AccountBox';
import MyMembershipIcon from "@material-ui/icons/CardMembership";
import HelpCenterIcon from '@material-ui/icons/LiveHelp';
import Language from '@material-ui/icons/Language';
import cookie from 'react-cookies';

const country_id = cookie.load('country_id');


const drawerList = [
    { title: 'Home', icon: <HomeIcon />, path: 'GO_TO_HOME',visible:true },
    { title: 'Shop By Category', icon: <ShopByCategoryIcon />, path: 'GO_TO_NAVIGATION', visible:true },
    { title: 'Reward Zone', icon: <MyRewardsIcon />, path: 'GO_TO_KNOW_REWARDS' , visible:(country_id==='IN')},
    { title: 'My Orders', icon: <MyOrdersIcon />, path: 'GO_TO_ORDERS_LIST', visible:true },
    { title: 'Buy Again', icon: <MyOrdersIcon />, path: 'GO_TO_BUY_FROM_PAST_ORDERS', visible:true },
    { title: 'My Rewards', icon: <MyRewardsIcon />, path: 'GO_TO_REWARDS', visible:(country_id==='IN') },
    { title: "My Membership", icon: <MyMembershipIcon />, path: "GO_TO_MY_MEMBERSHIP", visible: true },
    { title: 'My Addresses', icon: <MyAddressIcon />, path: 'GO_TO_ADDRESSES', visible:true },
    { title: 'My Wishlist', icon: <MyWishlistIcon />, path: 'GO_TO_WISHLIST' ,visible:true},
    { title: 'My Coupons', icon: <MyAddressIcon />, path: 'GO_TO_CUSTOMER_COUPON_LIST', visible:true },
    { title: 'My Account', icon: <MyAccountIcon />, path: 'GO_TO_ACCOUNT', visible:true },
    { title: 'Help Center', icon: <HelpCenterIcon />, path: 'GO_TO_HELP_CENTER' ,visible:true},
    { title: 'Change Country', icon: <Language />, path: 'GO_TO_SELECT_COUNTRY' , image: true,visible:false}
]

export default drawerList;
