import { gql } from '@apollo/client';

const CREATE_RETURN_REQUEST = gql`
    mutation returnRequest($items:[ReturnItemsInput], $remarks: String, $order_id: String! $attachments:[String],$description:String!,$payment_method:String,$source:String){
        returnRequest(
            items: $items
            remarks:$remarks
            order_id:$order_id
            attachments: $attachments
            description:$description
            payment_method:$payment_method
            source:$source
        ){
            order_id

        }
    }
`;

export default CREATE_RETURN_REQUEST;
