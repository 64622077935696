import {generalEventData} from './helper.js';

const BrandPageEvents = {
    BRAND_SELECTED_BRAND_PAGE: (data) => {
        const eventData = {
            category:"Brands",
            action:"BrandSelectedBrandPage",
            label:data.name
        }
        return generalEventData(eventData);
    },
    BRAND_ALPHABET: (data) => {
        const eventData = {
            category:"Brands",
            action:"AlphabeticalNavigation",
            label:data.key
        }
        return generalEventData(eventData);
    },
    BRAND_SEARCH: () => {
        const eventData = {
            category:"Brands",
            action:"Search",
            label:"Click"
        }
        return generalEventData(eventData);
    },
    BRAND_PROMOTION_BANNER: (data) => {
        const eventData = {
            category:"Brands",
            action:"BrandCarousel",
            label:`${data.title} | ${data.position}`
        }
        return generalEventData(eventData);
    },

}
export default BrandPageEvents;
