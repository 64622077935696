import { gql } from '@apollo/client';

const PDP_HOMEPAGE_CAROUSEL = gql`
    {
        gethomepagecarousel{
		    heading
		    heading_url
		    view
		    sku
		}

    }
`;
export default PDP_HOMEPAGE_CAROUSEL;
