import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import Style from './backHeader.module.scss';
import {DentalkartConsumer} from '@helpers/appContext';
import SearchBar from '@screens/search/modules/searchBar';
import NotificationsIcon from '@material-ui/icons/Notifications';

library.add({faUserMd});

const BackHeader = (props) => {
    const [state, setState] = useState({
        heading: props.heading
    })

    useEffect(() => {
        setState({ heading: props.heading})
    }, [props.heading ])


    const { globalObject,
        history,
        icons: { logo, cart, search, reward, currency, auth, bell , algoliaSearch},
        cartCount, isLoggedIn
    } = props;

    const { heading } = state;

    return(
        <DentalkartConsumer>
            {({routes}) => <div className={Style.back_header_wrapper}>
                <IconButton className={Style.back_button} onClick={()=>history.goBack()}><ArrowBackIcon /></IconButton>
                {algoliaSearch && <div className={Style.search_wrapper}>
                    <SearchBar setSearchValue={props.setSearchValue} customSearch={props.customSearch}/></div>}
                {logo ?
                    <Link to={routes.GO_TO_HOME} >
                        <IconButton className={Style.logo_wrapper}>
                            <img src="/dentalkart.svg" alt="dentalkart-logo" />
                        </IconButton>
                    </Link> : null
                }
                <div className={Style.heading_wrapper}>
                    <div className={Style.heading}>{heading}</div>
                </div>
                {cart ?
                    <Link to={routes.GO_TO_CART}>
                        <IconButton className={Style.cart_icon_button}>
                            <Badge className={Style.cart_count} badgeContent={cartCount} showZero color="primary">
                                <ShoppingCartIcon />
                            </Badge>
                        </IconButton>
                    </Link> : null
                }
                {search ?
                    <IconButton
                        className={Style.search_icon}
                        onClick={()=>
                            history.push({pathname:routes.GO_TO_SEARCH})
                        }
                    >
                        <SearchIcon />
                    </IconButton>
                        :
                    null
                }
                {bell && <div className={Style.notification_icon} id="notification">
                    <NotificationsIcon />
                </div>}
                {reward ?
                    <div className={Style.reward_icon}>
                        <img className={Style.icon} src="https://www.dentalkart.com/media/mw_rewardpoint/default/Final-design-rewards_4.png" alt="reward" />
                    </div> : null
                }
                {auth && !isLoggedIn ?
                    <div className={Style.auth_icon} onClick={() => globalObject.showAuthenticationModal()}>
                        <FontAwesomeIcon icon="user-md" />
                    </div> : null
                }
                {currency ?
                    <div className={Style.currency_icon}>
                        <img className={Style.icon} src="https://s3.ap-south-1.amazonaws.com/dentalkart-media/Header/in.png" alt="flag" />
                    </div> : null
                }
            </div>}
        </DentalkartConsumer>
    );
}

BackHeader.propTypes = {
    heading: PropTypes.string.isRequired
};

BackHeader.defaultProps = {
    heading: 'Dentalkart'
}

export default BackHeader;
