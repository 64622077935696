import { gql } from '@apollo/client';

const GET_NAVIGATION_DESKTOP = gql`
    {
        categoryList {
            children{
                name
                position
                id
                url_path
                is_anchor
                include_in_menu
                children{
                    name
                    position
                    include_in_menu
                    id
                    url_path
                    is_anchor
                }
            }
        }
    }
`;

export default GET_NAVIGATION_DESKTOP;