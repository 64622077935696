import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";
import { productData, generalEventData, wishlistEvent, ga4Items } from "./helper.js";
// import getAlgoliaObj from "@helpers/getAlgoliaObj";

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
};

const ProductPageEvents = {
    PRODUCT_PAGE_LOAD: (data) => {
        // const algoliaObj = getAlgoliaObj(data);
        const PRODUCT_PAGE_LOAD_DATA = {
            ecomm_pagetype: "product",
            ecomm_pcat: [...data.categories],
            ecomm_prodid: [data.sku],
            ecomm_pname: [data.name],
            ecomm_pval: data.price.maximalPrice.amount.value.toFixed(2),
            dimension7: getEncryptedEmail(),
            // algolia: algoliaObj,
        };
        return PRODUCT_PAGE_LOAD_DATA;
    },

    PRODUCT_DETAIL: (data) => {
        // console.log(data);
        if (data.items) {
            const childProducts = [];
            let ga4_products = [];
            let ga4Data = {
                event: "view_item",
                ecommerce:{}
            };

            data.items.map((item,index) => {
                childProducts.push(productData(item.product));
                ga4_products.push(ga4Items(item,'product_detail',index));
            })
            const PRODUCT_DETAIL_DATA = {
                event: "productdetail",
                ecommerce: {
                    detail: {
                        actionField: { list: "" },
                        products: childProducts,
                        adsProduct: [productData(data.parentProduct)],
                    },
                },
                ecomm_pagetype: "product",
                ecomm_pcat: [...data.parentProduct.categories],
                ecomm_prodid: [data.parentProduct.sku],
                ecomm_pname: [data.parentProduct.name],
                ecomm_pval: data.parentProduct.price.minimalPrice.amount.value.toFixed(2),
            };
            ga4Data.ecommerce.items = [...ga4_products];
            return {ga3:PRODUCT_DETAIL_DATA, ga4:ga4Data};
        } else {
            let ga4Data = {
                event: "view_item",
                ecommerce:{}
            };

            const PRODUCT_DETAIL_DATA = {
                event: "productdetail",
                ecommerce: {
                    detail: {
                        actionField: { list: "" },
                        products: [productData(data)],
                        adsProduct: [productData(data)],
                    },
                },
                ecomm_pagetype: "product",
                ecomm_pcat: [...data.categories],
                ecomm_prodid: [data.sku],
                ecomm_pname: [data.name],
                ecomm_pval: data.price.maximalPrice.amount.value.toFixed(2),
            };
            ga4Data.ecommerce.items = [ga4Items(data,'product_detail')];
            return {ga3:PRODUCT_DETAIL_DATA, ga4:ga4Data};

        }
    },

    INCREASE_QUANTITY: (data) => {
        const INCREASE_QUANTITY_DATA = {
            event: "Click",
            eventCategory: "checkout",
            eventAction: `Quantity Increased | ${data.qty - 1} | ${data.qty}`,
            eventLabel: data.sku,
            quantity: `${data.qty - 1} | ${data.qty}`,
            dimension6: data.name,
            dimension7: getEncryptedEmail(),
        };
        return INCREASE_QUANTITY_DATA;
    },
    DECREASE_QUANTITY: (data) => {
        const DECREASE_QUANTITY_DATA = {
            event: "Click",
            eventCategory: "checkout",
            eventAction: `Quantity Decreased | ${data.qty + 1} | ${data.qty}`,
            eventLabel: data.sku,
            quantity: `${data.qty + 1} | ${data.qty}`,
            dimension6: data.name,
            dimension7: getEncryptedEmail(),
        };
        return DECREASE_QUANTITY_DATA;
    },
    ADD_TO_WISHLIST_PDP: (data) => {
        let ga4Data = {
            event: "add_to_wishlist",
            ecommerce: {
                currency: data?.price?.minimalPrice?.amount?.currency || data?.price?.regularPrice?.amount?.currency,
                value: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
                items:[ga4Items(data,'product_page')]
            }
        }
        return {ga3:wishlistEvent("Click", "ProductDetailPage", data),ga4:ga4Data}

    },
    PRODUCT_REVIEW: (data) => {
        const eventData = {
            category: "RateProduct",
            action: data.name,
            label: "Initiate",
        };
        return generalEventData(eventData);
    },
    SUBMIT_PRODUCT_REVIEW: (data) => {
        const eventData = {
            category: "RateProduct",
            action: data.name,
            label: data.rating,
        };
        return generalEventData(eventData);
    },
    PRODUCT_REVIEW_ERROR: (data) => {
        const eventData = {
            category: "RateProduct",
            action: data.name,
            label: "Error",
        };
        return generalEventData(eventData);
    },

    ADD_TO_CART_BUY_NOW: (data) => {
        // const algoliaObj = getAlgoliaObj(data);
        if (data.options) {
            data.quantity = 0;
            let _totalAmount = 0;
            let _totalQuantity = 0;
            const _product = [];
            data.options.map((op) => {
                op.data.image = {};
                op.data.image.url = data.image.url;
                let prod = productData(op.data);
                _totalAmount += prod.totalPrice;
                _totalQuantity += op.data.quantity;
                data.quantity += op.data.quantity;
                data.revenue += prod.totalPrice;
                _product.push(prod);
                return null;
            });
            const ADD_TO_CART_DATA = {
                event: "BuyNow",
                pageName: "Detnalkart|Checkout Step1|Buy Now",
                ecommerce: {
                    currencyCode: cookie.load("currency_code"),
                    checkout: {
                        actionField: { step: 1, option: "Buy Now" },
                        products: [..._product],
                        adsProduct: [productData(data)],
                    },
                    // algolia: algoliaObj,
                },
                totalAmount: _totalAmount,
                totalQuantity: _totalQuantity,
            };
            return ADD_TO_CART_DATA;
        } else {
            data.quantity = data.qty;
            const ADD_TO_CART_DATA = {
                event: "BuyNow",
                pageName: "Detnalkart|Checkout Step1|Buy Now",
                ecommerce: {
                    currencyCode: cookie.load("currency_code"),
                    checkout: {
                        actionField: { step: 1, option: "Buy Now" },
                        products: [productData(data)],
                        adsProduct: [productData(data)],
                    },
                    // algolia: algoliaObj,
                },
            };
            return ADD_TO_CART_DATA;
        }
    },

    ADD_TO_CART: (data) => {
        // const algoliaObj = getAlgoliaObj(data);
        data.revenue = 0;
        let ga4Data = {
            event: "add_to_cart",
            ecommerce:{}
        };

        if (data.options) {
            data.quantity = 0;
            let _totalAmount = 0;
            let _totalQuantity = 0;
            const _product = [];
            let ga4_products = [];
            data.options.map((op,index) => {
                let prod = productData(op.data);
                ga4_products.push(op,data,'add_to_cart',index);
                _totalAmount += prod.totalPrice;
                _totalQuantity += op.data.quantity;
                data.quantity += op.data.quantity;
                data.revenue += prod.totalPrice;
                _product.push(prod);
                return null;
            });
            const ADD_TO_CART_DATA = {
                event: "addToCart",
                ecommerce: {
                    currencyCode: cookie.load("currency_code"),
                    add: {
                        actionField: {
                            list: "Product Detail Page",
                        },
                        products: [..._product],
                        adsProduct: [productData(data)],
                        items:[{
                        item_id:data.sku,
                        item_name:data.name,
                        price:data?.price?.minimalPrice?.amount?.value || data?.price?.regularPrice?.amount?.value,
                        quantity:data.quantity ? data.quantity : 1,
                        item_brand:data.manufacturer ? data.manufacturer : "Brand not assigned",
                        item_category:"",
                        item_variant:"",
                        item_list_name:"",
                        item_list_id:"",
                        index:1
                    }]

                    },
                    // algolia: algoliaObj,
                },
                totalAmount: _totalAmount,
                totalQuantity: _totalQuantity,
            };
            ga4Data.ecommerce.items = [...ga4_products];
            return {ga3:ADD_TO_CART_DATA, ga4:ga4Data};
        } else {
            data.quantity = data.qty;
            const ADD_TO_CART_DATA = {
                event: "addToCart",
                ecommerce: {
                    currencyCode: cookie.load("currency_code"),
                    add: {
                        actionField: {
                            list: "Product Detail Page",
                        },
                        products: [productData(data)],
                        adsProduct: [productData(data)],
                        items:[{
                        item_id:data.sku,
                        item_name:data.name,
                        price:data?.price?.minimalPrice?.amount?.value || data?.prices?.regularPrice?.amount?.value,
                        quantity:data.quantity ? data.quantity : 1,
                        item_brand:data.manufacturer ? data.manufacturer : "Brand not assigned",
                        item_category:"",
                        item_variant:"",
                        item_list_name:"",
                        item_list_id:"",
                        index:1
                    }]

                    },
                    // algolia: algoliaObj,
                },
                totalAmount:(data?.price?.minimalPrice?.amount?.value || data?.prices?.regularPrice?.amount?.value)* (data.quantity || 1),
                totalQuantity:data.quantity || 1
            };
            ga4Data.ecommerce.items = [ga4Items(data,'add_to_cart')];
            return {ga3:ADD_TO_CART_DATA, ga4:ga4Data};

        }
    },

    ADD_TO_CART_BOUGHT_TOGETHER_PRODUCT: (data) => {
        const ADD_TO_CART_BOUGHT_TOGETHER_PRODUCT_DATA = {
            event: "addToCart",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                add: {
                    actionField: {
                        list: "Hot Selling",
                    }, // 'add' actionFieldObject measures.
                    products: [productData(data)],
                    adsProduct: [productData(data)],
                },
            },
        };
        return ADD_TO_CART_BOUGHT_TOGETHER_PRODUCT_DATA;
    },
    ADD_TO_CART_RELATED_PRODUCT: (data) => {
        const ADD_TO_CART_RELATED_PRODUCT_DATA = {
            event: "addToCart",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                add: {
                    actionField: {
                        list: "Related Product",
                    }, // 'add' actionFieldObject measures.
                    products: [productData(data)],
                    adsProduct: [productData(data)],
                },
            },
        };
        return ADD_TO_CART_RELATED_PRODUCT_DATA;
    },

    VIEW_PLANS: () => {
        const VIEW_PLANS_DATA = {
            event: "Click",
            eventCategory: "ProductPage",
            eventAction: "Click",
            eventLabel: "ViewPlans",
            dimension7: getEncryptedEmail(),
        };
        return VIEW_PLANS_DATA;
    },
};
export default ProductPageEvents;
