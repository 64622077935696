import { gql } from '@apollo/client';

const UPDATE_INFO = gql`
    mutation updateInfo(
        $firstname: String,
        $lastname: String,
        $email: String,
        $taxvat: String,
        $is_subscribed: Boolean,
    ){
        updateCustomer(input: {
            firstname: $firstname,
            lastname: $lastname,
            email: $email,
            taxvat: $taxvat,
            is_subscribed: $is_subscribed
        }){
            customer {
                id
                firstname
                lastname
                email
                mobilenumber
                confirmation {
                    email_confirm
                    mobile_confirm
                }
                taxvat
                customer_unique_id
                addresses {
                    default_shipping
                    telephone
                }
            }

        }
    }
`;
export default UPDATE_INFO;
