const isRouteRedirect = ({screenType, isLoggedIn, newOrderInit}) => {
    const redirect = {
        'protected': isLoggedIn ? false : true,
        'public':false,
        'auth': isLoggedIn ? true : false,
        'payment': newOrderInit ? false : true,
        'order_success': false,
        'default': false
    }
    return redirect[screenType] || redirect['default'];
}
export default isRouteRedirect;
