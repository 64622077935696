import { gql } from '@apollo/client';

const GET_CANCELLATION_REASONS = gql`
query{
  getCancelReasons {
    id
    reason
  }
}
`

export default GET_CANCELLATION_REASONS;