import { gql } from '@apollo/client';

const PRODUCT_DATA_QUERY = gql`
    query productData($id : [Int], $sku : [String]){
      productData(id : $id , sku: $sku){
          average_rating
          id
          image_url
          manufacturer
          name
          rating_count
          reward_point_product
          short_description
          sku
          special_price
          thumbnail_url
          url_key
          price {
            minimalPrice {
                amount {
                    currency_symbol
                    value
                    currency
                }
                adjustments
            }
            maximalPrice {
                amount {
                    currency_symbol
                    value
                    currency
                }
                adjustments
            }
            regularPrice {
                amount {
                    currency_symbol
                    value
                    currency
                }
                adjustments
            }
          }
        type_id
      }
    }
`;

export default PRODUCT_DATA_QUERY;
