import { gql } from "@apollo/client";

const productDataFragment = gql`
  fragment productDataFragment on ProductData {
    pd_expiry_date
    dentalkart_custom_fee
    dispatch_days
    reward_point_product
    meta_title
    meta_keyword
    meta_description
    price {
      minimalPrice {
        amount {
          currency_symbol
          value
          currency
        }
        adjustments
      }
      maximalPrice {
        amount {
          currency_symbol
          value
          currency
        }
        adjustments
      }
      regularPrice {
        amount {
          currency_symbol
          value
          currency
        }
        adjustments
      }
    }
    tier_prices {
      customer_group_id
      qty
      value
      percentage_value
      website_id
    }
    media_gallery_entries {
      id
      media_type
      label
      position
      disabled
      types
      file
      video_content {
        media_type
        video_provider
        video_url
        video_title
        video_description
        video_metadata
      }
    }
    categories {
        name
        level
        position
        url_path
    }

  }
`;

export default productDataFragment;
