import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { GET_AVAILABLE_PAYMENT_METHOD } from "@graphql";
import { appSyncLiveCLient } from "@apolloClient";
import BrowserPersistence from "@helpers/browser_persistence";
import AppContext from "@helpers/appContext";
import Icon from "@helpers/icons";
import { isDesktop } from "@config/dentalkart.environment";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Style from "./checkCod.module.scss";
import fireEvents from "@helpers/event_data_layer";
import cookie from 'react-cookies';
import cartTotalItemsWeight from '@helpers/getCartItemsWeight';

const storage = new BrowserPersistence();

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            fontSize: 11,
        },
        "& .MuiOutlinedInput-input": {
            "height": isDesktop ? 30 : 27,
            "paddingTop": 5,
            "maxWidth": isDesktop ? 239 : "100%",
            "borderRadius": "1px",
            "padding": "4px 8px",
            "&::placeholder": {
                fontSize: 13,
                color: "#A8A8A8",
            },
        },
        "& .MuiOutlinedInput-root ": {
            position: "relative",
            borderRadius: "4px",
        },
        "& label.Mui-focused": {
            color: "#F88F3E",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F88F3E",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#DBDBDB",
            },
            "&:hover fieldset": {
                borderColor: "#F88F3E",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#F88F3E",
            },
        },
        "& .MuiInputLabel-outlined": {
            zIndex: 1,
            transform: "translate(14px, 12px) scale(1)",
            pointerEvents: "none",
            color: "#DBDBDB",
        },
        "& .MuiInputLabel-shrink": {
            zIndex: 1,
            pointerEvents: "none",
            transform: "translate(14px, -6px) scale(0.75)",
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    customWidth: {
        maxWidth: 280,
        background: "#FBFBFB",
        color: "#515151",
        padding: "13px",
        lineHeight: 1.5,
        fontSize: "10px",
        marginTop: "3px",
        // eslint-disable-next-line no-sequences
        boxShadow: theme.shadows[(1, 2, 3)],
    },
    noMaxWidth: {
        maxWidth: "none",
    },
}));

const CheckCod = async (pincode, globalObject, client, isLoggedIn,checkCodPayload) => {
    try {
        const country_id = cookie.load('country_id') || "IN";
        const {data} = await appSyncLiveCLient.query({
            query: GET_AVAILABLE_PAYMENT_METHOD,
            fetchPolicy: 'network-only',
            variables: {pincode: pincode, country_code:country_id,  ...checkCodPayload }});
        if(data && data.GetAvailableMethodsV2){
            const resulting_array = data.GetAvailableMethodsV2?.checkcod?.message_arr ?? '';
            const isCodAvailable = data?.GetAvailableMethodsV2?.checkcod?.cod_available ?? '';
            const isServiceAvailable = data.GetAvailableMethodsV2?.checkcod?.service_available ?? '';

            return{
                resulting_array,
                isCodAvailable,
                isServiceAvailable,
                checkCodData:data.GetAvailableMethodsV2
            }

        }
    } catch (e) {
        globalObject.handleError(e);
    }
};

const CheckCashOnDelivery = ({ cartTotals, isCart, cartData, productData,setIsAvailable }) => {
    const pincodeState = storage.getItem("pincodeState") || "";
    const { globalObject, client, isLoggedIn, pincode, setPincode  } = useContext(AppContext);
    const inputRef = useRef(null);
    const classes = useStyles();
    const [checkCodPayload, setCheckCodPayload] = useState({
        is_cod:true,
        total_weight:null,
        total_amount:null
    });
    const country_id =  cookie.load('country_id') || "IN";
    const [pincodeStateData, setPincodeState] = useState({
        pincodeValue: "",
        pincodeError: "",
        successPincode: "",
        loading: false,
        state: pincodeState,
        showDeliveryInfo: false,
        pincodeMessageArray: [],
        codTextField: "",
        apiResponse: "",
    });

    const {
        codTextField,
        apiResponse,
        pincodeValue,
        pincodeError,
        successPincode,
        loading,
        showDeliveryInfo,
        pincodeMessageArray,
    } = pincodeStateData;

    const handleShowDelivery = () => {
        setPincodeState((prevState) => ({ ...prevState, showDeliveryInfo: !pincodeStateData.showDeliveryInfo }));
    };

    const handlePincodeFieldChange = (e) => {
         if(country_id==="IN"){
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
         }
        setPincodeState((prevState) => ({
            ...prevState,
            pincodeValue: e.target.value,
            codTextField: e.target.value,
            pincodeError: "",
        }));
    };

    const handleCodSubmit = async () => {
        setPincodeState((prevState) => ({ ...prevState, showDeliveryInfo: false }));
        inputRef.current && inputRef.current.focus();
        if (pincodeValue.length === 6 || country_id!=="IN") {
            setPincodeState((prevState) => ({ ...prevState, codTextField: "", loading: true }));
            const COdInfo = await CheckCod(pincodeValue, globalObject, client, isLoggedIn,checkCodPayload);
            // fireEvents("GTM", "CHECK_COD", { ...COdInfo.result, pincodeValue });
            setPincode(pincodeValue);
            setPincodeState((prevState) => ({ ...prevState, codTextField: "", loading: false }));

            COdInfo && setPincodeState((prevState) => ({ ...prevState, apiResponse: COdInfo.checkCodData }));
            if(COdInfo){
                setPincodeState(prevState => ({
                    ...prevState,
                    successPincode:pincodeValue,
                    pincodeMessageArray:COdInfo?.resulting_array,
                    pincodeValue: "",
                }))
            }
        } else if (pincodeValue.length === 0) {
            setPincodeState((prevState) => ({ ...prevState, pincodeError: "Enter a 6 digit pincode!" }));
        } else if (pincodeValue.length > 0 && pincodeValue.length < 6) {
            setPincodeState((prevState) => ({ ...prevState, pincodeError: "Entered pincode is incomplete!" }));
        }
    };

    const GetCodInfo = async (pincode) => {
        const COdInfo = await CheckCod(pincode, globalObject, client, isLoggedIn,checkCodPayload);
        COdInfo && setPincodeState((prevState) => ({ ...prevState, apiResponse: COdInfo.checkCodData }));
        if(COdInfo){
            setPincodeState(prevState => ({
                ...prevState,
                successPincode:pincode,
                pincodeMessageArray:COdInfo?.resulting_array
            }))
            isCart &&setIsAvailable(COdInfo.isServiceAvailable);
        }else{
            console.log('error');
        }
    };

    useEffect(() => {
        if(isCart && !productData){
            setCheckCodPayload(prev => ({...prev, total_amount:parseInt(cartData?.prices?.grand_total?.value)}))
            let totalWeight = cartTotalItemsWeight(cartData?.items);
            let isCodTemp = true;
            cartData?.items?.map(item=>{
                if(!item.product.is_cod) isCodTemp=false;
            })
            setCheckCodPayload(prev => ({...prev, total_weight:Number(totalWeight), is_cod: isCodTemp}));
        }
        else if(productData && !isCart){
            setCheckCodPayload(prev => ({...prev,is_cod: Number(productData.is_cod) ? true: false, total_weight:productData.weight === 0 ? 1:(productData.weight  ? productData.weight:0), total_amount:parseInt(productData?.price?.minimalPrice?.amount?.value) ?? parseInt(productData?.price?.regularPrice?.amount?.value)}));
        }
        else if(!productData && !isCart){
            setCheckCodPayload(prev => ({...prev, is_cod: true, total_weight: 1, total_amount: 1})); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(isCart)
        {
        if(checkCodPayload.total_weight && checkCodPayload.total_amount)
            pincode ? GetCodInfo(pincode) : (country_id === "IN" ? GetCodInfo("110005") : GetCodInfo("123456"))
        }
        else if (productData)
        pincode ? GetCodInfo(pincode) : (country_id === "IN" ? GetCodInfo("110005") : GetCodInfo("123456"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pincode, checkCodPayload]);

    const [openCodInfoModal, setOpenCOdInfoModal] = React.useState(false);

    const handleCodInfoModalClose = () => {
        setOpenCOdInfoModal(false);
    };

    const codInfoText = () => {
        return (
            <p className={Style.cod_tooltip_text}>
                {
                    "Cash on Delivery availability feature enables a customer to check if cash payment option is available or not for a specific pincode. Cash payment feature availability is dependent on multiple factors. These factors include cart total, weight of the products, pincode, Individual items for shipping and many more."
                }
            </p>
        );
    };
    return (
        <div className={Style.root_wrapper}>
            <Fragment>
                {country_id === "IN" && 
                <div className={Style.cod_outer_wrapper}>
                    <div className={Style.cod_header_wrapper}>
                        <div className={Style.cod_text}>
                            {isLoggedIn ? `Service Availability` : ` Check Service Availability`}
                        </div>
                        <Dialog
                            onClose={handleCodInfoModalClose}
                            aria-labelledby="simple-dialog-title"
                            maxWidth={"sm"}
                            fullScreen={true}
                            fullWidth={true}
                            open={openCodInfoModal}
                            PaperProps={{
                                style: {
                                    position: "fixed",
                                    bottom: "0px",
                                    height: "210px",
                                },
                            }}
                        >
                            <div className={Style.tittle_wrapper}>
                                <span className={Style.heading_text}>Cash On Delivery Information</span>
                                <span className={Style.close_icon} onClick={handleCodInfoModalClose}>
                                    <Icon type={"material"} title={"Close"} />
                                </span>
                            </div>
                            <div className={Style.expiry_info_text_wrapper}>{codInfoText()}</div>
                        </Dialog>
                    </div>
                    {
                        <div className={Style.textfield_outer_wrapper}>
                            <div>
                                <TextField
                                    id="outlined-error-helper-text"
                                    label="6 Digit Pincode"
                                    variant="outlined"
                                    placeholder={
                                        isDesktop ? "Enter pincode to check availability." : "Enter 6 digit pincode"
                                    }
                                    type={"number"}
                                    onChange={(e) => handlePincodeFieldChange(e)}
                                    value={codTextField}
                                    className={classes.root}
                                    inputRef={inputRef}
                                />
                            </div>
                            <div className={Style.pincode_button_wrapper}>
                                <Button className={Style.pincode_check_button} onClick={() => handleCodSubmit()}>
                                    {loading ? <CircularProgress className={Style.button_prgress} /> : `Check`}
                                </Button>
                            </div>
                        </div>
                    }
                    {pincodeError && <p className={Style.helperText}>{pincodeError}</p>}
                </div>}
                { apiResponse?.checkcod?.message && (
                    <div className={Style.cod_availability_wrapper}>
                        <div className={Style.textfield_outer_wrapper}>
                            <div>
                                <div
                                    className={
                                        apiResponse.checkcod.service_available
                                            ? Style.tick_icon
                                            : Style.cancel_icon
                                    }
                                >
                                    <Icon
                                        type={"material"}
                                        title={apiResponse.checkcod.service_available ? "CheckCircle" : "Cancel"}
                                    />
                                </div>
                            </div>
                            {apiResponse?.checkcod?.message &&
                                <div>
                                    <p className={`${Style.availability_text} ${country_id !== "IN" && !apiResponse.checkcod.service_available ? Style.red_error : ""}`}>
                                        {apiResponse.checkcod.message}
                                        {country_id === "IN" && 
                                        (<>
                                        <span className={Style.region_text}>{` (${successPincode}`}</span>

                                        <span className={Style.region_text}>)</span>
                                        </>)
                                        }   
                                    </p>
                                </div>
                            }
                            {pincodeMessageArray.length > 0 && (
                                <div>
                                    <div className={Style.arrow_down} onClick={() => handleShowDelivery()}>
                                        <Icon
                                            type={"material"}
                                            title={!showDeliveryInfo ? "KeyboardArrowDown" : "KeyboardArrowUp"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {showDeliveryInfo && (
                            <div className={Style.error_list_wrapper}>
                                {pincodeMessageArray.length > 0 && (
                                    <ul className={Style.cod_info_text}>
                                        {pincodeMessageArray.map((info, index) => {
                                            return <li key={index}>{info}</li>;
                                        })}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Fragment>
        </div>
    );
};

export default CheckCashOnDelivery;
