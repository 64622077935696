import { gql } from '@apollo/client';

const GET_PRESIGNED_URL = gql`
query getPreSignedUrl($name : [String!]!){
    getPreSignedUrl(name: $name){
        file_name
        pre_signed_url
    }

}
`;
export default GET_PRESIGNED_URL;
