
import { HeaderConfiguration } from '@config/dentalkart.environment';

const generateHeaderConfiguration = (payload) => {
    let currentConf;
    if (HeaderConfiguration[payload.screen]){
        currentConf = HeaderConfiguration[payload.screen];
        currentConf.heading = payload?.heading ?? 'Dentalkart';
    }
    else {
        console.error('Wrong Screen name provided for header configuration');
        currentConf = HeaderConfiguration.default;
    }
    return currentConf;
}

export default generateHeaderConfiguration;
