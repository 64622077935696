import {gql} from '@apollo/client';

const GET_MY_MEMBERSHIP = gql`
  query {
    customerMembership {
      monetoryValue
      daysLeft
      currentPlan {
        duration
        price
        orderId
      }
      memberships {
        createdAt
        orderId
        productSku
        expiryDate
        isActive
        isExpired
        monetoryValue
      }
    }
  }
`;
export default GET_MY_MEMBERSHIP;
