import gql from "graphql-tag";

const GET_RETURN = gql`
  query getReturn($order_id:String!) {
        getReturn(order_id: $order_id) {
            qty
            name
            sku
            image
            awb_number
            created_at
            order_id
            reason
            tracking_url
            remarks{
              user
              comment
              created_at
            }
            status
            action
            return_cancellable
            transporter
                }
  }
`;

export default GET_RETURN;
