import React, { useState } from "react";
import Style from "./desktopHeader.module.scss";
import cookie from "react-cookies";
import BrowserPersistence from "@helpers/browser_persistence";
import { Dialog } from "@mui/material";
import { Mobile, Desktop } from "@config/dentalkart.environment";
const storage = new BrowserPersistence();

function CountryModal({
  IsCountryModalOpen,
  setIsAddressModalOpen,
  handleCloseCountryModal,
}) {
  const IsCountryId = cookie.load("country_id");
  const handleOpenAddressModal = () => {
    setIsAddressModalOpen(true);
  };
  return (
    <>
      <Desktop>
        {!IsCountryModalOpen && !IsCountryId && (
          <div className={Style.country_modal}>
            <div className={Style.country_modal_text}>
              <h5>
                We're showing product serviceability for {" "}
                <span className={Style.bold_text}>IN</span>. To check product serviceability 
                  to a different country, change your delivery address.
              </h5>
            </div>
            <div className={Style.country_change_button}>
              <button
                className={Style.IN_button}
                onClick={handleCloseCountryModal}
              >
                Don't Change
              </button>
              <button
                className={Style.other_button}
                onClick={handleOpenAddressModal}
              >
                Change Address
              </button>
            </div>
          </div>
        )}
      </Desktop>
      <Mobile>
        {!IsCountryModalOpen && !IsCountryId && (
          <Dialog onClose={handleCloseCountryModal} 
                  open={!IsCountryModalOpen}>
            <div className={Style.country_modal}>
              <div className={Style.country_modal_text}>
                <h5>
                  We're showing product serviceability for {" "}
                  <span className={Style.bold_text}>IN</span>. To check product serviceability 
                  to a different country, change your delivery address.
                </h5>
              </div>
              <div className={Style.country_change_button}>
                <button
                  className={Style.IN_button}
                  onClick={handleCloseCountryModal}
                >
                  Don't Change
                </button>
                <button
                  className={Style.other_button}
                  onClick={handleOpenAddressModal}
                >
                  Change Address
                </button>
              </div>
            </div>
          </Dialog>
        )}
      </Mobile>
    </>
  );
}

export default CountryModal;
