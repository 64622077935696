import { gql } from '@apollo/client';

const ADD_PRODUCT_FEEDBACK = gql`
mutation (
    $product_id: String,
    $product_name: String,
    $product_sku: String,
    $product_price: String,
    $product_quality: String,
    $other_feedback: String
){
    addProductFeedback(input: {
        product_id: $product_id,
        product_name: $product_name,
        product_sku: $product_sku,
        product_price: $product_price,
        product_quality: $product_quality,
        other_feedback: $other_feedback
    }) {
      product_id
      product_name
      product_sku
      product_price
      product_quality
      other_feedback
    }
}`

export default ADD_PRODUCT_FEEDBACK;
  