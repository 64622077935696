import gql from "graphql-tag";

const MOVE_WISHLIST_PRODUCT = gql`
  mutation MoveProductWishlist(
    $wishlist_id: String!
    $product_ids: [Int!]
    $target_wishlist_id: String!
  ) {
    moveProductWishlist(
      wishlist_id: $wishlist_id
      product_ids: $product_ids
      target_wishlist_id: $target_wishlist_id
    ) {
      wishlist_id
      target_wishlist_id
    }
  }
`;

export default MOVE_WISHLIST_PRODUCT;
