import { gql } from '@apollo/client';

const GET_CAROUSEL_SKU = gql`
    query carauselBySku($sku : String){
        GetAttributesBySku(sku: $sku){
            crosssell{
                name
                sku
                special_price
                average_rating
                manufacturer
                rating_count
                thumbnail_url
                msrp
                meta_title
                meta_keyword
                meta_description
                categories{
                    name
                    level
                    position
                    url_path
                }
                is_in_stock
                url_key
                max_sale_qty
                image_url
                id
                type_id
                pd_expiry_date
                dispatch_days
                dentalkart_custom_fee
                short_description
                reward_point_product
                tier_prices{
                    qty
                    value
                    percentage_value
                }
                price{
                    regularPrice{
                        amount{
                            value
                            currency
                            currency_symbol
                        }
                    }
                    minimalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                    maximalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                }
            }

            upsell{
                name
                sku
                special_price
                is_in_stock
                max_sale_qty
                image_url
                url_key
                id
                type_id
                pd_expiry_date
                dispatch_days
                dentalkart_custom_fee
                short_description
                reward_point_product
                average_rating
                manufacturer
                rating_count
                thumbnail_url
                msrp
                meta_title
                meta_keyword
                meta_description
                categories{
                    name
                    level
                    position
                    url_path
                }
                tier_prices{
                    qty
                    value
                    percentage_value
                }
                price{
                    regularPrice{
                        amount{
                            value
                            currency
                            currency_symbol
                        }
                    }
                    minimalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                    maximalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                }
            }

            related{
                name
                sku
                special_price
                is_in_stock
                max_sale_qty
                image_url
                url_key
                id
                type_id
                pd_expiry_date
                dispatch_days
                dentalkart_custom_fee
                short_description
                reward_point_product
                average_rating
                manufacturer
                rating_count
                thumbnail_url
                msrp
                meta_title
                meta_keyword
                meta_description
                categories{
                    name
                    level
                    position
                    url_path
                }
                tier_prices{
                    qty
                    value
                    percentage_value
                }
                price{
                    regularPrice{
                        amount{
                            value
                            currency
                            currency_symbol
                        }
                    }
                    minimalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                    maximalPrice{
                        amount{
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
`;

export default GET_CAROUSEL_SKU;
