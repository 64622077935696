import {gql} from '@apollo/client';

const SET_SHIPPING_METHOD_ON_CART = gql`
mutation setShippingMethodsOnCart(
    $cart_id: String!
    $carrier_code: String!
    $method_code: String!
){
    setShippingMethodsOnCart(input: {
        cart_id:$cart_id
        shipping_methods: [
            {
                carrier_code: $carrier_code
                method_code: $method_code
            }
        ]
    }) {
        cart {
            id
        }
    }
}
`;
export default SET_SHIPPING_METHOD_ON_CART;
