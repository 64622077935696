import gql from "graphql-tag";

const GET_WISHLIST = gql`
  query GetWishlist($wishlist_ids: [String], $sharing_hash: [String]) {
    getWishlist(wishlist_ids: $wishlist_ids, sharing_hash: $sharing_hash) {
      hash_key
      products {
        product_id
        added_date
      }
      wishlist_id
      wishlist_name
      access_type
      default
    }
  }
`;

export default GET_WISHLIST;
