import {generalEventData} from './helper.js';

const HelpCenterEvents = {
    HELP_MENU: (data) => {
        const eventData = {
            category:"HelpCenter",
            action:"HelpMenu",
            label:data.category_name
        }
        return generalEventData(eventData);
    },
    FAQ_TOP_QUESTIONS: (data) => {
        const eventData = {
            category:"HelpCenter",
            action:"FrequentlyVisitedQuestions",
            label:data.ques
        }
        return generalEventData(eventData);
    },
    FAQ_CATEGORY: (data) => {
        const eventData = {
            category:"HelpCenter",
            action:data.category,
            label:data.ques
        }
        return generalEventData(eventData);
    },

}
export default HelpCenterEvents;
