import { gql } from '@apollo/client';
// eslint-disable-next-line no-unused-vars
import toSource from "@helpers/toSource";

const ADD_GROUP_PRODUCTS_TO_CART = (options) =>
  gql`
        mutation addSimpleProductsToCart($cart_id : String!){
            addSimpleProductsToCart(
                input: {
                  cart_id: $cart_id
                  cart_items : ${options.length > 0 ? options.toSource() : `[]`}
                }
              ) {
            cart {
              items {
                id
                brand_image
                product {
                  name
                  sku
                  small_image {
                    url
                  }
                  image {
                    url
                  }
                }
                quantity
              }
              total_quantity
              prices {
                grand_total {
                  value
                }
              }
            }
        }
   }`;
export default ADD_GROUP_PRODUCTS_TO_CART;
