import BrowserPersistence from "@helpers/browser_persistence";
import {generalEventData} from './helper.js';
const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const OrderPageEvents = {
    CANCEL_ORDER: (data) => {
        const CANCEL_ORDER_DATA = {
            event: "Click",
            eventCategory: "MyOrder",
            eventAction: "Cancel",
            eventLabel: data.increment_id,
            eventValue: data.grand_total,
            products: [...data.items],
            dimension7: getEncryptedEmail()
        };
        return CANCEL_ORDER_DATA;
    },
    TRACK_ORDER: (data) => {
        const eventData = {
            category:"MyOrder",
            action:"Track",
            label:data.increment_id,
        }
        let result = generalEventData(eventData);
        result.eventValue = data.grand_total;
        return result;
    },

    TRACK_ORDER_VIEW_DETAILS: (data) => {
        const eventData = {
            category:"MyOrder",
            action:"ViewDetailsTrack",
            label:data.increment_id,
        }
        let result = generalEventData(eventData);
        result.eventValue = data.grand_total;
        return result;
    },

    BUY_IT_AGAIN: (data) => {
        const eventData = {
            category:"MyOrder",
            action:"BuyItAgain",
            label:data
        }
        return generalEventData(eventData);
    },

    NEED_ORDER_HELP: (data) => {
        const eventData = {
            category:"MyOrder",
            action:"NeedHelp",
            label:data.increment_id,
        }
        let result = generalEventData(eventData);
        result.eventValue = data.grand_total;
        return result;
    },
}

export default OrderPageEvents;
