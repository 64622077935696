import { gql } from '@apollo/client';
import {BRANDS_FRAGMENT} from './fragments';

const GET_FEATURED_BRANDS=gql`
    query{
		getBrand(filter:{featured: 1}){
			...brandFields
	  	}
	}
	${BRANDS_FRAGMENT}
`;

export default GET_FEATURED_BRANDS;
