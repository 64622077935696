import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";
import {productData,generalEventData, wishlistEvent, ga4Items} from './helper.js';

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const CartPageEvents = {
    ADD_TO_CART_FROM_CART: (data) => {
        const ADD_TO_CART_DATA = {
            event: "addToCart",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                add: {
                    actionField: {
                        list: "Cart",
                        label:"RecntlyViewedProduct",
                        action:"EmptyCart"
                    },
                    products: [productData(data)],
                    adsProduct:[productData(data)]
                },
            },
        };
        return ADD_TO_CART_DATA;
    },

    CART_UPDATED: (data) => {
        const _product = [];
        data.cart.items.map((item) => {
            item.product.quantity = item.quantity
            _product.push(productData(item.product));
            return null
        });
        const CART_UPDATED_DATA = {
            event: "cartUpdated",
            totalAmount: data.cart.prices.grand_total.value,
            noOfProducts: data.cart.items.length,
            totalQuantity: data.cart.total_quantity,
            products: [..._product],
            dimension7:getEncryptedEmail()
        };
        return CART_UPDATED_DATA;
    },

    CART_PRODUCT_QUANTIY_UPDATED: (data) => {
        const eventData= {
            category:"Checkout",
            action:data.product.name,
            label: `Quantity Updated | ${data.quantity} | ${data.updatedQuantity}`
        }
        return generalEventData(eventData);
    },
    ADD_TO_WISHLIST_EMPTY_CART: (data) => {
        let ga4Data = {
            event: "add_to_wishlist",
            ecommerce: {
                currency: data?.price?.minimalPrice?.amount?.currency || data?.price?.regularPrice?.amount?.currency,
                value: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
                items:[ga4Items(data,'cart_page')]
            }
        }
        return {ga3:wishlistEvent("Click", "CartPage", data),ga4:ga4Data}
    },

    PRODUCT_CLICK_ON_EMPTY_CART: (data) => {
        const PRODUCT_CLICK_DATA = {
            event: "productClick",
            ecommerce: {
                click: {
                    actionField: {
                        list: "Cart",
                        label:"RecenltyViewedProduct",
                        action:"EmptyCart"
                    },
                    products: [productData(data)],
                    adsProduct:[productData(data)],
                },
            },
        };
        return PRODUCT_CLICK_DATA;
    },
    ADD_TO_WISHLIST_CART: (data) => {
        let ga4Data = {
            event: "add_to_wishlist",
            ecommerce: {
                currency: data?.price?.minimalPrice?.amount?.currency || data?.price?.regularPrice?.amount?.currency,
                value: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
                items:[ga4Items(data,'cart_page')]
            }
        }
        return {ga3:wishlistEvent("Click", "CartPage", data),ga4:ga4Data}
    },

    REMOVE_FROM_CART: (data) => {
        const product = data.product;
        const REMOVE_FROM_CART_DATA = {
            event: "removeFromCart",
            ecommerce: {
                remove: {
                    // 'remove' actionFieldObject measures.
                    products: [productData(product)],
                    adsProduct:[productData(product)]

                },
            },
        };
        return REMOVE_FROM_CART_DATA;
    },

    CHECK_COD: (data) => {
        const eventData = {
            category:  "ChooseLocation",
            action: "AddOrEditAddress",
            label: "AddOrEditAddress",
        }
        let result =  generalEventData(eventData);
        result.isCodAvailable = data.data.checkcod.cod_available;
        result.message = data.data.checkcod.message;
        return result;
    },
    VALID_COUPON: (data) => {
        const eventData = {
            category:"checkout",
            action:"invalid",
            label:data
        }
        return generalEventData(eventData);
    },
    INVALID_COUPON: (data) => {
        const eventData = {
            category:"checkout",
            action:"invalid",
            label:data
        }
        return generalEventData(eventData);
    },

    CART_PAGE_LOAD: (data) => {
        const products = [];
        const dymad_prodid = [];
        const dymad_pname = [];
        const dymad_pval = [];
        const _adsProduct = [];
        const parentData = storage.getItem("parentData") || [];
        let ga4_products = [];
        let ga4Data = {
            event: 'view_cart',
            ecommerce:{
              
            }
        }


        data.cart.items.map((item, index) => {
            // item.product.quantity = item.quantity;
            products.push(productData(item.product));
            ga4_products.push(ga4Items(item.product,'view_cart',index, item.quantity))
            dymad_prodid.push(item.product.sku);
            dymad_pname.push(item.product.name);
            dymad_pval.push(item.product.price.minimalPrice.amount.value);
            let parentProductData =  parentData.find( ch => ch['childSku'] === item.product.sku );
            if(parentProductData){
                let pr = _adsProduct.find(temp => temp['id']===parentProductData.parent.sku);
                if(!pr){
                    _adsProduct.push(productData(parentProductData.parent));
                }
            }else{
                _adsProduct.push(productData(item.product))
            }
            return null;
        });
        const CART_PAGE_LOAD_DATA = {
            event: "cart_viewed",
            products: [...products],
            adsProduct:[..._adsProduct],
            totalAmount: data.cart.prices.grand_total.value,
            totalQuantity: data.cart.total_quantity,
            noOfProducts: data.cart.items.length,
            ecomm_pagetype: "cart",
            ecomm_prodid: [...dymad_prodid],
            ecomm_pname: [...dymad_pname],
            ecomm_pval: [...dymad_pval],
            ecomm_totalvalue: data.cart.prices.grand_total.value,
            dimension7:getEncryptedEmail()
        };
        ga4Data.ecommerce.items = [...ga4_products];
        ga4Data.ecommerce.value = data.cart.prices.grand_total.value;
        ga4Data.ecommerce.currency = data?.cart?.items[0]?.product?.price?.regularPrice?.amount?.currency;
        return {ga3:CART_PAGE_LOAD_DATA, ga4:ga4Data};
    },

    CONTINUE_TO_PAY: (data) => {
        const products = [];
        let productName = [];
        let productId = [];
        let price = [];
        const parentData = storage.getItem("parentData") || [];
        let ga4Data = {
            event: "begin_checkout",
            ecommerce:{}
        }
        let ga4_products = [];


        const _adsProduct = [];
        data.items.map((row,index) => {
            products.push(productData(row.product));
            ga4_products.push(ga4Items(row.product,'continue_to_pay',index))
            let parentProductData = parentData.find( ch => ch['childSku'] === row.product.sku );
            if(parentProductData){
                let pr = _adsProduct.find(temp => temp['id']===parentProductData.parent.sku);
                if(!pr){
                    _adsProduct.push(productData(parentProductData.parent))
                }
            }else{
                _adsProduct.push(productData(row.product));
            }
            productName.push(row.product.name);
            productId.push(row.product.sku);
            price.push(row.product.price.minimalPrice.amount.value);
            return null;
        });
        storage.setItem("placeOrderProduct",products);
        storage.setItem("placeOrderAdsProduct",_adsProduct);
        const CONTINUE_TO_PAY_DATA = {
            event: "checkout",
            pageName: "detnalkart|Checkout Step1|Continue Payment",
            ecommerce: {
                checkout: {
                    actionField: { step: 1, option: "Continue To Pay" },
                    products: products,
                    adsProduct:[..._adsProduct]
                },
            },
            totalQuantity: data.total_quantity,
            noOfProducts: data.items.length,
            couponCode: data.applied_coupons,
            productName: productName.join(),
            productId: productId.join(),
            price: price.join(),
            totalAmount: data.prices.grand_total.value,
            subTotal: data.prices.subtotal_including_tax.value,
            shippingCharge: data.shipping_addresses[0]?.available_shipping_methods[0]?.amount.value,
            discountAmount: data.prices.discount,
            dimension7:getEncryptedEmail()
        };
        ga4Data.ecommerce.items = [...ga4_products];
        return {ga3:CONTINUE_TO_PAY_DATA, ga4:ga4Data};

    },

}
export default CartPageEvents;
