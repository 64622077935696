
const moreListConfiguration = [
    {
        title: 'Membership',
        path: 'GO_TO_MEMBERSHIPSCREEN',
        leftIcon: {
            type: 'material',
            title: 'CardMembershipIcon'
        },
        event:'MEMBERSHIP',
        rightIcon: {
            type: '',
            title: ''
        },
        pathType: 'relative'
    },
    {
        title: 'Help Center',
        path: 'GO_TO_HELP_CENTER',
        leftIcon: {
            type: 'solid',
            title: 'question-circle'
        },
        event:'HELP_CENTER',
        rightIcon: {
            type: '',
            title: ''
        },
        pathType: 'relative'
    },
    {
        title: 'Reward Zone',
        path: 'GO_TO_KNOW_REWARDS',
        leftIcon: {
            type: 'material',
            title: 'RewardIcon'
        },
        event: 'REWARD_ZONE',
        rightIcon: {
            type: '',
            title: ''
        },
        pathType: 'relative'
    },
    {
        title: 'Android App',
        path: 'GO_TO_PLAY_STORE',
        leftIcon: {
            type: 'brand',
            title: 'android'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'ANDROID_APP',
        pathType: 'absolute'
    },
    {
        title: 'IOS App',
        path: 'GO_TO_APP_STORE',
        leftIcon: {
            type: 'brand',
            title: 'apple'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'IOS_APP',
        pathType: 'absolute'
    },
    {
        title: 'Careers',
        path: 'GO_TO_CAREERS',
        leftIcon: {
            type: 'solid',
            title: 'graduation-cap'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'CAREERS',
        pathType: 'absolute'
    },
    {
        title: 'Blogs',
        path: 'GO_TO_BLOGS',
        leftIcon: {
            type: 'material',
            title: 'BookIcon'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'HEADER_BLOGS',
        pathType: 'absolute'
    },
    {
        title: 'Magazine',
        path: 'GO_TO_MAGAZINE',
        leftIcon: {
            type: 'material',
            title: 'ImportContactsIcon'
        },
        rightIcon: {
            type: '',
            title: ''
        },
        event:'MAGAZINE',
        pathType: 'relative'
    },
];

export default moreListConfiguration;

