import { gql } from '@apollo/client';

const RETURN_ACTIONS = gql`
    query{
        returnActions{
            id
            action
        }
    }
`;
export default RETURN_ACTIONS;
