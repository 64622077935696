import { gql } from '@apollo/client';
// eslint-disable-next-line no-unused-vars
import toSource from "@helpers/toSource";

const GET_CATEGORY_PRODUCTS = (sortBy, category, price, manufacturer) => {
    const priceRange =  [];
    const fromPrice = priceRange[0];
    const toPrice = priceRange[1];
    return gql`
        query CategoryData($id: Int!, $currentPage: Int){
            category(id: $id) {
                name
                image
                url_key
                meta_title
                meta_keywords
                meta_description
                description
                children{
                    name
                    id
                    url_path
                }
            }
            products(
                filter:{
                    category_id: {eq: "${category}"},
                    ${priceRange.length > 1 ? `price: {from: "${fromPrice}", to: "${toPrice}"},` : ''}
                    ${manufacturer ? `manufacturer: {in: ${manufacturer.toSource()}}` : ''}
                },
                currentPage: $currentPage,
                ${sortBy.price ? `sort: {price: ${sortBy.price}}` : ''}
            ){
                page_info{
                    current_page
                }
                total_count
                items {
                    id
                    name
                    stock_status
                    type_id
                    meta_description
                    reward_point_product
                    only_x_left_in_stock
                    image{
                        label
                        url
                    }
                    small_image{
                        label
                        url
                    }
                    url_path
                    url_key
                    manufacturer
                    special_price
                    sku
                    short_description{
                        html
                    }
                    media_gallery_entries{
                        id
                        file
                        types
                    }
                    msrp
                    price{
                        regularPrice{
                            amount{
                                value
                                currency
                            }
                        }
                        minimalPrice{
                            amount{
                                value
                                currency
                            }
                        }
                        maximalPrice{
                            amount{
                                value
                                currency
                            }
                        }
                    }
                    ... on BundleProduct{
                        dynamic_price
                        short_description{
                            html
                        }
                        items{
                            title
                            type
                            required
                            option_id
                            sku
                            position
                            options{
                                label
                                qty
                                price
                                id
                                position
                                is_default
                                price_type
                                can_change_quantity
                            }
                        }
                    }
                    average_rating
                    rating_count
                    categories{
                        name
                        url_path
                        position
                    }
                }
            }
        }
    `;
}

export default GET_CATEGORY_PRODUCTS;
