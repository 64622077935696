import { gql } from '@apollo/client';
import {BRANDS_FRAGMENT} from './fragments';

const GET_BRANDS = gql`
	query{
		getBrand{
			...brandFields
	  	}
	}
	${BRANDS_FRAGMENT}
`;

export default GET_BRANDS;
