import { gql } from '@apollo/client';

const RETURN_REASONS = gql`
    query{
        returnReasons{
            attachment
            children {
              attachment
              id
              reason
            }
            code
            id
            reason
          }
    }
`;
export default RETURN_REASONS;
