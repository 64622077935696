import {gql} from '@apollo/client';
import { cartRewardFragment } from './fragments';

const CART_REWARDS_INFO = gql`
    query{
        getRewardsCartInfo{
            ...cartRewardFragment
        }
    }
    ${cartRewardFragment}
`;

export default CART_REWARDS_INFO;
