
import { gql } from '@apollo/client';

const GET_ORDERS_NEW = gql`
    query newCustomerOrders($timespan: Int $page: Int){
        customerOrders(timespan:$timespan page: $page){
                can_cancel
                can_return
                grand_total
                is_processing
                can_reorder
                id
                increment_id
                created_at
                status
                currency
                payment_method
                items{
                    name
                    price
                    sku
                    product_id
                    qty
                    thumbnail
                    url_key
                }
        }
    }
`;

export default GET_ORDERS_NEW;
