import { gql } from "@apollo/client";

const GET_MANUFACTURER_QUERY = gql`
  query {
    customAttributeMetadata(
      attributes: { attribute_code: "manufacturer", entity_type: "4" }
    ) {
      items {
        attribute_options {
          label
          value
        }
      }
    }
  }
`;

export default GET_MANUFACTURER_QUERY;
