import {gql} from '@apollo/client';

const SET_SHIPPING_ADDRESS_ON_CART = gql`
mutation setShippingAddressesOnCart(
    $firstname: String!,
    $lastname: String!,
    $postcode: String!,
    $telephone: String!,
    $alternate_mobile:String,
    $street:[String]!,
    $country_id: String!,
    $region_id: String!,
    $city: String!,
    $cart_id: String!
){
    setShippingAddressesOnCart(
        input: {
            cart_id: $cart_id
            shipping_addresses: [
                {
                    address: {
                        firstname: $firstname,
                        lastname: $lastname,
                        street: $street,
                        city: $city,
                        region: $region_id
                        postcode: $postcode,
                        country_code: $country_id,
                        telephone: $telephone,
                        alternate_mobile: $alternate_mobile,
                        save_in_address_book: false
                    }
                }
            ]
        }
    ) {
        cart {
            shipping_addresses {
                firstname
                lastname
                company
                street
                city
                region {
                    code
                    label
                }
                postcode
                telephone
                country {
                    code
                    label
                }
                available_shipping_methods {
                    amount {
                        currency
                        value
                    }
                    available
                    carrier_code
                    carrier_title
                    error_message
                    method_code
                    method_title
                    price_excl_tax {
                        value
                        currency
                    }
                    price_incl_tax {
                        value
                        currency
                    }
                }
            }
        }
    }
}
`;
export default SET_SHIPPING_ADDRESS_ON_CART;
