import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import BrowserPersistence from '@helpers/browser_persistence';
import {sendQueryResponse, queryUrl} from '@helpers/sendQueryData';

const cache  =  new InMemoryCache();
const storage = new BrowserPersistence();

const apiKeyObj = {
    "appSyncLive": 'da2-b7vqajjrfbgbvjf4fbesbavuhq',
    "appSyncLiveTwo":'da2-b7vqajjrfbgbvjf4fbesbavuhq',
    "appSyncStaging":'da2-b7vqajjrfbgbvjf4fbesbavuhq'
};

const requestUrl = {
    "magentoLive":'https://api-apollo.dentalkart.com/graphql',
    "appSyncLive": 'https://api-apollo.dentalkart.com/graphql',
    "appSyncLiveTwo":'https://api.dentalkart.com/graphql',
    "appSyncStaging":'https://api.dentalkart.com/graphql',
};


const httpLinkObj = {
  magentoLive: new HttpLink({
    uri: requestUrl.magentoLive,
  }),
  appSyncLive: new HttpLink({
    uri: requestUrl.appSyncLive,
  }),
  appSyncLiveTwo: new HttpLink({ uri: requestUrl.appSyncLiveTwo }),
  appSyncStaging: new HttpLink({ uri: requestUrl.appSyncStaging }),
};

const formHeaderbasedOnType = (option) => {
    const storage = new BrowserPersistence();
    const token = storage.getItem('token') || null;
    const header = {
        "authorization": token ? `Bearer ${token}` : '',
        "platform": `web`,
    };
    switch(option){
        case "magentoLive":
            header["x-api-key"] = "da2-b7vqajjrfbgbvjf4fbesbavuhq";
          break;
        case "appSyncLiveTwo":
          header["x-api-key"] = apiKeyObj[option];
            break;
        case "appSyncLive":
          header["x-api-key"] = apiKeyObj[option];
          break;
        case "appSyncStaging":
          header["x-api-key"] = apiKeyObj[option];
          break;
        default:
          break;
    }
    return  header;
};

const setHeader = (option) => {
    const getNewHeader = formHeaderbasedOnType(option);
    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
          headers: {
            ...getNewHeader,
            cookies: document?.cookie ?? null,
          },
        });
        return forward(operation);
    });
    return { authMiddleware };

};

const errorInterceptor = onError(({ response }) => {
    try{
        if(response?.data){
            const responseOptions = {
                "query":  Object.keys(response.data).join(','),
                "customer_id":  storage.getItem('customer_email') || storage.getItem('user_id') || null,
                "created_at":new Date(),
                "user_agent": navigator.userAgent,
                "error": response?.errors ? JSON.stringify(response?.errors) : 'success',
                "page":window.location.pathname
            }
            sendQueryResponse(responseOptions,queryUrl.query_error)
        }
    }
    catch(e){
        console.log(e)
    }
});

const createClient  = _type => new ApolloClient({
    cache,
    link: from([
        setHeader(_type).authMiddleware,
        errorInterceptor,
        httpLinkObj[_type]
    ])
});

const magentoLiveClient = createClient("magentoLive");

const appSyncLiveCLient = createClient("appSyncLive");

const appSyncLiveCLientTwo = createClient("appSyncLiveTwo");

const appSyncStagingClient = createClient("appSyncStaging");

const nestClient = new ApolloClient({
    cache:cache,
    // uri:'/react-admin/gql',
    uri:'https://dental-admin.dentalkart.com/react-admin/gql'
  })




export {magentoLiveClient, appSyncLiveCLient, appSyncStagingClient, appSyncLiveCLientTwo, nestClient};
