import {gql} from '@apollo/client';

const GET_PRODUCT_ATTACHMENTS = gql`
    query productSpecifications($sku : String!){
        GetAttributesBySku(sku: $sku){
            attachments{
               title
               thumbnail
               url
            }
        }
    }
`;

export default GET_PRODUCT_ATTACHMENTS;
