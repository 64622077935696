import React, { Fragment, useContext, useState, useEffect } from "react";
import AppContext from "@helpers/appContext";
import CheckCashOnDelivery from "../../screens/cart/modules/checkCodSection";
import Country from "../../screens/country/index";
import Style from "./addressModal.module.scss";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@helpers/icons";
import Button from "@material-ui/core/Button";
import { isDesktop } from "@config/dentalkart.environment";
import fireEvents from "@helpers/event_data_layer";
import cookie from "react-cookies";
import BrowserPersistence from "@helpers/browser_persistence";
import AddressSection from "./AddressSection";

const storage = new BrowserPersistence();

function AddressModal({
  IsAddressModalOpen,
  setIsAddressModalOpen,
  cartTotals,
  cartData,
}) {
  const { routes, history, isLoggedIn, pincode } =
    useContext(AppContext);
  const initialPin = storage.getItem("pincode") || "";
  const [addressData, setAddressData] = useState([]);
  const country_id = cookie.load("country_id");
  const handleCloseAddressModal = () => {
    setIsAddressModalOpen(false);
  };

  const AddShippingAddresss = () => {
    return (
      <div
        className={Style.address_link_text}
        onClick={() => {
          fireEvents("GTM", "ADD_AND_EDIT_ADDRESS");
          handleCloseAddressModal();
          return history.push({
            pathname: routes.GO_TO_ADDRESSES,
          });
        }}
      >
        {`Add/Edit an address `}
      </div>
    );
  };

  return (
    <Dialog
      onClose={handleCloseAddressModal}
      aria-labelledby="simple-dialog-title"
      maxWidth={"xs"}
      fullScreen={isDesktop ? false : true}
      fullWidth={true}
      open={IsAddressModalOpen}
    >
      <div className={Style.dialog_wrapper}>
        <div className={Style.tittle_wrapper}>
          <span className={Style.heading_text}>Choose your location</span>
          <span className={Style.cross_icon} onClick={handleCloseAddressModal}>
            <Icon type={"material"} title={"Close"} />
          </span>
        </div>
        {(addressData?.length > 0 || !isLoggedIn) && (
          <p
            className={Style.delivery_helper_text}
          >{`Select a delivery location to see product availability and delivery options on payment page.`}</p>
        )}
        {isLoggedIn ? (
          <Fragment>
            <AddressSection addressData={addressData} setAddressData={setAddressData} handleCloseAddressModal={handleCloseAddressModal}/>
            <AddShippingAddresss />
          </Fragment>
        ) : (
          <Fragment>
            <div className={Style.login_button}>
              <Button
                className={Style.continue_button}
                onClick={() => {
                  history.push({
                    pathname: routes.GO_TO_LOGIN,
                  });
                  handleCloseAddressModal();
                }}
              >
                <span
                  className={Style.continue_text}
                >{`Sign in to see your addresses`}</span>
              </Button>
            </div>
          </Fragment>
        )}
        <Fragment>
          {country_id === "IN" && (
            <>
              <p className={Style.or_text}>or enter a pincode</p>
              <div>
                {cartData ? (
                  <CheckCashOnDelivery
                    cartTotals={cartTotals}
                    pincode={pincode}
                    cartData={cartData}
                    isCart={true}
                  />
                ) : (
                  <CheckCashOnDelivery pincode={pincode} isCart={false} />
                )}
              </div>
            </>
          )}
        </Fragment>

        <Fragment>
          <p className={Style.or_text}>or</p>
          <div>
            <Country setIsAddressModalOpen={setIsAddressModalOpen}/>
          </div>
        </Fragment>
      </div>
    </Dialog>
  );
}

export default AddressModal;
