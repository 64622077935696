import { gql } from '@apollo/client';

const GET_POPULAR_FAQ = gql`
	{
        popularfaq{
            question
            id
        }
    }
`;

export default GET_POPULAR_FAQ
