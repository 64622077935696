import {gql} from '@apollo/client';

const GET_PRODUCT_REVIEWS_ITEMS_NAME = gql`
    query productName($id: [Int], $sku: [String]){
        productData(id: $id, sku: $sku)
        {
            name
            type_id

        }
    }
`;

export default GET_PRODUCT_REVIEWS_ITEMS_NAME;
