import gql from "graphql-tag";
import productDataFragment from './fragments/productDataFragment.gql'

const GET_PDP_PRODUCT_DATA = gql`
  query ProductData($id: [Int], $sku: [String]) {
        productData(id: $id, sku: $sku) {
            id
            image_url
            name
            sku
            special_price
            url_key
            thumbnail_url
            short_description
            type_id
            manufacturer
            average_rating
            rating_count
            is_in_stock
            is_cod
            msrp
            demo_available
            weight
            max_sale_qty
            ...productDataFragment
        }
  }
  ${productDataFragment}
`;

export default GET_PDP_PRODUCT_DATA;
