import { gql } from '@apollo/client';

const CUSTOMER_INFO = gql`
    query {
        customer {
            id
            firstname
            lastname
            email
            mobilenumber
            confirmation {
                email_confirm
                mobile_confirm
            }
            taxvat
            customer_unique_id
            addresses {
                default_shipping
                telephone
            }
        }
    }
`;

export default CUSTOMER_INFO;
