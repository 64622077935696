import React from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RewardIcon from "@material-ui/icons/MonetizationOn";
import BookIcon from "@material-ui/icons/Book";
import OrderIcon from "@material-ui/icons/HowToVote";
import HelpIcon from "@material-ui/icons/Help";
import WishlistIcon from "@material-ui/icons/Favorite";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import WarningIcon from "@material-ui/icons/Warning";
import ReturnIcon from "@material-ui/icons/SettingsBackupRestore";
import RightArrow from "@material-ui/icons/ArrowRightAlt";
import Lock from "@material-ui/icons/Lock";
import MailOutline from "@material-ui/icons/MailOutline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Delete from "@material-ui/icons/Delete";
import Error from "@material-ui/icons/Error";
import Close from "@material-ui/icons/Close";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Info from "@material-ui/icons/Info";
import HighlightOff from "@material-ui/icons/HighlightOff";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import SaveAlt from "@material-ui/icons/SaveAlt";
import EditIcon from "@material-ui/icons/Edit";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import ShareIcon from "@material-ui/icons/Share";
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import TuneIcon from '@material-ui/icons/Tune';
import CircleIcon from '@mui/icons-material/Circle';
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
    faQuestionCircle as helpCenter,
    faGraduationCap as careers,
    faUsers as aboutus,
    faUserMd as profile,
    faHandHoldingUsd as rewards,
    faExclamationTriangle as defaultIcon,
    faTicketAlt as ticket,
} from "@fortawesome/free-solid-svg-icons";
import {
    faAndroid as android,
    faApple as apple,
    faInstagram as instagram,
    faFacebookSquare as facebook,
    faTwitterSquare as twitter,
    faYoutubeSquare as youtube,
} from "@fortawesome/free-brands-svg-icons";

library.add({
    helpCenter,
    android,
    apple,
    careers,
    aboutus,
    profile,
    defaultIcon,
    rewards,
    ticket,
    instagram,
    facebook,
    twitter,
    youtube,
});

const materialIcons = {
    RewardIcon: RewardIcon,
    BookIcon: BookIcon,
    OrderIcon: OrderIcon,
    WishlistIcon: WishlistIcon,
    HelpIcon: HelpIcon,
    LocalOffer: LocalOfferIcon,
    warning: WarningIcon,
    ReturnIcon: ReturnIcon,
    RightArrow: RightArrow,
    Lock: Lock,
    MailOutline: MailOutline,
    AccountCircle: AccountCircle,
    Delete: Delete,
    Error: Error,
    Close: Close,
    CheckCircle: CheckCircle,
    Info: Info,
    HighlightOff: HighlightOff,
    KeyboardArrowUp: KeyboardArrowUp,
    KeyboardArrowDown: KeyboardArrowDown,
    Cancel: Cancel,
    SaveAlt: SaveAlt,
    EditIcon: EditIcon,
    ShareIcon: ShareIcon,
    PhoneIphone: PhoneIphone,
    UnCheckedRadioBtn: RadioButtonUncheckedIcon,
    CheckedRadioBtn: CheckCircleOutlineIcon,
	ArrowDropDownOutlinedIcon:ArrowDropDownOutlinedIcon,
	ArrowDropUpOutlinedIcon:ArrowDropUpOutlinedIcon,
	TuneIcon:TuneIcon,
    Circle:CircleIcon,
    CardMembershipIcon:CardMembershipIcon,
    ImportContactsIcon:ImportContactsIcon,
    PictureAsPdfIcon:PictureAsPdfIcon

};
const Icon = ({ type, title, styling }) => {
    let MaterialIcon = "";
    if (type === "material") MaterialIcon = materialIcons[title];
    const types = {
        brand: <FontAwesomeIcon className={styling ? styling : ""} icon={["fab", title]} />,
        solid: <FontAwesomeIcon className={styling ? styling : ""} icon={title} />,
        material: <MaterialIcon className={styling ? styling : ""} />,
        default: <FontAwesomeIcon className={styling ? styling : ""} icon={"exclamation-triangle"} />,
    };
    return types[type] || types["default"];
};

Icon.propTypes = {
    type: PropTypes.oneOf(["brand", "solid", "material"]).isRequired,
    title: PropTypes.string.isRequired,
};
export default Icon;
