import { gql } from '@apollo/client';

const GET_NAVIGATION_MOBILE = gql`
    {
        categoryList{
            children{
               level
               include_in_menu
               position
               name
               id
               url_path
               is_anchor
               thumbnail
               children{
                   level
                   include_in_menu
                   position
                   name
                   id
                   url_path
                   is_anchor
                   children{
                       level
                       include_in_menu
                       name
                       id
                       is_anchor
                       url_path
                   }
               }
            }

        }
    }
`;

export default GET_NAVIGATION_MOBILE;
