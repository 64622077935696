
import { gql } from '@apollo/client';

const CANCEL_ORDER_V2 = gql`
    mutation cancelOrderV2($fullOrderCancel: Int, $orderId: String!, $reason: Int!){
        cancelOrderV2(input:{fullOrderCancel: $fullOrderCancel, orderId: $orderId , reason: $reason}){
            cancelOrder{
                orderId
                errors
              }

        }
    }
`;

export default CANCEL_ORDER_V2;
