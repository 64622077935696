const footerHighlights = [
    {
        name: 'Help Center',
        icon: 'question-circle',
        url: 'GO_TO_HELP_CENTER',
    },
    {
        name: 'Rewards',
        icon: 'hand-holding-usd',
        url: '#'
    },
    {
        name: 'Genuine Products',
        icon: 'check-circle',
        url: '#'
    },
];

const copyrights = {
    text: `2016-${new Date().getFullYear()}, VASA DENTICTY LIMITED`,
    icon: 'copyright'
}

export {footerHighlights, copyrights};
