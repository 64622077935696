import React, { useContext} from 'react';
import Style from './infoHeader.module.scss';
import {fixedFooter} from '@config/dentalkart.environment';
import AppContext from '@helpers/appContext';

const PhoneNumber = () => {
    return(
        <div className={Style.phone_number_wrapper}>
            <img 
                src={'https://dentalkart-media.s3.ap-south-1.amazonaws.com/React/call-center-worker-with-headset.png'} 
                alt={'call-center'} />
            <a 
                className={Style.phone} 
                href={`tel:${fixedFooter.address.phone}`}
            >
                {fixedFooter.address.phone}
            </a>
        </div>
    )
}

const DownloadApp = () => {
    const context = useContext(AppContext);
    const {routes} = context;
    return(
        <div className={Style.download_app_wrapper}>
            <span>Download Our APP</span>
            <a href={routes.GO_TO_APP_STORE} target="_blank" rel="noopener noreferrer" >
                <img src={'https://dentalkart-application-media.s3.ap-south-1.amazonaws.com/website-icons/app-store%2BIcon.png'} alt={'app store'}/>
            </a>
            <a href={routes.GO_TO_PLAY_STORE} target="_blank" rel="noopener noreferrer">
                <img src={'https://dentalkart-application-media.s3.ap-south-1.amazonaws.com/website-icons/Google%2BPlay%2BStore%2BIcon.png'} alt={'play store'} />
            </a>
        </div>
    )
}

const InfoHeader = () => {
    return(
        <div className={Style.info_header_wrapper}>
            <PhoneNumber />
            <DownloadApp />
        </div>
    )
    
}

export default InfoHeader;
