import BrowserPersistence from "@helpers/browser_persistence";
import { generalEventData } from "./helper";
const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
};

const AddressEvents = {
    ADD_AND_EDIT_ADDRESS: () => {
        const eventData = {
            category: "ChooseLocation",
            action: "AddOrEditAddress",
            label: "AddOrEditAddress",
        };
        return generalEventData(eventData);
    },
    SAVE_NEW_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "Checkout Option 2",
            label: "SaveAndDeliverHere",
        };
        return generalEventData(eventData);
    },

    SAVE_EDIT_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "CheckoutOption2",
            label: "SaveEditAddress",
        };
        return generalEventData(eventData);
    },

    CANCEL_NEW_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "CheckoutOption2",
            label: "CancelSaveAndDeliver",
        };
        return generalEventData(eventData);
    },

    CANCEL_EDIT_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "Checkout Option 2",
            label: "CancelEditAddress",
        };
        return generalEventData(eventData);
    },
    EDIT_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "Checkout Option 2",
            label: "EditAddress",
        };
        return generalEventData(eventData);
    },
    CHANGE_ADD_NEW_ADDRESS: () => {
        const eventData = {
            category: "Ecommerce",
            action: "CheckoutOption3",
            label: "ChangeOrAddNewAddress",
        };
        return generalEventData(eventData);
    },
    SAVE_AND_DELIVER_HERE: (data) => {
        const SAVE_AND_DELIVER_HERE_DATA = {
            event: "CheckoutOption",
            ecommerce: {
                checkout_option: {
                    actionField: { step: 2, option: "Add New OR Edit Address" },
                    dimension7: getEncryptedEmail(),
                },
            },
        };
        return SAVE_AND_DELIVER_HERE_DATA;
    },
};
export default AddressEvents;
