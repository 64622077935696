import { gql } from "@apollo/client";

const POST_PRODUCT_REVIEW_V2 = gql`
mutation PostProductReviewV2($details: String, $id: Int, $nickname: String,$product_id: Int,$rating: Int,$title: String) {
    postProductReviewV2(review:{details: $details ,id: $id, nickname: $nickname,product_id: $product_id, rating: $rating, title: $title}) {
      created_at
      customer_id
      details
      id
      nickname
      product_id
      rating
      status
      status_label
      title
    }
  }
  
`;

export default POST_PRODUCT_REVIEW_V2;
