import { gql } from '@apollo/client';

const ADD_VIRTUAL_PRODUCT_TO_CART = gql`
	mutation addVirtualProductsToCart($cart_id : String!, $cart_items:[VirtualProductCartItemInput]!){
		addVirtualProductsToCart(
			input: {
				cart_id: $cart_id
				cart_items: $cart_items
			}
		) {
			cart {
				items {
					id
					product {
						name
						sku
					}
					quantity
				}
			}
		}
}`;

export default ADD_VIRTUAL_PRODUCT_TO_CART;
