import { gql } from "@apollo/client";

const PLACE_ORDER_MUTATION = (isVersionTwo = false) =>
  isVersionTwo
    ? gql`
        mutation dkplaceOrderV2($cart_id: String!, $payment_method: String!, $attribute_code: String!, $value:String!) {
          dkplaceOrderV2(
            input: { cart_id: $cart_id, payment_method: $payment_method,extension_attributes:[{attribute_code:$attribute_code,value:$value }] }
          ) {
            order_number
            reference_number
            merchant_id
            amount
            currency
          }
        }
      `
    : gql`
        mutation dkplaceOrder($cart_id: String!, $payment_method: String!) {
          dkplaceOrder(
            input: { cart_id: $cart_id, payment_method: $payment_method }
          ) {
            order_number
            reference_number
            merchant_id
            amount
            currency
          }
        }
      `;

export default PLACE_ORDER_MUTATION;
