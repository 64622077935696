import { gql } from '@apollo/client';
import {ProductListingFragment, cartPriceFragment, cartShippingAddressFragment} from './fragments';

const GUEST_NEW_CART = gql`
	query cart($cart_id: String!){
		cart(cart_id: $cart_id){
			global_errors
			applied_coupons {
                code
            }
            total_quantity
			...ProductListingFragment
            prices {
				...cartPriceFragment
            }
			shipping_addresses{
				...cartShippingAddressFragment
			}
        }
	}
	${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
`;

export default GUEST_NEW_CART;
