import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";
import HeaderEvents from "./events/headerEvents";
import FooterEvents from "./events/footerEvents";
import HomePageEvents from "./events/homePageEvents";
import CategoryPageEvents from "./events/categoryPageEvents";
import HelpCenterEvents from "./events/helpcenterEvents";
import ProductPageEvents from "./events/productPageEvents";
import CartPageEvents from "./events/cartPageEvents";
import AuthenticationEvents from "./events/authenticationEvents.js";
import PaymentPageEvents from "./events/paymentPageEvents";
import AddressEvents from "./events/addressEvents";
import BrandPageEvents from "./events/brandPageEvents";
import OrderPageEvents from "./events/orderPageEvents";
import SearchBarPageEvents from "./events/searchBarPageEvents";
import NotImportantEvents from "./events/notImportantEvents";

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
};

const generalEventData = (data) => ({
    event: "Click",
    eventCategory: data.category || "Checkout",
    eventAction: data.action || data.product.name,
    eventLabel: data.label || `Quantity Updated | ${data.quantity} | ${data.updatedQuantity}`,
    dimension7: getEncryptedEmail(),
});

const productData = (data) => ({
    name: data.name,
    id: data.sku,
    sku: data.sku,
    p_id: data.id,
    price: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
    quantity: data.quantity ? data.quantity : 1,
    brands: data.manufacturer ? data.manufacturer : "Brand not assigned",
    imageUrl: data.image.url
        ? data.image.url
        : "https://s3.ap-south-1.amazonaws.com/dentalkart-media/React/Web+Icon+%26+Skeleton+Gif/Default-Product-Image-Place-Holder.png",
    productUrl: `https://www.dentalkart.com/${data.url_key}.html`,
    totalPrice: data.quantity
        ? data.quantity * data.price.minimalPrice.amount.value
        : 1 * data.price.minimalPrice.amount.value,
    dimension7: getEncryptedEmail(),
    dimension4: "ProductDetailPage",
    dimension14: data.name,
});

const Events = {
    ...HeaderEvents,
    ...FooterEvents,
    ...HomePageEvents,
    ...CategoryPageEvents,
    ...HelpCenterEvents,
    ...ProductPageEvents,
    ...CartPageEvents,
    ...AuthenticationEvents,
    ...PaymentPageEvents,
    ...AddressEvents,
    ...BrandPageEvents,
    ...OrderPageEvents,
    ...SearchBarPageEvents,
    ...NotImportantEvents,

    CONTINUE_SHOPPING: () => {
        const eventData = {
            category: "Cart",
            action: "OrderSuccess",
            label: "ContinueShopping",
        };
        return generalEventData(eventData);
    },

    HOME_BRAND: (data) => {
        const eventData = {
            category: "Brands",
            action: "Home",
            label: data.name,
        };
        return generalEventData(eventData);
    },

    APP_ICON: (data) => {
        const eventData = {
            category: "AppIcon",
            action: "IconClick",
            label: data.platform,
        };
        return generalEventData(eventData);
    },

    CONTINUE_AS_GUEST: (data) => {
        const products = [];
        data.items.map((row) => products.push(productData(row.product)));
        const CONTINUE_AS_GUEST_DATA = {
            event: "checkout",
            pageName: "detnalkart|Checkout StepGuest|Continue As Guest",
            ecommerce: {
                checkout: {
                    actionField: { step: 2, option: "Continue As Guest" },
                    products: products,
                },
            },
        };
        return CONTINUE_AS_GUEST_DATA;
    },

    HOME_PAGE: (data) => {
        const HOME_PAGE_DATA = {
            event: "impressions",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                dimension7: getEncryptedEmail(),
                impressions: { ...data },
            },
        };
        return HOME_PAGE_DATA;
    },

    GOOGLE_ADD_USER: (data) => {
        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + " " + time;
        const GOOGLE_ADD_USER_DATA = {
            event: "Click",
            eventCategory: "Google Add User",
            eventAction: dateTime,
            eventLabel: data.gclid || data.dclid,
            dimension7: storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest",
        };
        return GOOGLE_ADD_USER_DATA;
    },
    USER_INFO: (data) => {
        const extractTelephone = (addresses = []) => {
            let telephone = "Guest";
            if (addresses.length > 0) {
                telephone = addresses[0].telephone;
            }
            return telephone;
        };
        const extractPathname = () => {
            if (window.location.pathname === "/") return "Home";
            else if (window.location.pathname.includes("/brands")) return "Brands";
            else if (window.location.pathname.includes("/cart")) return "Cart";
            else if (window.location.pathname.includes("/payment")) return "Payment";
            else if (window.location.pathname.includes("/account")) return "Account";
            else if (window.location.pathname.includes("/help-center")) return "Help Center";
            else return "Other";
        };

        const USER_INFO_DATA = {
            event: "_trackpageview",
            virtualPageURL: `${window.location.pathname}${data.gclid ? "?gclid=" : ""}${data.gclid ? data.gclid : ""}`,
            ...(data && data.email && {UserID: window.btoa(data.email)}),
            LoginType: data && data.email ? "LoggedIn" : "Guest",
            email: data && data.email ? data.email : "Guest",
            name: data && data.firstname ? `${data.firstname} ${data.lastname}` : "Guest",
            telephone: extractTelephone(data.addresses),
            customer_id: data && data.email ? window.btoa(data.email) : "Guest",
            ecomm_pagetype: extractPathname(),
        };
        return USER_INFO_DATA;
    },
    GOOGLE_ADD_USER_ORDERED: (data) => {
        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + " " + time;
        let addId = "";
        if (data.gclid) {
            addId = `gclid=${data.gclid} | ${data.incrementId}`;
        }
        if (data.dclid) {
            addId = `dclid=${data.dclid} | ${data.incrementId}`;
        }
        const GOOGLE_ADD_USER_ORDERED_DATA = {
            event: "Click",
            eventCategory: "Google Add User Ordered",
            eventAction: dateTime,
            eventValue: data.orderTotals,
            eventLabel: addId,
            dimension7: storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest",
        };
        return GOOGLE_ADD_USER_ORDERED_DATA;
    },
};
export default Events;
