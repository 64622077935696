import {gql} from '@apollo/client';

const GET_SEARCH_PRODUCTS = gql`
  query getProductSearch(
    $search:String, $applyFilters:[ProductSearchApplyFilters], 
    $sort:String, $pageNo:Int, $platform:String
  ){
    getProductSearch(
      search: $search, applyFilters: $applyFilters
      sort: $sort, pageNo: $pageNo, platform: $platform
    ){
      searchedTerm
      records {
        sku
        objectID
        name
        image_url
        short_description
        rating_count
        rating_summary
        demo_available
        type_id
        prices{
          __typename
          minimalPrice {
            amount {
              currency_symbol
              value
              currency
            }
            adjustments
          }
          maximalPrice {
            amount {
              currency_symbol
              value
              currency
            }
            adjustments
          }
          regularPrice {
            amount {
              currency_symbol
              value
              currency
            }
            adjustments
          }
        }
        in_stock
      }
      filters{
        __typename
        key
        value
      }
      filter_stats{
        key
        value{
          min
          max
          avg
          sum
        }
      }
      hitsPerPage
      noOfResults
      nbPages
      page
      query
    }
  }
`;

export default GET_SEARCH_PRODUCTS;