import { gql } from '@apollo/client';

const CHANGE_PASSWORD = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!){
	    changeCustomerPassword(
	    	currentPassword: $currentPassword,
	    	newPassword: $newPassword
	    ){
	        created_at
	    }
	}
`;

export default CHANGE_PASSWORD;
