import { gql } from '@apollo/client';
import {ProductListingFragment, cartPriceFragment, cartShippingAddressFragment} from '../fragments';

const APPLY_COUPON = gql`
	mutation applyCoupon($cart_id: String!, $coupon_code: String!){
        applyCouponToCart(
            input: {
                cart_id : $cart_id,
                coupon_code : $coupon_code
            }
        )  {
                cart {
					global_errors
				    total_quantity
					applied_coupons {
						code
					}
					...ProductListingFragment
					prices {
						...cartPriceFragment
					}
					shipping_addresses{
					  	...cartShippingAddressFragment
					}
                }
            }
	}
	${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
`;
export default APPLY_COUPON;
