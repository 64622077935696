import React from 'react';
import { withRouter } from 'react-router-dom';
import { COUNTRIES_LIST_QUERY } from '@graphql';
import GetQueryData from '@components/getQueryComponent';
import { appSyncLiveCLient } from '@apolloClient';
import CountryList from './countryList';
import {Helmet} from "react-helmet";

const Country = React.memo((props) => {
    const queryConfiguration= {
        gql: COUNTRIES_LIST_QUERY,
        queryClient: appSyncLiveCLient, queryFetchPolicy:'cache-and-network'
    }
    return(
        <GetQueryData queryConfiguration={queryConfiguration}>
            {
                (data) => {
                    if (data && data.countries){
                        const {countries} = data;
                        return(
                            <>
                                <CountryList countries={countries} setIsAddressModalOpen={props.setIsAddressModalOpen}/>
                                <Helmet>
                                    <title>{'Select Country'}</title>
                                </Helmet>
                            </>
                        )
                    }
                }
                
            } 
        </GetQueryData>
    )
});

export default withRouter((Country));



