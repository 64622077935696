import {gql} from '@apollo/client';

const GET_BANNER = gql`
query{
    getBanner{
      _id
      banner_url
      banner_coverpage
      alt
      created_At
      enable
    }
  }
`;

export default GET_BANNER;