import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import {ApolloProvider} from '@apollo/client/react';
import {magentoLiveClient} from '@apolloClient';
import './index.scss';
import App from '../app';
import {tagManagerArgs} from '@config/dentalkart.environment';
import TagManager from '../gtm';

TagManager.initialize(tagManagerArgs);
ReactDOM.render(
    <ApolloProvider client={magentoLiveClient}>
        <Router>
            <SnackbarProvider maxSnack={3}>
                <App client={magentoLiveClient}/>
            </SnackbarProvider>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);
