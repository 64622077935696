import { gql } from '@apollo/client';
import {ProductListingFragment, cartPriceFragment, cartShippingAddressFragment} from '../fragments';

const REMOVE_COUPON = gql`
	mutation removeCouponFromCart($cart_id: String!){
       removeCouponFromCart(
            input:{ cart_id : $cart_id }
        ) {
            cart{
				global_errors
				applied_coupons {
					code
				}
                total_quantity
				...ProductListingFragment
				prices {
					...cartPriceFragment
				}
				shipping_addresses{
					...cartShippingAddressFragment
				}
			}
		}
	}
	${ProductListingFragment}
	${cartPriceFragment}
	${cartShippingAddressFragment}
`;
export default REMOVE_COUPON;
