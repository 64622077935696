import BrowserPersistence from "@helpers/browser_persistence";
import { productData, generalEventData } from "./helper.js";

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
};

const HomePageEvents = {
    BRAND_SELECTED_HOME_PAGE: (data) => {
        const eventData = {
            category: "TopBrands",
            action: "HomePage",
            label: data.name,
        };
        return generalEventData(eventData);
    },
    HOME_LOGO: (data) => {
        const eventData = {
            category: "HomeIcon",
            action: "Click",
            label: data.pathname,
        };
        return generalEventData(eventData);
    },
    BANNER_ORDER_NOW: (data) => {
        const BANNER_ORDER_NOW_DATA = {
            event: "Click",
            eventCategory: "Banner",
            eventAction: "Click",
            eventLabel: data.title,
            bannerName: data.title,
            bannerId: data.id,
            bannerLink: data.link,
            dimension7: getEncryptedEmail(),
        };
        return BANNER_ORDER_NOW_DATA;
    },
    HOME_PAGE_LOAD: () => {
        const HOME_PAGE_LOAD_DATA = {
            event: "Home",
            ecomm_pagetype: "Home",
            dimension7: getEncryptedEmail(),
        };
        return HOME_PAGE_LOAD_DATA;
    },

    HOME_SLIDER: (data) => {
        const banners = [];
        data.map((banner, index) =>
            banners.push({
                id: banner.id,
                url: banner.link,
                name: banner.title,
                creative: `${banner.title}-${index + 1}`,
                position: `slot${index + 1}`,
                dimension7: getEncryptedEmail(),
            }),
        );
        const HOME_SLIDER_DATA = {
            event: "promoview",
            ecommerce: {
                promoView: {
                    promotions: banners,
                },
            },
        };
        return HOME_SLIDER_DATA;
    },

    HOME_VIEW_ALL: (data) => {
        const eventData = {
            category: "Home - View All",
            action: data.name,
            label: `slot${data.position}`,
        };
        return generalEventData(eventData);
    },

    PROMOTION_PRODUCT: (data) => {
        const PROMOTION_PRODUCT_DATA = {
            event: "promotionClick",
            ecommerce: {
                promoClick: {
                    promotions: {
                        id: data.id, // Name or ID is required.

                        name: data.title,
                        creative: `${data.title}-${data.position}`,
                        position: `carouselSlot-${data.position}`,
                        dimension7: getEncryptedEmail(),
                    },
                },
            },
        };
        return PROMOTION_PRODUCT_DATA;
    },

    PRODUCT_CLICK: (data) => {
        const PRODUCT_CLICK_DATA = {
            event: "productClick",
            ecommerce: {
                click: {
                    actionField: { list: "Category Click" }, // Optional list property.
                    products: [productData(data)],
                    adsProduct: [],
                },
            },
        };
        return PRODUCT_CLICK_DATA;
    },
};

export default HomePageEvents;
