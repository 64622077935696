import {gql} from '@apollo/client';

const RESET_PASSWORD = gql`
    mutation checkEmail($email: String!, $otp: String!, $newPassword: String!){
        resetPassword(input: {
        	email: $email,
        	reset_token: $otp,
        	new_password: $newPassword
        })
    }
`;

export default RESET_PASSWORD;
