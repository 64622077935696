import { gql } from '@apollo/client';

const GET_PRODUCT = gql`
    query productData ($id :[Int]){
        productData(id:$id)
        {
            name
            sku
            special_price
            average_rating
            manufacturer
            rating_count
            thumbnail_url
            msrp
            meta_title
            meta_keyword
            meta_description
            categories{
                name
                level
                position
                url_path
            }
            is_in_stock
            url_key
            max_sale_qty
            image_url
            id
            type_id
            pd_expiry_date
            dispatch_days
            dentalkart_custom_fee
            short_description
            reward_point_product
            media_gallery_entries{
                id
                video_content{
                    media_type
                    video_title
                    video_url
                    video_description
                }
                disabled
                media_type
                file
                label
                position
                types
            }
            tier_prices{
                qty
                value
                percentage_value
            }
            price{
                regularPrice{
                    amount{
                        value
                        currency
                        currency_symbol
                    }
                }
                minimalPrice{
                    amount{
                        value
                        currency
                    }
                }
                maximalPrice{
                    amount{
                        value
                        currency
                    }
                }
            }

        }
    }
`;

export default GET_PRODUCT;
