import {Desktop} from '@config/dentalkart.environment';
import Skeleton from '@components/skeleton';
import Style from './categorySkeleton.module.scss'

const FiltersSkelelton = () => {
    return(
        <Desktop>
            <div className={Style.filter_placeholder_wrapper} style={{padding:'10px 15px'}}>
                {[1,2,3,4].map((item, index) => <Skeleton key={index} width="100%" height="45px" />)}
            </div>
        </Desktop>
    )
}

export default FiltersSkelelton;