import BrowserPersistence from "@helpers/browser_persistence";
import cookie from "react-cookies";
import {productData,generalEventData, wishlistEvent, ga4Items} from './helper.js';

const storage = new BrowserPersistence();

const getEncryptedEmail = () => {
    const encryptedEmail = storage.getItem("customer_email") ? window.btoa(storage.getItem("customer_email")) : "Guest";
    return encryptedEmail;
}

const CategoryPageEvents = {
    FILTER_CATEGORY: (data) => {
        const eventData = {
            category:"CategoryFilter",
            action:"Filter",
            label:data.categoryName
        }
        return generalEventData(eventData);
    },

    CATEGORY_PAGE_LOAD: (data) => {
        const CATEGORY_PAGE_LOAD_DATA = {
            ecomm_pagetype: "category",
            ecomm_pcat: [data.name],
            dimension7: getEncryptedEmail()
        };
        return CATEGORY_PAGE_LOAD_DATA;
    },
    ADD_TO_WISHLIST_PLP: (data) => {
        let ga4Data = {
            event: "add_to_wishlist",
            ecommerce: {
                currency: data?.price?.minimalPrice?.amount?.currency || data?.price?.regularPrice?.amount?.currency,
                value: data.price.minimalPrice.amount.value || data.price.regularPrice.amount.value,
                items:[ga4Items(data,'category_page')]
            }
        }
        return {ga3:wishlistEvent("Click", "CategoryPage", data),ga4:ga4Data}
    },

    ADD_TO_CART_PLP: (data) => {
        const ADD_TO_CART_PLP_DATA = {
            event: "addToCart",
            eventCategory: "CategoryPage",
            eventAction: "Click",
            eventLabel: "AddToCart",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                add: {
                    actionField: {
                        list: "Category Page",
                    },
                    products: [productData(data)],
                    adsProduct:[productData(data)],
                },
            },
            items:[ga4Items(data,'category_page')]
        };
        return ADD_TO_CART_PLP_DATA;
    },

    PRODUCTS_LISTING: (data) => {
        const products = [];
        let ga4_products = [];
        data.items.map((product, index) =>{
            let result = productData(product);
            ga4_products.push(ga4Items(product,'category_page_load',index))
            result.list = data.resultantPage;
            result.position = index+1;
            products.push(result);
            return null;
        });
        const PRODUCTS_LISTING_DATA = {
            event: "impressions",
            ecommerce: {
                currencyCode: cookie.load("currency_code"),
                dimension7: getEncryptedEmail(),
                impressions: products,
                items:[...ga4_products]
            },
        };
        return PRODUCTS_LISTING_DATA;
    },
}

export default CategoryPageEvents;
