import React from 'react';
import OrderIcon from '@material-ui/icons/HowToVote';
import SettingsIcon from '@material-ui/icons/Settings';
import StuffIcon from '@material-ui/icons/FolderShared';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';

const accountSidebarConfiguration = [
    {
        title: 'My Orders',
        path: 'GO_TO_ORDERS_LIST',
        leftIcon: <OrderIcon />,
        rightIcon: <RightArrowIcon />,
    },
    {
        title: 'Account Settings',
        leftIcon: <SettingsIcon />,
        items: [
            {
                title: 'Personal Information',
                path: 'GO_TO_PROFILE',
                visible: true
            },
            {
                title: 'Manage Addresses',
                path: 'GO_TO_ADDRESSES',
                visible: true
            }
        ],
    },
    {
        title: 'My Stuff',
        leftIcon: <StuffIcon/ >,
        items: [
            {
                title: 'My Wishlist',
                path: 'GO_TO_WISHLIST',
                visible: true
            },
            {
                title: 'My Coupons',
                path: 'GO_TO_CUSTOMER_COUPON_LIST',
                visible: true
            },
            {
                title: `My Rewards`,
                path:  `GO_TO_REWARDS`,
                visible: true
            },
            {
                title: `My Membership`,
                path:  `GO_TO_MY_MEMBERSHIP`,
                visible: true
            },

        ],
    },
  
];

export default accountSidebarConfiguration;
