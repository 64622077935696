import { gql } from '@apollo/client';

const SET_COUNTRY = gql`
    mutation setCountry(
        $country_code: String!
    ){
        setCurrency(country_code: $country_code){
            country_code
            currency_code
            full_name_english
        }
    }
`;
export default SET_COUNTRY;
